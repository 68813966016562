import React, { useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import axios from "axios";
import toastr from "toastr";

const BenefitEnhancementsRequestLSA = (props) => {
    const [requests, setRequests] = useState([{ id: Date.now(), type: '', amount: '', description: '' }]);
    const [showModal, setShowModal] = useState(false);

    const handleAddRequest = () => {
        setRequests([...requests, { id: Date.now(), type: '', amount: '', description: '' }]);
    };

    const handleChange = (id, field, value) => {
        setRequests(requests.map(req => {
            if (req.id === id) {
                const updatedReq = { ...req, [field]: value };

                if (field === 'type') {
                    updatedReq.description = getDefaultDescription(value);
                }

                return updatedReq;
            }
            return req;
        }));
    };
    
    const getDefaultDescription = (type) => {
        switch (type) {
            case 'Workboots':
                return 'Annual workboot allowance';
            case 'Education and Personal Development':
                return 'Coverage for Education & Personal development resources';
            case 'Fitness Equipment and Memberships':
                return 'Personal fitness equipment and/or gym membership fees';
            case 'Remote Work Support':
                return 'Equipment and electronics required to support remote work';
            case 'Tools & Equipment':
                return 'Work-related equipment to enhance productivity & performance';
            case 'Pet Care & Services':
                return 'Pet insurance, veterinary bills & boarding';
            case 'Health Related Services - Alternative':
                return 'Homeopathy, hypnotherapy, weight management, stress management and more';
            default:
                return '';
        }
    };

    const handleDeleteRequest = (id) => {
        setRequests(requests.filter(req => req.id !== id));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post("/api/BenefitCategories/sendenhancementsrequest", {
            accountType: "LSA",
            companyName: props.companyName,
            bencatName: props.bencatName,
            bencatRequests: requests
        }).then((response) => {
            if (response.data.success) {
                toastr.success("Your request has been submitted successfully!");
                setRequests([{ id: Date.now(), type: '', amount: '', description: '' }]);
            }
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <h1>Benefit Enhancements Request (LSA+)</h1>
            <img src="/plus-lsa.png" style={{float: "right", marginLeft: "10px"}}/>
            <Form.Text>
                <br/>
                <strong>It's easy to add a Workboot Program, Educational & Personal Development, and more.</strong>
                <br/><br/>
                Benefit Enhancements are additional allocations added on top of your existing HSA and/or LSA benefits to
                cover specific expense types your employees need.
                <br/>
                For example, if a category of employees needs extra physiotherapy coverage due to the demands of their
                work, you can add this coverage on top of their base benefits to address that specific need.
                <br/>
                These enhancements allow you to tailor your benefit allocations to meet the diverse needs of your staff.
                <br/>
                Each Benefit Enhancement is category-specific, and you can request up to six per HSA and LSA.
                <br/><br/>
                <strong>Use the tool below to request your Benefit Enhancements. After clicking “Submit for Review,” our team
                will assess your request and activate your Benefit Enhancements, or contact you if further clarification
                    is needed.</strong>
            </Form.Text>

            <br/><br/>
            <Form.Text className="text-muted mt-2" style={{fontSize: 11, fontStyle: "italic"}}>Benefit Enhancements
                cannot be carried forward, and are automatically renewed annually unless removed by PA
                request.</Form.Text>

            {requests.map((request, index) => (
                <Row key={request.id} className="mb-3">
                    <Col md={3}>
                        <Form.Select
                            value={request.type}
                            onChange={(e) => handleChange(request.id, 'type', e.target.value)}
                        >
                            <option>Select...</option>
                            <option value="Workboots">Workboots</option>
                            <option value="Education and Personal Development">Education and Personal Development
                            </option>
                            <option value="Fitness Equipment and Memberships">Fitness Equipment and Memberships</option>
                            <option value="Remote Work Support">Remote Work Support</option>
                            <option value="Tools & Equipment">Tools & Equipment</option>
                            <option value="Pet Care & Services">Pet Care & Services</option>
                            <option value="Health Related Services - Alternative">Health Related Services -
                                Alternative
                            </option>
                            <option value="Custom">Custom</option>
                        </Form.Select>
                    </Col>
                    <Col md={3}>
                        <Form.Control
                            type="number"
                            placeholder="Amount"
                            value={request.amount}
                            onChange={(e) => handleChange(request.id, 'amount', e.target.value)}
                        />
                    </Col>
                    <Col md={4}>
                        <Form.Control
                            type="text"
                            placeholder="Description"
                            value={request.description}
                            onChange={(e) => handleChange(request.id, 'description', e.target.value)}
                        />
                    </Col>
                    <Col md={2} className="d-flex align-items-center">
                        {requests.length < 6 && (
                            <Button variant="primary" onClick={handleAddRequest}>
                                +
                            </Button>)}
                        {requests.length > 1 && (
                            <Button variant="danger" onClick={() => handleDeleteRequest(request.id)}>
                                -
                            </Button>)}
                    </Col>
                </Row>
            ))}
            <Button style={{backgroundColor: '#059454'}} type="submit">
                Submit for Review
            </Button>
            <br/>
            <Form.Text className="text-muted mt-2" style={{fontSize: 11, fontStyle: "italic"}}>
                Don’t see what you’re looking for? Select 'Custom' and enter your request in the Description field. Our
                team will contact you.
            </Form.Text>
        </Form>
    );
};

export default BenefitEnhancementsRequestLSA;
