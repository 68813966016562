import React from 'react'
import { Broadcast,Application} from '../core';


interface IToastMessage {
    text:string;
    style:string;
}

export class NotificationToast extends React.Component<
    {},
    {}
> {
    private timer: any;
    broadcast:Broadcast;
    messages:IToastMessage[];
    
    constructor(props) {
        super(props);
        this.state = {
            messages:null
        };
        this.broadcast = Application.instance.notifications;
    }

    componentDidMount() {
        this.broadcast.connect(this,"*",(action,data)=> {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.timer = setTimeout(this.hideMessage, 2000);
            this.messages = this.messages || [];
            this.messages.push({ text: data.text, style: data.style });
            this.forceUpdate();
        });
    }
    componentWillUnmount() {
        this.broadcast.disconnect(this);
    }

    hideMessage = () => {
        this.timer = null;
        this.messages = null;
        this.forceUpdate();
    };

    render() {
        let elems = [];
        let messages = this.messages;
        if (messages){
            for(let i = 0; i < messages.length;i++){
                let messageItem = messages[i];
                if (messageItem.text){
                    let style = messageItem.style || "info";
                    let className = "Toast-Message__item Toast-Message--" + style;
                    let innerClassName ="Toast-Message__inner Toast-Message__inner--" + style;
                    elems.push( <div key={i} className={className}>
                        <div className={innerClassName}>
                            {messageItem.text}
                        </div>
                    </div>
                    );
                }
            }
        }
        return (
            <div className="Toast-Message__container">
                {elems}
            </div>
        )
    }
}
