import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import toastr from "toastr";
import FlexDatesCard from "./FlexDatesCard";
import "../styles/Campaign.css";

interface EditCampaignModalProps {
    showEditModal: boolean;
    closeModal: () => void;
    refreshCampaigns: () => void;
    policy_id: string;
    SelectionDeadline: string;
    LaunchDate: string;
    ReminderDate: string;
    LastDayReminder: string;
    benefitEndDate: string;
}

const EditCampaignModal: React.FC<EditCampaignModalProps> = ({
    showEditModal,
    closeModal,
    refreshCampaigns,
    policy_id,
    SelectionDeadline: initialSelectionDeadline,
    LaunchDate: initialLaunchDate,
    ReminderDate: initialReminderDate,
    LastDayReminder: initialLastDayReminder,
    benefitEndDate,
}) => {
    const [SelectionDeadline, setSelectionDeadline] = useState(initialSelectionDeadline);
    const [LaunchDate, setLaunchDate] = useState(initialLaunchDate);
    const [ReminderDate, setReminderDate] = useState(initialReminderDate);
    const [LastDayReminder, setLastDayReminder] = useState(initialLastDayReminder);

    const handleSave = async () => {
        let selectionDeadline = new Date(SelectionDeadline);
        let launchDate = new Date(LaunchDate);
        let reminderDate = new Date(ReminderDate);
        let lastDayReminder = new Date(LastDayReminder);
        let benefitEndDateObj = new Date(benefitEndDate);
        let originalLaunchDate = new Date(initialLaunchDate);
        let today = new Date();
        today.setHours(0, 0, 0, 0);

        if (launchDate.getTime() !== originalLaunchDate.getTime() && launchDate <= today) {
            toastr.error("Launch date must be after today", "Error");
            return;
        }

        if (selectionDeadline > benefitEndDateObj || launchDate > benefitEndDateObj ||
            reminderDate > benefitEndDateObj || lastDayReminder > benefitEndDateObj) {
            toastr.error("Dates cannot be after the benefit year end date", "Error");
            return;
        }

        if (launchDate >= selectionDeadline || reminderDate >= selectionDeadline ||
            lastDayReminder >= selectionDeadline) {
            toastr.error("Dates must be before the selection deadline", "Error");
            return;
        }

        if (launchDate >= reminderDate) {
            toastr.error("Launch date must be before the reminder date", "Error");
            return;
        }

        if (reminderDate >= lastDayReminder) {
            toastr.error("Reminder date must be before the last day reminder", "Error");
            return;
        }

        const data = {
            SelectionDeadline: selectionDeadline.toLocaleDateString(),
            LaunchDate: launchDate.toLocaleDateString(),
            ReminderDate: reminderDate.toLocaleDateString(),
            LastDayReminder: lastDayReminder.toLocaleDateString(),
        };

        const body = {
            PolicyId: policy_id,
            SelectionDeadline: data.SelectionDeadline,
            LaunchDate: data.LaunchDate,
            ReminderDate: data.ReminderDate,
            LastDayReminder: data.LastDayReminder,
        };

        try {
            const response = await axios.put("/api/Flex/editpolicycampaign", body);
            if (response.data.success) {
                refreshCampaigns();
                closeModal();
                toastr.success("Campaign updated successfully");
            } else {
                toastr.error("Failed to update campaign");
            }
        } catch (error) {
            console.error(error);
            toastr.error("An error occurred while updating the campaign");
        }
    };

    const handleDateChange = (date: string, type: string) => {
        switch (type) {
            case "SelectionDeadline":
                setSelectionDeadline(date);
                break;
            case "LaunchDate":
                setLaunchDate(date);
                break;
            case "ReminderDate":
                setReminderDate(date);
                break;
            case "LastDayReminder":
                setLastDayReminder(date);
                break;
            default:
                break;
        }
    };

    const isAnyDateNull = !SelectionDeadline || !LaunchDate || !ReminderDate || !LastDayReminder;

    const isEditable = (date: string) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return new Date(date) > today;
    };

    return (
        <Modal
            className="react-modal edit-campaign-modal"
            show={showEditModal}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title className="modal-title">Edit Campaign Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FlexDatesCard
                    handleDateChange={handleDateChange}
                    LaunchDate={LaunchDate}
                    ReminderDate={ReminderDate}
                    LastDayReminder={LastDayReminder}
                    SelectionDeadline={SelectionDeadline}
                    isEditable={isEditable}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSave} disabled={isAnyDateNull}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditCampaignModal;
