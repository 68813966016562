import React, { useState, useEffect } from 'react';
import { Card, Line } from 'ktm-ui';
import { MdEmail } from 'react-icons/md';
import EditCampaignModal from '../../components/Edit_campaign_modal';
import ReactMoment from 'react-moment';
import EmployeeCampaignTable from './Employee_campaigns';
import NewCampaignModal from '../../components/New_campaign_modal';
import { CustomLoading } from 'components/CustomLoading';
import axios from 'axios';
import toastr from 'toastr';
import {Button, Modal} from "react-bootstrap";
import ReactPlayer from 'react-player';

interface CampaignProps {
    policy_id: string;
}

const Campaign: React.FC<CampaignProps> = ({ policy_id }) => {
    const [loading, setLoading] = useState(true);
    const [hasCampaign, setHasCampaign] = useState(false);
    const [campaignData, setCampaignData] = useState({
        SelectionDeadline: null,
        LaunchDate: null,
        ReminderDate: null,
        LastDayReminder: null,
    });
    const [benefitEndDate, setBenefitEndDate] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showNewCampaignModal, setShowNewCampaignModal] = useState(false);
    const [acknowledgementPopUp, setAcknowledgementPopUp] = useState(false);

    useEffect(() => {
        getCampaign();
        getBenefitEndDate();
    }, [policy_id]);

    const getCampaign = async () => {
        try {
            const response = await fetch(`/api/Flex/getpolicycampaign?PolicyId=${policy_id}`);
            const data = await response.json();
            if (data.success) {
                setCampaignData({
                    SelectionDeadline: data.campaign.selectionDeadline,
                    LaunchDate: data.campaign.launchDate,
                    ReminderDate: data.campaign.reminderDate,
                    LastDayReminder: data.campaign.lastDayReminder,
                });
                setHasCampaign(true);
            } else {
                setHasCampaign(false);
            }
        } catch (error) {
            toastr.error('Error getting campaign info');
            setHasCampaign(false);
        } finally {
            setLoading(false);
        }
    };

    const getBenefitEndDate = async () => {
        try {
            const response = await axios.get(`/api/Flex/getbenefitenddate?policyId=${policy_id}`);
            if (response.data.success) {
                setBenefitEndDate(response.data.currentBenYearEndDate.endDate);
            } else {
                toastr.error(response.data.message, 'Error');
            }
        } catch (error) {
            toastr.error('Failed to get benefit end date', 'Error');
        }
    };

    const handleProceedToNewCampaign = () => {
        setAcknowledgementPopUp(false);
        setShowNewCampaignModal(true);
    };

    if (loading) {
        return <CustomLoading />;
    }

    const renderCampaignDetails = () => (
        <div className="campaign-info-container">
            <EditCampaignModal
                showEditModal={showEditModal}
                policy_id={policy_id}
                SelectionDeadline={campaignData.SelectionDeadline}
                LaunchDate={campaignData.LaunchDate}
                ReminderDate={campaignData.ReminderDate}
                LastDayReminder={campaignData.LastDayReminder}
                refreshCampaigns={() => {
                    toastr.success('Campaign updated successfully');
                    setShowEditModal(false);
                    getCampaign();
                }}
                closeModal={() => setShowEditModal(false)}
                benefitEndDate={benefitEndDate}
            />
            <div className="RT-Section">
                <div className="RT-Section__header">
                    <div className="RT-Section__title" style={{fontSize:20, fontStyle: "bold"}}>
                        Flex Selection Email Campaign Dates
                    </div>
                    <div className="RT-Section__actions">
                        <a href="#" className="RT-Link" onClick={() => setShowEditModal(true)}>
                            Edit
                        </a>
                    </div>
                </div>
                <Line>
                {['LaunchDate', 'ReminderDate', 'LastDayReminder', 'SelectionDeadline'].map((dateType) => (
                        <div key={dateType} className="RT-Static-Field">
                            <label className="RT-Static-Field__label">{`${dateType.replace(/([A-Z])/g, ' $1')} (YYYY-MM-DD)`}</label>
                            <div className="RT-Static-Field__content" style={{ height: '21px' }}>
                                {campaignData[dateType] ? <ReactMoment format="YYYY-MM-DD">{campaignData[dateType]}</ReactMoment> : ''}
                            </div>
                        </div>
                    ))}
                </Line>
                <div style={{ marginTop: '20px' }}>
                    <p>
                        <span style={{fontWeight: 'bold'}}>Welcome to your Year-End Flex Campaign!</span>
                        <br/>
                        Flex Selection emails are scheduled to go out automatically according to the first three dates chosen above.
                        Please take a moment to review the employee list and Flex amounts below for the upcoming benefit
                        year. Once reviewed, there is nothing left to do.
                        <br/>
                        If adjustments are required, any updates made within the Campaign tab apply ONLY to the next benefit year, never the current one.
                        <br/>
                        You also have the ability to send Flex Selection emails manually to all or to selected
                        employees, at any time after the Launch date, with our <span style={{fontWeight: 'bold'}}>Send Manual Email(s)</span> button!
                        This does not impact the automatically scheduled emailing.
                        <br/>
                        If necessary, use the <span style={{fontWeight: 'bold'}}>Toggle Email(s)</span> button to enable
                        or disable selected employees from receiving scheduled email notifications.
                        <br/>
                        Don’t hesitate to contact <a href="mailto:support@nationalhealthclaim.com" style={{color: "#4987ef"}}>support@nationalhealthclaim.com</a> if you require assistance!
                        <br/>
                        <a 
                           href={"https://nhcfilestorage.blob.core.windows.net/docfiles/Flex_Campaign_Help_Page.pdf"}
                           target="_blank" 
                           style={{fontStyle: 'italic', color: "#4987ef"}}
                        >Click here for help with campaign features.
                        </a>
                    </p>
                </div>
            </div>
            <div className="employee-campaigns-container" style={{ marginTop: '15px' }}>
                <EmployeeCampaignTable
                    policy_id={policy_id}
                    disableBtn={!campaignData.SelectionDeadline}
                    benefitEndDate={benefitEndDate}
                    selectionDeadline={campaignData.SelectionDeadline}
                    launchDate={campaignData.LaunchDate}
                />
            </div>
        </div>
    );

    const renderEmptyState = () => (
        <div className='campaign-empty-state' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 60, textAlign: 'center' }}>
            <MdEmail style={{ fill: 'rgb(100,120,140)', fontSize: 80, opacity: 0.2 }} />
            <div style={{ fontSize: '15px', fontWeight: 500 }}>
                The next benefit year’s Flex Selection Campaign can be created up to 2 months before your current benefit year-end date.
                We will provide emailed notifications with instruction at that time.
                <br /><br/>
                If no Flex Campaign is manually created within 1 month of your current benefit year-end date, a Flex Campaign will automatically generate on your behalf.
                <br />
                We will also then email a reminder to Plan Administrators to review the next year’s employee list and Flex totals before emails are launched to employees.
                <br/>
                For further assistance, please reach out to <a style={{"color":"#0d6efd"}} href="mailto:enrollments@nationalhealthclaim.com">enrollments@nationalhealthclaim.com</a>
                <br/><br/>
                Please Note: Creating a new Flex Campaign always applies to the next benefit year, never the current one.
            </div>
            <ReactPlayer
                url="https://player.vimeo.com/video/1020310923?h=70c621da36&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
                width="700px"
                height="500px"
                controls={true}
            />
            <button 
                className="RT-Button RT-Button--primary" 
                onClick={() => setAcknowledgementPopUp(true)}
                style={{ width: 200, height: 40, fontSize: 17 }}
            >
                Add Flex Campaign
            </button>
            <AcknowledgementModal
                show={acknowledgementPopUp}
                onClose={() => setAcknowledgementPopUp(false)}
                onProceed={handleProceedToNewCampaign}
            />
            <NewCampaignModal
                policy_id={policy_id}
                showNewCampaignModal={showNewCampaignModal}
                closeModal={() => setShowNewCampaignModal(false)}
                refreshCampaigns={() => {
                    setShowNewCampaignModal(false);
                    getCampaign();
                }}
                benefitEndDate={benefitEndDate}
            />
        </div>
    );

    return <div>{hasCampaign ? renderCampaignDetails() : renderEmptyState()}</div>;
};

export default Campaign;

interface AcknowledgementModalProps {
    show: boolean;
    onClose: () => void;
    onProceed: () => void;
}

const AcknowledgementModal: React.FC<AcknowledgementModalProps> = ({ show, onClose, onProceed }) => {
    return (
        <Modal className="react-modal edit-campaign-modal" show={show}>
            <Modal.Header>
                <Modal.Title>Helpful Tip!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card>
                    <p>
                        Before you proceed to the next step, we highly recommend a quick review of your current year first. 
                        It is not required in order to continue, but may save you time within the Flex Campaign tab if adjustments 
                        to employees' <strong>current</strong> year are necessary.
                        <strong> If a new Flex category amount will be required for use only in the next benefit year,</strong> you may still proceed 
                        to the next steps, but a new category will need to be created. Please reach out to <a href="mailto:enrollments@nationalhealthclaim.com" style={{"color":"#0d6efd"}}>enrollments@nationalhealthclaim.com</a> for assistance.
                    </p>
                </Card>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={onProceed}>
                    Proceed
                </Button>
            </Modal.Footer>
        </Modal>
    );
};