import React, { useState } from "react";
import {Column, Panel} from "ktm-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import toastr from "toastr";
import {KTMScreen} from "ktm-ui";
import ViewEmployee from './ViewEmployee';
import ViewPolicy from "./ViewPolicy";
import ViewUser from "./ViewUser";
import {PolicyTable, EmployeeTable, ClaimTable, UserTable, DependentTable} from "./SearchTables";
import {Spinner} from "react-bootstrap";

export default KTMScreen(
    {name: "search"},
    class extends React.Component<any, any> {
        state = {
            searchType: "policy",
            searchInput: "",
            searchResult: null,
            loading: false,
            claimCard: null,
            employeeData: null,
            employeeId: "",
            policyId: "",
            showViewEmployee: false,
            policyData: null,
            showViewPolicy: false,
            showViewUser: false,
            userData: null
        };

        handleViewEmployeeClick = (data) => {
            this.setState({
                employeeData: data,
                showViewEmployee: true,
            });
        };

        handleViewPolicyClick = (data) => {
            this.setState({
                policyData: data,
                showViewPolicy: true,
            });
        };

        handleViewUserClick = (data) => {
            this.setState({
                userData: data,
                showViewUser: true,
            });
        };

        searchBy = (type) => {
            this.setState({searchType: type});
        };

        setInput = (e) => {
            this.setState({
                searchInput: e.target.value,
                showViewEmployee: false,
                showViewPolicy: false,
                showViewUser: false,
            });
        };

        setSearchType = (e) => {
            this.setState({
                searchType: e.target.value,
                searchInput: "",
                searchResult: null,
                claimCard: null,
                showViewEmployee: false,
                showViewPolicy: false,
                showViewUser: false,
            });
        };

        submitSearch = () => {
            if (this.state.searchInput != null && this.state.searchInput != "") {
                this.setState({loading: true});
                const encodedParamValue = encodeURIComponent(this.state.searchInput);
                fetch(
                    "/api/support/searchsupport?type=" +
                    this.state.searchType +
                    "&input=" +
                    encodedParamValue,
                    {
                        method: "GET",
                    }
                )
                .then((response) => response.json())
                .then((data) => {
                    this.setState(
                        {
                            searchResult: data,
                            loading: false,
                            claimCard: null,
                        },
                        () => {
                            if (this.state.searchResult.length == 0) {
                                toastr.error("No search result found", "Error");
                            }
                        }
                    );
                })
                .catch((error) => {
                    this.setState({searchResult: null, success: false});
                    toastr.console.error("Search failed", "Error");
                });
            }
        };

        handleKeyDown = (event) => {
            if (event.key === "Enter") {
                this.submitSearch();
            }
        };

        render() {
            let placeholderText = "Enter search input";
            if (this.state.searchType == "policy") {
                placeholderText = "Enter Policy Holder name, DBA, or policy number..";
            } else if (this.state.searchType == "employee") {
                placeholderText = "Enter employee name, email, or phone number..";
            } else if (this.state.searchType == "claim") {
                placeholderText = "Enter claim number..";
            } else if (this.state.searchType == "dependent") {
                placeholderText = "Enter dependent name..";
            } else if (this.state.searchType == "user") {
                placeholderText = "Enter username, email..";
            }

            let searchInput = (
                <>
                    <div>
                        <p className="fs-3 pb-3">Search</p>
                    </div>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <select
                                className="form-select"
                                onChange={(e) => this.setSearchType(e)}
                                defaultValue={this.state.searchType}
                            >
                                <option value="policy">Policy Holder</option>
                                <option value="employee">Employee</option>
                                <option value="claim">Claim</option>
                                <option value="dependent">Dependent</option>
                                <option value="user">User</option>
                            </select>
                        </div>
                        <input
                            onKeyDown={this.handleKeyDown}
                            type="text"
                            className="form-control"
                            name="searchInput"
                            onChange={(e) => this.setInput(e)}
                            value={this.state.searchInput}
                            placeholder={placeholderText}
                        />
                        <button
                            onClick={() => this.submitSearch()}
                            className="btn btn-outline-primary"
                            type="button"
                        >
                            Search
                        </button>
                    </div>
                </>
            );
            let searchContent = null;
            if (this.state.searchType === "policy") {
                searchContent = (
                    <div>
                        {searchInput}
                        {this.state.loading ? <Spinner className="pad-spinner" animation="border" variant="primary"/> : <></>}
                        {this.state.searchResult && <PolicyTable searchResult={this.state.searchResult} onPolicySelect={this.handleViewPolicyClick}/>}
                        {this.state.showViewPolicy && <ViewPolicy data={this.state.policyData} />}
                    </div>
                );
            } else if (this.state.searchType === "employee") {                
                searchContent = (
                    <div>
                        {searchInput}
                        {this.state.loading ? <Spinner className="pad-spinner" animation="border" variant="primary"/> : <></>}
                        {this.state.searchResult && <EmployeeTable searchResult={this.state.searchResult} onEmployeeSelect={this.handleViewEmployeeClick}/>}
                        {this.state.showViewEmployee && <ViewEmployee data={this.state.employeeData} />}
                    </div>
                );
            } else if (this.state.searchType === "claim") {
                searchContent = (
                    <div>
                        {searchInput}
                        {this.state.loading ? <Spinner className="pad-spinner" animation="border" variant="primary"/> : <></>}
                        {this.state.searchResult && <ClaimTable searchResult={this.state.searchResult} onEmployeeSelect={this.handleViewEmployeeClick}/>}
                        {this.state.showViewEmployee && <ViewEmployee data={this.state.employeeData} />}
                    </div>
                );
            } else if (this.state.searchType === "dependent") {
                searchContent = (
                    <div>
                        {searchInput}
                        {this.state.loading ? <Spinner className="pad-spinner" animation="border" variant="primary"/> : <></>}
                        {this.state.searchResult && <DependentTable searchResult={this.state.searchResult} onEmployeeSelect={this.handleViewEmployeeClick}/>}
                        {this.state.showViewEmployee && <ViewEmployee data={this.state.employeeData} />}
                    </div>
                );
            } else if (this.state.searchType === "user") {
                searchContent = (
                    <div>
                        {searchInput}
                        {this.state.loading ? <Spinner className="pad-spinner" animation="border" variant="primary"/> : <></>}
                        {this.state.searchResult && <UserTable searchResult={this.state.searchResult} onUserSelect={this.handleViewUserClick}/>}
                        {this.state.showViewUser && <ViewUser data={this.state.userData} />}
                    </div>
                );
            } 

            let searchContainer = (
                <div className="home-card-container">
                    <div className="row">
                        <div className="col-12">
                            <div>
                                <Panel>
                                    <Column>
                                        <div className="search-card">{searchContent}</div>
                                    </Column>
                                </Panel>
                            </div>
                        </div>
                    </div>
                </div>
            );

            return <div>{searchContainer}</div>;
        }
    }
);
