import { useState } from 'react';
import { Modal, Button, Card } from 'react-bootstrap';
import axios from 'axios';
import toastr from 'toastr';
import { CustomDropdown } from 'components/CustomDropdown';
import { Line } from 'ktm-ui';

const BulkEditEmployeeBenCatCampaignModal = ({
  showBulkEditEmployeeBenCatCampaignModal,
  bencatOptions,
  policyId,
  employeeIds,
  campaignLaunchDate,
  closeBulkEditEmployeeBenCatCampaignModal,
}) => {
  const [selectedOption, setSelectedOption] = useState(bencatOptions[0]);
  const [saveCompleted, setSaveCompleted] = useState(false);
  const [showEmailPrompt, setShowEmailPrompt] = useState(false);

  const handleSave = async () => {
    const body = {
      policyId,
      employeeIds,
      selectedBenCatId: selectedOption.id,
    };

    try {
      const response = await axios.put('/api/Flex/updateemployeebencatcampaign', body);
      const today = new Date();

      if (response.data.success) {
        toastr.success("Employee's campaign updated successfully", 'Success');
        if (selectedOption.isFlex && new Date(campaignLaunchDate) <= today) {
          setShowEmailPrompt(true);
          setSaveCompleted(true);
        } else {
          setSaveCompleted(false);
          closeBulkEditEmployeeBenCatCampaignModal();
        }
      } else {
        toastr.error(response.data.message, 'Error');
        closeBulkEditEmployeeBenCatCampaignModal();
      }
    } catch (error) {
      toastr.error("Failed to update employee's campaign", 'Error');
    }
  };

  const handleSendEmail = async () => {
    const body = {
      PolicyId: policyId,
      RecipientIds: employeeIds,
    };

    try {
      const response = await axios.put('/api/Flex/sendselectionemails', body);
      if (response.data.success) {
        toastr.success('Email sent successfully', 'Success');
      } else {
        toastr.error(response.data.message, 'Error');
      }
    } catch (error) {
      toastr.error('Failed to send email', 'Error');
    }

    setSaveCompleted(false);
    closeBulkEditEmployeeBenCatCampaignModal();
  };

  const handleCloseEmailModal = () => {
    setSaveCompleted(false);
    closeBulkEditEmployeeBenCatCampaignModal();
  };

  const editBenCatModal = (
    <Modal
      className="edit-employee-campaign-modal-container"
      show={showBulkEditEmployeeBenCatCampaignModal && !showEmailPrompt}
    >
      <Modal.Header className="campaign-edit-employee-modal-header">
        <Modal.Title>Edit Employee Campaign</Modal.Title>
      </Modal.Header>
      <Modal.Body className="campaign-edit-employee-modal-body">
        <Card>
          <Line>
            <div className="RT-TextBox">
              <label className="RT-Label">Benefit Category</label>
              <CustomDropdown
                options={bencatOptions.map((option) => option.name)}
                selectedOption={selectedOption.name}
                setSelectedOption={(option) => {
                  setSelectedOption(
                    bencatOptions.find((bencat) => bencat.name === option)
                  );
                }}
              />
            </div>
          </Line>
        </Card>
      </Modal.Body>
      <Modal.Footer className="campaign-edit-employee-modal-footer">
        <Button variant="secondary" onClick={closeBulkEditEmployeeBenCatCampaignModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const sendEmailModal = (
    <Modal className="edit-employee-campaign-modal-container" show={showEmailPrompt}>
      <Modal.Header className="campaign-edit-employee-modal-header">
        <Modal.Title>Edit Employee Campaign</Modal.Title>
      </Modal.Header>
      <Modal.Body className="campaign-edit-employee-modal-body">
        <Card>
          <p style={{ fontWeight: 'bold', color: 'red' }}>
            Do you want to send a flex email to the employees?
          </p>
        </Card>
      </Modal.Body>
      <Modal.Footer className="campaign-edit-employee-modal-footer">
        <Button variant="secondary" onClick={handleCloseEmailModal}>
          No
        </Button>
        <Button variant="primary" onClick={handleSendEmail}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return !saveCompleted ? editBenCatModal : showEmailPrompt ? sendEmailModal : null;
};

export default BulkEditEmployeeBenCatCampaignModal;
