import React from 'react';
import {Application} from '../core';

const spinner = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 1,
        bottom: 1,
        backgroundColor: "#33383f",
        opacity: 0.2,
        zIndex: 50000,
        transform:"translate(1,1)"
    },
    container: {
        position: "fixed",
        top: "50%",
        left: "50%",
        width: "65px",
        height: "65px",
        transform: "translate(-50%,-50%)",
        zIndex: 50001
    },
    renderContent: () => <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle className="spinner-path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
}
export class Spinner extends React.Component<{ }, { visible: boolean }> {

    constructor(props) {
        super(props);
        this.state = {
            visible: false
        }

    }

    componentDidMount() {
        Application.instance.updateSpinnerState = (visible: boolean) => {
            this.setState({ visible });
        }
    }

    componentWillUnmount() {
        Application.instance.updateSpinnerState = null;
    }

    render() {
        let style: any = {};
        if (!this.state.visible) {
            style.display = "none";
        }
        else {
            style.display = "block";
        }

        return (
            <div style={style}>
                <div style={spinner.container as any}>
                    {spinner.renderContent()}
                </div>
               {/* <div style={spinner.overlay as any} /> */}
            </div>
        )

    }

}