import React from "react";
import {Modal} from "react-bootstrap";
import {
    ActionEvent,
    Application,
    Button,
    Card,
    ColorSwatch,
    Column,
    Display,
    FileUpload,
    KTMScreen,
    Layout,
    Line,
    Panel,
    RecordHeader,
    RenderEvent,
    renderScreen,
    Sidebar,
    Tab,
    TabSet,
} from "ktm-ui";
import {KTMApi} from "api/KTMApi";
import ProductsScreen from "./Products";
import Cost_centresScreen from "./Cost_centres";
import DivisionsScreen from "./Divisions";
import Limit_previewScreen from "./Limit_preview";
import Benefit_categoriesScreen from "./Benefit_categories";
import CampaignBrowseScreen from "planadmin/campaign/Browse";
import ActivationScreen from "./Activation";
import EmployeesScreen from "./Employees";
import Year_dashboardScreen from "./Year_dashboard";
import Edit_detailsScreen from "./Edit_details";
import Edit_plan_adminScreen from "./Edit_plan_admin";
import Edit_policy_holderScreen from "./Edit_policy_holder";
import Change_advisorScreen from "./Change_advisor";
import Change_policy_holderScreen from "./Change_policy_holder";
import Edit_noteScreen from "./Edit_note";
import Add_first_yearScreen from "./Add_first_year";
import Edit_first_yearScreen from "./Edit_first_year";
import CustomToolTip from "components/CustomToolTip";
import EditMinimumBalance from "./Edit_minimum_balance";
import toastr from "toastr";
import TextModal from "../../components/TextModal";
import CampaignScreen from "./Campaign";
import ReportsScreen from "./Reports";
import SettingsScreen from "../../components/Settings";
import ReactPlayer from "react-player";
import "../../styles/PlanAdminPortal.css";
import ReactButton from "react-bootstrap/Button";
import base64 from "data/base64.json";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {MdEditNotifications} from "react-icons/md";
import {PiCurrencyDollarBold} from "react-icons/pi";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import {HiDocumentArrowDown} from "react-icons/hi2";
import {CertificateOfInsurance} from "./CertificateOfInsurance";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface IdsPolicy {
    Id: string;
    Name: string;
    PolicyNo: string;
    PolicyHolder_Id: string;
    PrimaryAdvisor_Id: string;
    EnrollmentStatus: string;
    EnrollmentNote: string;
    IsActive: boolean;
    DependentAge: number;
    StudentAge: number;
    TaxJurisdiction_Id: string;
    Type: string;
    TypeLabel: string;
    TaxName: string;
    CurrentBenYear_Id: string;
    WaitingPeriod: number;
    EnrollPAFirstName: string;
    EnrollPALastName: string;
    EnrollPAEmail: string;
    StartDate: string;
    EnablePad: boolean;
    IsBusinessOwner: boolean;
    HasFlex: boolean;
    HasCorehealth: boolean;
    HasTeladoc: boolean;
    NatPolicy: string;
    CreationDate: string;
}

interface IdsAdvisor {
    Id: string;
    Name: string;
    Phone: string;
    Email: string;
    Distro: string;
}

interface IdsPolicyHolder {
    Id: string;
    Name: string;
    DBA: string;
    MailingStreet1: string;
    MailingStreet2: string;
    MailingCity: string;
    MailingProv: string;
    MailingCountry: string;
    MailingPostalCode: string;
    Phone: string;
    ContactPerson: string;
    ContactEmail: string;
    LegalForm: string;
}

interface IdsBenefitYear {
    Id: string;
    StartDate: string;
    EndDate: string;
    RunOffClosingDate: string;
    RunoffMonths: number;
}

interface IdsPolicyBalance {
    Policy_Id: string;
    LedgerType: string;
    Balance: number;
}

export default KTMScreen(
    {name: "planadmin.policy.record"},
    class extends React.Component<{ policy_id: string }, any> {
        data: {
            dsPolicy: IdsPolicy;
            dsAdvisor: IdsAdvisor;
            dsPolicyHolder: IdsPolicyHolder;
            dsBenefitYear: IdsBenefitYear;
            dsPolicyBalance: IdsPolicyBalance;
        };

        constructor(props) {
            super(props);
            this.state = {
                AssignedPolicies: [],
                FundingBalance: 0,
                OnHoldAmount: 0,
                ApprovedClaimCount: 0,
                ApprovedClaimTotal: 0,
                Shortfall: 0,
                AssignedPas: [],
                MinimumFunding: 0,
                ShowMinimumBalanceModal: false,
                showOnlineBankingModal: false,
                showPADModal: false,
                showCompanyCheque: false,
                PayeeAccountNumber: "",
                hasAccess: false,
                hideDivisionsTab: false,
                hideCostCentresTab: false,
                showHSAReports: false,
                showLSAReports: false,
                showFlexReports: false,
                isMigratedCompany: false,
                customChequeAmount: 0,
                ShortfallFundingAmount: "",
                showHsaCustomCatReport: false,
                showLsaCustomCatReport: false,
                teladocOnly: false,
                hasTeladoc: false,
                hasCorehealth: false,
            };
        }

        render() {
            return renderScreen(this);
        }

        async start({screen}: ActionEvent) {
            screen.defaultRecord = "@dsPolicy";
            await KTMApi.start(screen, {
                "@policy_id": this.props.policy_id,
            });
            screen.title = this.data.dsPolicyHolder.Name;
            screen.setOptions("@dsBenefitYear.RunoffMonths", [
                {value: "1", label: "1 month"},
                {value: "2", label: "2 months"},
                {value: "3", label: "3 months"},
            ]);
            await this.get_funding_summary();
            await this.get_assigned_policies();
            await this.get_assigned_pas();
            await this.get_minimum_funding();
            await this.getPayeeAccountNumber();
            await this.getAccess();
            await this.updateCostCentresTab();
            await this.updateDivisionsTab();
            await this.updateReportsTab();
            await this.checkIsMigratedCompany();
            await this.updateCustomCategoryTabs();
            await screen.triggerAction(this.configure_ui);
        }

        main({field, draw, action}: RenderEvent) {
            let content;
            if (this.state.hasAccess) {
                content = (
                    <Layout>
                        {draw(this.header)}
                        {draw(this.tabs)}
                        {draw(this.footer)}
                    </Layout>
                );
            } else {
                content = null;
                Application.instance.session.signout();
            }
            return content;
        }

        header({field, draw, action}: RenderEvent) {
            return (
                <RecordHeader entityLabel="Policy Holder">
                    {/* {draw(this.header_bar)} */}
                </RecordHeader>
            );
        }

        footer({field, draw, action}: RenderEvent) {
            return (
                <label style={{position: "absolute", right: "50px", bottom: "15px"}}>
                    PA Portal Version 3
                </label>
            );
        }

        tabs({field, draw, action}: RenderEvent) {
            return (
                <TabSet>
                    <Tab label="Home" template={this.details_tab}/>
                    <Tab label="Profile" template={this.policy_holder_tab}/>
                    <Tab label="Product" template={this.products_tab}/>
                    <Tab label="Benefit Categories" template={this.bencat_tab}/>
                    <Tab
                        label="Divisions"
                        template={this.divisions_tab}
                        isHidden={this.state.hideDivisionsTab}
                    />
                    <Tab
                        label="Cost Centres"
                        template={this.cost_centres_tab}
                        isHidden={this.state.hideCostCentresTab}
                    />
                    <Tab label="Employees" template={this.employees_tab}/>
                    {!this.state.teladocOnly ? (
                        <Tab label="Funding" template={this.funding_tab}/>
                    ) : (
                        <></>
                    )}
                    <Tab label="Documents" template={this.document_tab}/>
                    {/* <Tab label="Limit Preview" template={this.limit_preview_tab}/>
                <Tab label="Activation" template={this.activation_tab}/> */}
                    {this.data.dsPolicy.HasFlex ? (
                        <Tab label="Flex Campaign" template={this.campaign_tab}/>
                    ) : (
                        <></>
                    )}
                    <Tab label="Reports" template={this.reports_tab}/>
                    <Tab label="Help" template={this.help_tab}/>
                    {/* <Tab label="Settings" template={this.settings_tab} /> */}
                </TabSet>
            );
        }

        header_bar({field, draw, action}: RenderEvent) {
            return (
                <Line>
                    <Display
                        field={field("EnrollmentStatus")}
                        size="medium"
                        textStyle="bold"
                    />
                    <Display field={field("PolicyNo")}/>
                    <div className="RT-Static-Field">
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <label className="RT-Static-Field__label">
                                Enrollment Note
                            </label>
                            <Button action={action(this.edit_note)} label="Edit" style="link"/>
                        </div>
                        <div className="RT-Static-Field__content" 
                            style={{maxHeight: 200, overflowY: "scroll"}}
                        >
                            {this.data.dsPolicy.EnrollmentNote}
                        </div>
                    </div>
                    <Button
                        action={action(this.edit_note)}
                        label="Edit note"
                        style="link"
                    />
                </Line>
            );
        }

        details_tab({field, draw, action}: RenderEvent) {
            let leftColumn = null;
            let rightColumn = null;
            if (this.data.dsPolicy.IsBusinessOwner) {
                leftColumn = (
                    <Column>
                        {draw(this.bo_admin_access_welcome)}
                        {draw(this.bo_hsa_detail)}
                    </Column>
                );
                rightColumn = (
                    <Column>
                        {draw(this.homepage_funding)}
                        {this.state.AssignedPolicies.length > 1 && draw(this.pa_companies)}
                    </Column>
                );
            } else {
                if (this.state.AssignedPolicies.length > 1) {
                    leftColumn = (
                        <Column>
                            {draw(this.pa_companies)}
                            {draw(this.homepage_funding)}
                        </Column>
                    );
                    rightColumn = (
                        <Column>
                            {draw(this.plan_admin_panel)}
                            {draw(this.pa_claim_notes)}
                        </Column>
                    );
                } else {
                    leftColumn = (
                        <Column>
                            {draw(this.homepage_funding)}
                            {draw(this.plan_admin_panel)}
                        </Column>
                    );
                    rightColumn = <Column>{draw(this.pa_claim_notes)}</Column>;
                }
            }

            return (
                <div className="pa-portal-detail-tab">
                    <Panel>
                        {leftColumn}
                        {rightColumn}
                    </Panel>
                </div>
            );
        }

        policy_holder_tab({field, draw, action}: RenderEvent) {
            return (
                <Panel>
                    <Column>
                        {draw(this.policy_holder)}
                        {draw(this.policy_details)}
                        {/* {draw(this.plan_admin_list)}
            {draw(this.advisor)} */}
                    </Column>
                </Panel>
            );
        }

        products_tab({field, draw, action}: RenderEvent) {
            return <ProductsScreen policy_id={this.data.dsPolicy.Id}/>;
        }

        cost_centres_tab({field, draw, action}: RenderEvent) {
            return <Cost_centresScreen policy_id={this.data.dsPolicy.Id}/>;
        }

        divisions_tab({field, draw, action}: RenderEvent) {
            return <DivisionsScreen policy_id={this.data.dsPolicy.Id}/>;
        }

        limit_preview_tab({field, draw, action}: RenderEvent) {
            return <Limit_previewScreen policy_id={this.data.dsPolicy.Id}/>;
        }

        bencat_tab({field, draw, action}: RenderEvent) {
            return <Benefit_categoriesScreen policy_id={this.data.dsPolicy.Id} 
                                             hasTeladoc={this.data.dsPolicy.HasTeladoc} 
                                             hasCorehealth={this.data.dsPolicy.HasCorehealth}/>;
        }

        campaigns_tab({field, draw, action}: RenderEvent) {
            return <CampaignBrowseScreen policy_id={this.props.policy_id}/>;
        }

        activation_tab({field, draw, action}: RenderEvent) {
            return <ActivationScreen policy_id={this.data.dsPolicy.Id}/>;
        }

        employees_tab({field, draw, action}: RenderEvent) {
            return (
                <EmployeesScreen
                    policy_id={this.data.dsPolicy.Id}
                    view_type={
                        this.data.dsPolicy.IsBusinessOwner ? "business-owner" : "general"
                    }
                />
            );
        }

        funding_tab({field, draw, action}: RenderEvent) {
            if (this.data.dsPolicy.IsBusinessOwner) {
                return (
                    <div className="bo-funding-tab">
                        <div className="funding-notification-card">
                            {draw(this.funding_notification)}
                        </div>
                        <Panel>
                            <Column>{draw(this.funding_summary)}</Column>
                            <Column>{draw(this.funding_detail)}</Column>
                        </Panel>
                        <Panel>
                            <Column>
                                {this.data.dsPolicy.EnablePad
                                    ? draw(this.preauthorized_debit)
                                    : draw(this.funding_option)}
                            </Column>
                            <Column>{draw(this.funding_shortfall)}</Column>
                        </Panel>
                        <Panel>
                            <Column>
                                {this.data.dsPolicy.EnablePad
                                    ? draw(this.funding_option)
                                    : null}
                            </Column>
                        </Panel>
                    </div>
                );
            }

            return (
                <div className={"funding-tab"}>
                    <div className="funding-notification-card">
                        {draw(this.funding_notification)}
                    </div>
                    <Panel>
                        <Column>
                            <Panel>
                                <Column>{draw(this.funding_summary)}</Column>
                                <Column>{draw(this.funding_shortfall)}</Column>
                            </Panel>
                            <Panel>
                                <Column>{draw(this.funding_option)}</Column>
                                <Column>
                                    {this.data.dsPolicy.EnablePad
                                        ? draw(this.preauthorized_debit)
                                        : null}
                                </Column>
                            </Panel>
                        </Column>
                    </Panel>
                </div>
            );
        }

        homepage_funding({field, draw, action}: RenderEvent) {
            if(this.state.teladocOnly) {
                return null
            }
            return (
                <Card label="Funding">
                    {this.data.dsPolicy.IsBusinessOwner && (
                        <div style={{marginBottom: "10px"}}>
                            After claims are approved, you need to fund the Health Spending
                            Account from your company bank account.
                        </div>
                    )}
                    <div>Here is an overview of your current funding requirements:</div>
                    <Line>
                        <div className="funding-summary-card">
                            <table className="RT-Table">
                                <tbody>
                                <tr className="RT-Table__tr">
                                    <td className="RT-Table__td RT-Table__td--align-left">
                                        Current Account Funds
                                    </td>
                                    <td className="RT-Table__td RT-Table__td--align-right">
                                        ${this.state.FundingBalance.toFixed(2)}
                                    </td>
                                </tr>
                                {this.state.OnHoldAmount > 0 ? (
                                    <tr className="RT-Table__tr">
                                        <td className="RT-Table__td RT-Table__td--align-left">
                                            On Hold Amount
                                        </td>
                                        <td
                                            className="RT-Table__td RT-Table__td--align-right"
                                            style={{color: "red"}}
                                        >
                                            ${this.state.OnHoldAmount.toFixed(2)}
                                        </td>
                                    </tr>
                                ) : null}
                                <tr className="RT-Table__tr">
                                    <td className="RT-Table__td RT-Table__td--align-left">
                                        {this.state.ApprovedClaimCount} Approved Claims Waiting
                                        Reimbursement
                                    </td>
                                    <td className="RT-Table__td RT-Table__td--align-right">
                                        ${this.state.ApprovedClaimTotal.toFixed(2)}
                                    </td>
                                </tr>
                                {this.state.Shortfall > 0 ? (
                                    <tr className="RT-Table__tr">
                                        <td className="RT-Table__td RT-Table__td--align-left">
                                            You need ${this.state.Shortfall.toFixed(2)} to satisfy
                                            all claims pending on account.
                                        </td>
                                    </tr>
                                ) : null}
                                <tr className="RT-Table__tr funding-minimum">
                                    <td className="RT-Table__td RT-Table__td--align-left">
                                        Minimum Amount Due
                                    </td>
                                    <td className="RT-Table__td RT-Table__td--align-right">
                                        ${this.state.Shortfall.toFixed(2)}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </Line>
                    {this.data.dsPolicy.IsBusinessOwner && (
                        <div>
                            To learn more about why this funding is required and for a list of
                            your funding options, go under the "Funding" tab.
                        </div>
                    )}
                </Card>
            );
        }

        funding_summary({field, draw, action}: RenderEvent) {
            return (
                <Card label="Funding Summary">
                    {this.data.dsPolicy.IsBusinessOwner && (
                        <div style={{marginBottom: "10px"}}>
                            After claims are approved, you need to fund the Health Spending
                            Account from your company bank account.
                        </div>
                    )}
                    <div>Here is an overview of your current funding requirements:</div>
                    <Line>
                        <div className="funding-summary-card">
                            <table className="RT-Table">
                                <tbody>
                                <tr className="RT-Table__tr">
                                    <td className="RT-Table__td RT-Table__td--align-left">
                                        Current Account Funds
                                    </td>
                                    <td
                                        className="RT-Table__td RT-Table__td--align-right"
                                        style={{color: "green"}}
                                    >
                                        ${this.state.FundingBalance.toFixed(2)}
                                    </td>
                                </tr>
                                {this.state.OnHoldAmount > 0 ? (
                                    <tr className="RT-Table__tr">
                                        <td className="RT-Table__td RT-Table__td--align-left">
                                            On Hold Amount
                                        </td>
                                        <td
                                            className="RT-Table__td RT-Table__td--align-right"
                                            style={{color: "red"}}
                                        >
                                            ${this.state.OnHoldAmount.toFixed(2)}
                                        </td>
                                    </tr>
                                ) : null}
                                <tr className="RT-Table__tr">
                                    <td className="RT-Table__td RT-Table__td--align-left">
                                        {this.state.ApprovedClaimCount} Approved Claims Waiting
                                        Reimbursement
                                    </td>
                                    <td className="RT-Table__td RT-Table__td--align-right">
                                        ${this.state.ApprovedClaimTotal.toFixed(2)}
                                    </td>
                                </tr>
                                {this.state.Shortfall > 0 ? (
                                    <tr className="RT-Table__tr">
                                        <td className="RT-Table__td RT-Table__td--align-left">
                                            You need ${this.state.Shortfall.toFixed(2)} to satisfy
                                            all claims pending on account.
                                        </td>
                                    </tr>
                                ) : null}
                                <tr className="RT-Table__tr funding-minimum">
                                    <td className="RT-Table__td RT-Table__td--align-left">
                                        Minimum Amount Due
                                    </td>
                                    <td className="RT-Table__td RT-Table__td--align-right">
                                        ${this.state.Shortfall.toFixed(2)}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </Line>
                    {/* <div style={{paddingBottom:33}}>
            Amounts inclusive of admin fee and corresponding provincial tax. We recommend funding your account to a higher number than the
            minimum amount to ensure claims get continuously reimbursed on a
            rolling basis.
          </div> */}
                </Card>
            );
        }

        funding_option({field, draw, action}: RenderEvent) {
            return (
                <>
                    <TextModal
                        showModal={this.state.showOnlineBankingModal}
                        toggleModal={this.toggleOnlineBankingModal}
                        header={"Online Banking"}
                        bodyText={
                            'Please login to your company\'s banking service and set up a "Payee" for the Spending Account. Search for "National HealthClaim" to locate the correct payee. The Payee Account Number to enter is: ' +
                            this.state.PayeeAccountNumber +
                            '.\nOnce this is done, simply fund the Spending Account by using your company\'s online banking "Pay Bills" feature. National HealthClaim will receive the funds in 1-2 business days. It is not necessary to mail any payment documents to National HealthClaim to complete the payment transaction.'
                        }
                    />
                    <TextModal
                        showModal={this.state.showPADModal}
                        toggleModal={this.togglePADModal}
                        header={"Pre-Authorized Debit"}
                        bodyText={
                            "Instead of making online bank payments or mailing cheques, National HealthClaim recommends doing weekly withdrawals to prefund the account balance to facilitate immediate payment of approved claims. Contact support@nationalhealthclaim.com to enable PAD. Please note that PAD withdrawals are made on Thursdays, and the credits will be posted the following Tuesday."
                        }
                    />
                    <Modal
                        className="react-modal"
                        show={this.state.showCompanyCheque}
                        backdrop="static"
                        keyboard={false}
                        style={{width: "400px", left: "40%"}}
                    >
                        <Modal.Header className="react-modal-title">
                            <Modal.Title>Company Cheque</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="mb-2">Generate payment summary sheet.</div>
                            <label className="form-label"> Cheque Amount </label>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter cheque amount"
                                    value={this.state.customValue}
                                    onChange={(e) =>
                                        this.setState({customChequeAmount: e.target.value})
                                    }
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton
                                variant="light"
                                onClick={this.toggleCompanyCheque}
                                style={{width: "70px"}}
                            >
                                Cancel
                            </ReactButton>
                            <ReactButton
                                className="RT-Button RT-Button--primary"
                                onClick={() => this.handleComapnyChequeSave()}
                                style={{width: "90px"}}
                            >
                                Generate
                            </ReactButton>
                        </Modal.Footer>
                    </Modal>
                    <Card label="Funding Option">
                        <div>
                            <table className="table">
                                <thead>
                                <tr>
                                    <td style={{width: "20%"}}>Type</td>
                                    <td style={{width: "25%"}}>Description</td>
                                    <td style={{width: "30%"}}>Timeline</td>
                                    <td></td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        Pre-Authorized Debit{" "}
                                        <span style={{color: "green"}}>(Recommended)</span>
                                    </td>
                                    <td>Same day payment from account balance</td>
                                    <td>Weekly pull, 5 business days</td>
                                    <td>
                                        <a
                                            href="#"
                                            className="link-blue"
                                            onClick={this.togglePADModal}
                                        >
                                            View
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Online Bank</td>
                                    <td></td>
                                    <td>1-2 business days</td>
                                    <td>
                                        <a
                                            href="#"
                                            className="link-blue"
                                            onClick={this.toggleOnlineBankingModal}
                                        >
                                            View
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Company Cheque</td>
                                    <td></td>
                                    <td>10 calendar days after arrival at office</td>
                                    <td>
                                        <a
                                            href="#"
                                            className="link-blue"
                                            onClick={this.toggleCompanyCheque}
                                        >
                                            View
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <div style={{paddingTop: 10}}>
                                National HealthClaim will only issue reimbursements once a
                                company cheque or online payment has cleared into our account.
                                In the event of over-funding, the balance will be kept 'in
                                trust' indefinitely for payment of future claims. You may also
                                contact us for a refund of any uncommitted funding balance on
                                our end. There is no interest paid on a positive balance.
                            </div>
                        </div>
                    </Card>
                </>
            );
        }

        pad_option({field, draw, action}: RenderEvent) {
            return (
                <Card label="Pay by Pre-Authorized Debit (PAD)">
                    <Line>
                        <div>Contact National HealthClaim to set-up this option.</div>
                    </Line>
                </Card>
            );
        }

        funding_detail({field, draw, action}: RenderEvent) {
            return (
                <Card label="Why you need to fund the account after your claim is approved.">
                    <ReactPlayer
                        url="https://player.vimeo.com/video/666162270?h=6b72c4ffa3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22"
                        width="350px"
                        height="220px"
                        controls={true}
                    />
                    <div>
                        Once National HealthClaim receives your company funding, we
                        reimburse your personal bank account. You can view and retrieve your
                        corporate expenses under the "Reports" tab to see the breakdown of
                        the claims, administration fees, and taxes.
                    </div>
                </Card>
            );
        }

        handleShortfallFundingChange = (event) => {
            const value = event.target.value;
            if (value === "" || /^[0-9]*\.?[0-9]*$/.test(value)) {
                this.setState({ShortfallFundingAmount: value});
            }
        };

        getTimestamp = () => {
            const now = new Date();
            const year = now.getFullYear();
            const month = (now.getMonth() + 1).toString().padStart(2, "0");
            const day = now.getDate().toString().padStart(2, "0");
            const hours = now.getHours().toString().padStart(2, "0");
            const minutes = now.getMinutes().toString().padStart(2, "0");

            return `${year}-${month}-${day} ${hours}:${minutes}`;
        };
        getDateTimestamp = () => {
            const now = new Date();
            const monthNames = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];
            const year = now.getFullYear();
            const month = monthNames[now.getMonth()];
            const day = now.getDate();

            return `${month} ${day}, ${year}`;
        };

        generateShortallForm = (event) => {
            if (
                this.state.ShortfallFundingAmount === null ||
                this.state.ShortfallFundingAmount === undefined ||
                this.state.ShortfallFundingAmount === "" ||
                isNaN(this.state.ShortfallFundingAmount)
            ) {
                return;
            }

            let fileName = "FundingRequest" + this.state.PayeeAccountNumber;
            let timestamp = this.getTimestamp();
            let currDate = this.getDateTimestamp();

            let content = {
                content: [
                    {
                        columns: [
                            {
                                text: "",
                                width: "*",
                            },
                            {
                                image: base64.logo,
                                width: 230,
                                margin: [0, 0, 0, 0],
                            },
                        ],
                        columnGap: 10,
                    },
                    {
                        columns: [
                            {
                                text: "",
                                width: "*",
                            },
                            {
                                stack: [
                                    "National HealthClaim Corp (FC)",
                                    "5320 - 7005 Fairmount Dr. SE",
                                    "Calgary, AB",
                                    "T2H 0K4",
                                ],
                                alignment: "right",
                                margin: [20, 20, 0, 30],
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                stack: [
                                    this.data.dsPolicyHolder.Name,
                                    this.data.dsPolicyHolder.MailingStreet1,
                                    this.data.dsPolicyHolder.MailingCity +
                                    ", " +
                                    this.data.dsPolicyHolder.MailingProv,
                                    this.data.dsPolicyHolder.MailingPostalCode,
                                ],
                                alignment: "left",
                                margin: [0, 0, 0, 10],
                            },
                            {
                                stack: [currDate],
                                alignment: "right",
                                margin: [0, 0, 0, 10],
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                stack: ["Shortfall funding request " + timestamp],
                                alignment: "left",
                                margin: [0, 10, 0, 30],
                                bold: true,
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                stack: [
                                    'This is an interim shortfall funding request form. A formal monthly statement will be automatically generated at the conclusion of each month, providing a comprehensive breakdown of funding distribution. You can locate this statement in the Reports Tab under the label "Monthly Statement".',
                                ],
                                alignment: "left",
                                margin: [0, 0, 0, 15],
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                stack: [
                                    {
                                        text:
                                            "Amount of " +
                                            Intl.NumberFormat("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                                minimumFractionDigits: 2,
                                            }).format(this.state.ShortfallFundingAmount),
                                        bold: true,
                                    },
                                    "\n",
                                    "Company Name: ",
                                    {
                                        text: this.data.dsPolicyHolder.Name,
                                        bold: true,
                                    },
                                    "\n",
                                    "Account Number: ",
                                    {
                                        text: this.state.PayeeAccountNumber,
                                        bold: true,
                                    },
                                ],
                                alignment: "left",
                                margin: [0, 10, 0, 0],
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                stack: [
                                    "Note: The amount in the form may not accurately represent the current shortfall of the account, as shortfalls are subject to change. The indicated amount is the intended funding to be paid at the time the form was generated. Kindly retain this form for record-keeping purposes.",
                                ],
                                fontSize: 10,
                                alignment: "left",
                                margin: [0, 20, 0, 0],
                            },
                        ],
                    },
                ],
            };

            pdfMake.createPdf(content).download(fileName);
        };

        funding_shortfall({field, draw, action}: RenderEvent) {
            return (
                <>
                    <Card label="Shortfall Funding Request">
                        <div className="shortfall-container">
                            <div className="shortfall-desc">
                                Create and print a shortfall funding request form for your
                                accounting department.
                            </div>
                            <div className="shortfall-form">
                                Enter the funding amount to generate a request form
                                <div className="shortfall-input">
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text
                                            style={{
                                                backgroundColor: "#559e83",
                                                borderColor: "#559e83",
                                            }}
                                        >
                                            <PiCurrencyDollarBold
                                                style={{fontSize: "20px", color: "#fff"}}
                                            />
                                        </InputGroup.Text>
                                        <FormControl
                                            aria-label="Amount"
                                            placeholder="Enter amount"
                                            value={this.state.ShortfallFundingAmount}
                                            onChange={this.handleShortfallFundingChange}
                                            className="text-right"
                                        />
                                    </InputGroup>
                                    <button
                                        className="btn shortfall-btn"
                                        onClick={this.generateShortallForm}
                                    >
                                        <HiDocumentArrowDown size={20}/> Generate Form
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Card>
                </>
            );
        }

        funding_notification({field, draw, action}: RenderEvent) {
            return (
                <>
                    <EditMinimumBalance
                        updateMinimumBalance={this.update_minimum_funding}
                        showModal={this.state.ShowMinimumBalanceModal}
                        toggleModal={this.toggleMinimumBalanceModal}
                    />
                    <Card>
                        <div className="notification-container">
                            <div className="notification-desc">
                                <div className="notification-label">
                                    Funding Threshold Notification
                                </div>
                                <div>
                                    Want to keep your account funding above a certain threshold
                                    for faster claim reimbursement? <br/>
                                    Select a Minimum Balance, and you will also receive a weekly
                                    notification when Account Funds have dipped below your
                                    selected Minimum Balance.
                                </div>
                            </div>
                            <div className="notification-balance">
                                <div className="notification-icon">
                                    <MdEditNotifications color={"#559e83"} size={40}/>
                                </div>
                                <div className="notification-form">
                                    <div>Minimum Balance</div>
                                    <div>
                                        {this.state.MinimumFunding !== undefined &&
                                        this.state.MinimumFunding !== null &&
                                        this.state.MinimumFunding !== 0 ? (
                                            <span>$ {this.state.MinimumFunding}</span>
                                        ) : (
                                            "None"
                                        )}
                                    </div>
                                </div>

                                <div className="notification-edit">
                                    <Button
                                        action={action(this.edit_minimumBalance)}
                                        label="Edit"
                                        style="link"
                                    />
                                </div>
                            </div>
                        </div>
                    </Card>
                </>
            );
        }

        preauthorized_debit({field, draw, action}: RenderEvent) {
            return (
                <Card label="Your Pre-Authorized Debit Schedule">
                    <Line>
                        <div>
                            <div>
                                If required, we pull your funds every Thursday. Company Plan
                                Administrators get an email notice every Thursday morning
                                stating the withdrawal amount. If you want to stop the pull from
                                happening, you must notify us ASAP at
                                support@nationalhealthclaim.com.
                            </div>

                            <div style={{paddingTop: 10}}>
                                Your withdrawal amount varies each time, depending on how many
                                employee claims are pending and if you have set up a float on
                                your account. After the withdrawal happens, money will be
                                credited to your company National HealthClaim account after 5
                                days. This is to ensure there are no NSFs or bounce back of
                                funds.
                            </div>
                        </div>
                    </Line>
                    <Line>
                        <div style={{paddingTop: 10}}>
                            <a
                                href={
                                    "https://pad.nationalhealthclaim.com/paddashboard?id=" +
                                    this.data.dsPolicy.Id
                                }
                                className="btn btn-primary"
                                target="_blank"
                            >
                                View your PAD details and withdrawal history
                            </a>
                        </div>
                    </Line>
                </Card>
            );
        }

        document_tab({field, draw, action}: RenderEvent) {
            return (
                <Panel>
                    <Column>{draw(this.document_details)}</Column>
                </Panel>
            );
        }

        help_tab({field, draw, action}: RenderEvent) {
            return (
                <Panel>
                    <Column>
                        {draw(this.support_articles)}
                        {draw(this.help_panel)}
                    </Column>
                    <Column>
                        <div className="RT-Section" style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                            <iframe
                                src="https://vimeo.com/showcase/10828343/embed"
                                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                                allowFullScreen
                            ></iframe>
                        </div>
                    </Column>
                </Panel>
            );
        }

        campaign_tab({field, draw, action}: RenderEvent) {
            return <CampaignScreen policy_id={this.data.dsPolicy.Id}/>;
        }

        reports_tab({field, draw, action}: RenderEvent) {
            return (
                <ReportsScreen
                    policyId={this.data.dsPolicy.Id}
                    showHSAReports={this.state.showHSAReports}
                    showLSAReports={this.state.showLSAReports}
                    showFlexReports={this.state.showFlexReports}
                    showDivisions={!this.state.hideDivisionsTab}
                    showCostCentres={!this.state.hideCostCentresTab}
                    showHSACustomCat={this.state.showHsaCustomCatReport}
                    showLSACustomCat={this.state.showLsaCustomCatReport}
                    teladocOnly={this.state.teladocOnly}
                    hasTeladoc={this.state.hasTeladoc}
                    hasCorehealth={this.state.hasCorehealth}
                />
            );
        }

        settings_tab({field, draw, action}: RenderEvent) {
            return (
                <SettingsScreen policyId={this.data.dsPolicy.Id} view={"plan_admin"}/>
            );
        }

        document_details({field, draw, action}: RenderEvent) {
            let storageUrl = "https://nhcfilestorage.blob.core.windows.net/docfiles";

            return (
                <Card label="Documents">
                    <Line>
                        <div>
                            <ul>
                                {!this.state.teladocOnly && (
                                    <li>
                                        <a
                                            style={{color: "#0d6efd"}}
                                            href={storageUrl + "/KTM-PA-Handbook.pdf"}
                                            target="_blank"
                                        >
                                            Plan Admin Handbook
                                        </a>
                                    </li>
                                )}
                                {!this.data.dsPolicy.IsBusinessOwner || !this.state.teladocOnly && (
                                    <>
                                        <li>
                                            <a
                                                style={{color: "#0d6efd"}}
                                                href={storageUrl + "/EmployeeUserHandbookHSA.pdf"}
                                                target="_blank"
                                            >
                                                Employee User Handbook HSA
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                style={{color: "#0d6efd"}}
                                                href={storageUrl + "/EmployeeUserHandbookLSA.pdf"}
                                                target="_blank"
                                            >
                                                Employee User Handbook LSA
                                            </a>
                                        </li>
                                    </>
                                )}
                                <li>
                                    <a
                                        style={{color: "#0d6efd"}}
                                        href={storageUrl + "/PlanAdminChangeRequestFillable.pdf"}
                                        target="_blank"
                                    >
                                        Plan Admin Change Request
                                    </a>
                                </li>
                                {!this.state.teladocOnly && (
                                    <li>
                                        <a
                                            style={{color: "#0d6efd"}}
                                            href={storageUrl + "/HSAIndemnityContract.pdf"}
                                            target="_blank"
                                        >
                                            HSA Indemnity Contract
                                        </a>
                                    </li>
                                )}
                                {this.data.dsPolicy.IsBusinessOwner || !this.state.teladocOnly && (
                                    <li>
                                        <a
                                            style={{color: "#0d6efd"}}
                                            href={storageUrl + "/HSA2PagerBusinessOwner.pdf"}
                                            target="_blank"
                                        >
                                            HSA Tax Savings
                                        </a>
                                    </li>
                                )}
                                {this.state.hasTeladoc && (
                                    <>
                                        <li>
                                            <a
                                                style={{color: "#0d6efd"}}
                                                href={storageUrl + "/TeladocWelcomeLetter.pdf"}
                                                target="_blank"
                                            >
                                                Teladoc Welcome Letter
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                style={{color: "#0d6efd"}}
                                                href={storageUrl + "/TeladocHowToAccess.pdf"}
                                                target="_blank"
                                            >
                                                Teladoc How to Access
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                style={{color: "#0d6efd"}}
                                                href={storageUrl + "/TeladocTerminationLetter.pdf"}
                                                target="_blank"
                                            >
                                                Teladoc Termination Letter
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                style={{color: "#0d6efd"}}
                                                href={storageUrl + "/EDISBenefit.pdf"}
                                                target="_blank"
                                            >
                                                Teladoc EDIS Benefit
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                style={{color: "#0d6efd"}}
                                                href={storageUrl + "/EDISWhatToKnow.pdf"}
                                                target="_blank"
                                            >
                                                Teladoc EDIS What to Know
                                            </a>
                                        </li>
                                    </>
                                )}
                                {this.data.dsPolicy.HasCorehealth ?
                                    <CertificateOfInsurance policyNo={this.data.dsPolicy.PolicyNo}
                                                            companyName={this.data.dsPolicyHolder.Name}
                                                            policyEffectiveDate={this.data.dsPolicy.CreationDate}
                                                            natPolicy={this.data.dsPolicy.NatPolicy}/>
                                    : <></> }
                            </ul>
                        </div>
                    </Line>
                </Card>
            );
        }

        common_tasks({field, draw, action}: RenderEvent) {
            return (
                <Card label="Common PA Tasks">
                    <table style={{borderCollapse: "separate", borderSpacing: "0 8px"}}>
                        <tr>
                            <td>
                                <a href="#" className="RT-Link">
                                    Add/Edit/Remove Employees
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href="#" className="RT-Link">
                                    Download Financial Invoices
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href="#" className="RT-Link">
                                    Download Reports
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href="#" className="RT-Link">
                                    Submit Spending Account Funds
                                </a>
                            </td>
                        </tr>
                    </table>
                </Card>
            );
        }

        support_articles({field, draw, action}: RenderEvent) {
            let supportUrl =
                "https://support.nationalhealthclaim.com/hc/en-us/articles";

            const SUPPORT_ARTICLES = [
                {
                    title: "Adding an employee",
                    slug: "227119967-Adding-an-employee",
                },
                {
                    title: "Remove an employee",
                    slug: "227254228-Remove-an-employee",
                },
                {
                    title: "Temporarily suspend employee coverage",
                    slug: "227256648-Temporarily-suspend-employee-coverage",
                },
                {
                    title: "Add dependents",
                    slug: "227120067-Add-dependents",
                },
                {
                    title: "Remove dependents",
                    slug: "228923668-Remove-dependents",
                },
                {
                    title: "Add Student Status coverage to dependents",
                    slug: "227254268-Add-Student-Status-coverage-to-dependents",
                },
                {
                    title: "How to submit funds",
                    slug: "227128147-How-to-submit-funds",
                },
                {
                    title: "What Claims Got Reimbursed?",
                    slug: "227128247-What-Claims-Got-Reimbursed-",
                },
                {
                    title: "How fast do claims get reimbursed?",
                    slug: "227129327-How-fast-do-claims-get-reimbursed-",
                },
                {
                    title: "Editing Benefit Categories",
                    slug: "227119727-Editing-Benefit-Categories",
                },
            ];

            return (
                <Card label="Support Articles To Answer Frequently Asked Questions">
                    <Line>
                        <div style={{paddingLeft: 30}}>
                            <ul>
                                {SUPPORT_ARTICLES.map((article) => (
                                    <li key={article.slug} style={{marginBottom: 10}}>
                                        <a
                                            className="support-link"
                                            href={`${supportUrl}/${article.slug}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{color: "#0d6efd"}}
                                        >
                                            {article.title}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Line>
                    <Line>
                        <div>
                            For a complete list of all our support articles{" "}
                            <a
                                style={{color: "#4987ef"}}
                                href={
                                    "https://support.nationalhealthclaim.com/hc/en-us/categories/203820867-Company-Plan-Administrator"
                                }
                                target="_blank"
                            >
                                click here
                            </a>{" "}
                            .
                        </div>
                    </Line>
                    <Line>
                        <div>
                            When accessing our support articles, you will be asked to identify
                            which version of our platform you are using.
                            <br/>
                            You are on Version 3 of our system.
                        </div>
                    </Line>
                </Card>
            );
        }

        help_panel({field, draw, action}: RenderEvent) {
            return (
                <Card label="Help">
                    <div>
                        Contact Support at{" "}
                        <a
                            className="RT-Link"
                            style={{display: "initial"}}
                            href="mailto:support@nationalhealthclaim.com"
                        >
                            support@nationalhealthclaim.com
                        </a>{" "}
                        or call our toll-free number 1-866-342-5908 during regular business
                        hours.
                        <br/>
                        Monday - Friday 8am - 4pm MST
                    </div>
                </Card>
            );
        }

        plan_admin_panel({field, draw, action}: RenderEvent) {
            let storageUrl = "https://nhcfilestorage.blob.core.windows.net/docfiles";
            let showRole = this.state.AssignedPas.find((pa) => pa.role != null);
            return (
                <Card label="Here are the Plan Administrators assigned to this policy holder:">
                    <table
                        style={{
                            border: "1px solid #ccc",
                            borderCollapse: "collapse",
                            margin: "0",
                            padding: "0",
                            width: "100%",
                            tableLayout: "auto",
                        }}
                    >
                        <thead>
                        <tr style={{border: "1px solid #ddd", padding: ".35em"}}>
                            <th style={{padding: ".35em", border: "1px solid #ddd"}}>
                                Last Name
                            </th>
                            <th style={{padding: ".35em", border: "1px solid #ddd"}}>
                                First Name
                            </th>
                            <th style={{padding: ".35em", border: "1px solid #ddd"}}>
                                Email
                            </th>
                            {showRole && (
                                <th style={{padding: ".35em", border: "1px solid #ddd"}}>
                                    Role
                                </th>
                            )}
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.AssignedPas.map((data, i) => {
                            return (
                                <tr key={i} style={{border: "1px solid #ddd", padding: ".35em"}}>
                                    <td style={{padding: ".35em", border: "1px solid #ddd"}}>
                                        {data.lastName}
                                    </td>
                                    <td style={{padding: ".35em", border: "1px solid #ddd"}}>
                                        {data.firstName}
                                    </td>
                                    <td style={{padding: ".35em", border: "1px solid #ddd"}}>
                                        {data.email}
                                    </td>
                                    {showRole && (
                                        <td style={{padding: ".35em", border: "1px solid #ddd"}}>
                                            {data.role}
                                        </td>
                                    )}
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                    <div style={{marginTop: 15}}>
                        If you need to add or remove a Plan Administrator,{" "}
                        <a
                            style={{color: "#4987ef"}}
                            href={storageUrl + "/PlanAdminChangeRequestFillable.pdf"}
                            target="_blank"
                        >
                            download and complete this form
                        </a>{" "}
                        and email it to Support at{" "}
                        <a
                            href="mailto:support@nationalhealthclaim.com"
                            style={{color: "#4987ef"}}
                        >
                            support@nationalhealthclaim.com
                        </a>
                        .
                    </div>
                </Card>
            );
        }

        pa_claim_notes({field, draw, action}: RenderEvent) {
            return (
                <Card label="Eligible to make claims?">
                    <div>
                        If you also receive coverage on this plan, use the same login
                        Information but login with our mobile app.
                        <br/>
                        <a
                            style={{color: "#4987ef"}}
                            href="https://support.nationalhealthclaim.com/hc/en-us/articles/229866428-Download-the-app-and-log-in-"
                            target="_blank"
                        >
                            {" "}
                            ClaimSnap{" "}
                        </a>
                        or use the online employee portal to make your personal claims.
                    </div>
                    <div style={{marginTop: 15}}>
                        <a
                            style={{color: "#4987ef"}}
                            href="https://employee.nationalhealthclaim.com/login"
                            target="_blank"
                        >
                            https://employee.nationalhealthclaim.com/login
                        </a>
                    </div>
                </Card>
            );
        }

        pa_companies({field, draw, action}: RenderEvent) {
            return (
                <Card label="Welcome to your Plan Administrator access">
                    <Line>
                        <div>
                            You are listed as a Plan Administrator for the following policy
                            holders:
                        </div>
                    </Line>
                    <Line>
                        <div style={{maxHeight: "200px"}}>
                            <table>
                                {this.state.AssignedPolicies.map((data) => {
                                    var link =
                                        "/ops/planadmin.policy.record?policy_id=" + data.policyId;
                                    return (
                                        <tr>
                                            <td>
                                                <a href={link} className="RT-Link">
                                                    {data.name}
                                                </a>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </table>
                        </div>
                    </Line>
                </Card>
            );
        }

        bo_admin_access_welcome({field, draw, action}: RenderEvent) {
            return (
                <Card label="Welcome to your Health Spending Account Administrator Access!">
                    <div>
                        After you have paid for your out-of-pocket health and medical
                        expenses with personal dollars (credit/debit card), submit your
                        receipts to us by logging into the mobile app
                        <a
                            style={{color: "#4987ef"}}
                            href="https://support.nationalhealthclaim.com/hc/en-us/articles/229866428-Download-the-app-and-log-in-"
                            target="_blank"
                        >
                            {" "}
                            ClaimSnap
                        </a>
                        , or log in to the online portal to upload receipts.
                    </div>
                    <div style={{marginTop: 15}}>
                        <a
                            style={{color: "#4987ef"}}
                            href="https://employee.nationalhealthclaim.com/login"
                            target="_blank"
                        >
                            https://employee.nationalhealthclaim.com/login
                        </a>
                    </div>
                </Card>
            );
        }

        bo_hsa_detail({field, draw, action}: RenderEvent) {
            return (
                <Card label="Why your business needs a Health Spending Account">
                    <div>
                        A Health Spending Account creates an environment that turns
                        after-tax personal medical expenses into before-tax business
                        deductions. By running your out-of-pocket health and dental expenses
                        through an HSA, you are able to recuperate that money through your
                        corporation tax-free.
                    </div>
                    <ReactPlayer
                        url="https://player.vimeo.com/video/821442220?h=6b9381f524&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        width="500px"
                        height="300px"
                        controls={true}
                    />
                </Card>
            );
        }

        async placeholder({screen}: ActionEvent) {
            window.open("https://employee.nationalhealthclaim.com");
        }

        ee_portal({field, draw, action}: RenderEvent) {
            return (
                <Card label="Need to Make Your Own Claims?">
                    <Line>
                        <Button
                            action={action(this.placeholder)}
                            label="Switch to your employee portal ->"
                            style="primary"
                        />
                    </Line>
                </Card>
            );
        }

        policy_details({field, draw, action}: RenderEvent) {
            return (
                <Card
                    label="Policy Details"
                    //   actions={
                    //     <>
                    //       <Button
                    //         action={action(this.edit_policy)}
                    //         label="Edit"
                    //         style="link"
                    //       />
                    //     </>
                    //   }
                >
                    <Line>
                        <Display field={field("PolicyNo")}/>
                        <Display
                            field={field("TaxName")}
                            toolTip={
                                <CustomToolTip
                                    header="Province"
                                    bodyContent="Each claim is taxed based on the home address of the employee."
                                />
                            }
                        />
                    </Line>
                </Card>
            );
        }

        plan_admin({field, draw, action}: RenderEvent) {
            return (
                <Card
                    label="Plan Administrator"
                    //   actions={
                    //     <>
                    //       <Button
                    //         action={action(this.edit_plan_admin)}
                    //         label="Edit plan admin"
                    //         style="link"
                    //       />
                    //     </>
                    //   }
                >
                    <Line>
                        <Display field={field("EnrollPAFirstName")}/>
                        <Display field={field("EnrollPALastName")}/>
                        <Display field={field("EnrollPAEmail")}/>
                    </Line>
                </Card>
            );
        }

        plan_admin_list({field, draw, action}: RenderEvent) {
            return (
                <Card label="Plan Administrator">
                    <table
                        style={{
                            border: "1px solid #ccc",
                            borderCollapse: "collapse",
                            margin: "0",
                            padding: "0",
                            width: "100%",
                            tableLayout: "fixed",
                        }}
                    >
                        <tr style={{border: "1px solid #ddd", padding: ".35em"}}>
                            <th style={{padding: ".35em", border: "1px solid #ddd"}}>
                                First Name
                            </th>
                            <th style={{padding: ".35em", border: "1px solid #ddd"}}>
                                Last Name
                            </th>
                            <th style={{padding: ".35em", border: "1px solid #ddd"}}>
                                Email
                            </th>
                        </tr>
                        {this.state.AssignedPas.map((data) => {
                            return (
                                <tr style={{border: "1px solid #ddd", padding: ".35em"}}>
                                    <td style={{padding: ".35em", border: "1px solid #ddd"}}>
                                        {data.firstName}
                                    </td>
                                    <td style={{padding: ".35em", border: "1px solid #ddd"}}>
                                        {data.lastName}
                                    </td>
                                    <td style={{padding: ".35em", border: "1px solid #ddd"}}>
                                        {data.email}
                                    </td>
                                </tr>
                            );
                        })}
                    </table>
                </Card>
            );
        }

        year_details({field, draw, action}: RenderEvent) {
            return (
                <Card label="Benefit Year Details">
                    <Line>
                        <Display field={field("@dsBenefitYear.StartDate")}/>
                        <Display field={field("@dsBenefitYear.EndDate")}/>
                        <Display field={field("@dsBenefitYear.RunoffMonths")}/>
                    </Line>
                </Card>
            );
        }

        add_toolbar({field, draw, action}: RenderEvent) {
            return (
                <div className="RT-Action-Bar">
                    <Button
                        action={action(this.add_year)}
                        label="Add benefit year"
                        style="add"
                    />
                </div>
            );
        }

        policy_holder({field, draw, action}: RenderEvent) {
            return (
                <Card
                    label="General"
                    //   actions={
                    //     <>
                    //       <Button
                    //         action={action(this.edit_policy_holder)}
                    //         label="Edit"
                    //         style="link"
                    //       />
                    //       <Button
                    //         action={action(this.change_policy_holder)}
                    //         label="Change account holder"
                    //         style="link"
                    //       />
                    //     </>
                    //   }
                >
                    <Line>
                        <Display
                            field={field("@dsPolicyHolder.DBA")}
                            toolTip={
                                <CustomToolTip
                                    header="DBA"
                                    bodyContent="Doing Business As. If your business operates under a name that is different from it’s legal name, you can enter it here. "
                                />
                            }
                        />
                        <Display field={field("@dsPolicyHolder.LegalForm")}/>
                    </Line>
                    <Display field={field("@dsPolicyHolder.MailingStreet1")}/>
                    <Display field={field("@dsPolicyHolder.MailingStreet2")}/>
                    <Line>
                        <Display field={field("@dsPolicyHolder.MailingCity")}/>
                        <Display field={field("@dsPolicyHolder.MailingProv")}/>
                    </Line>
                    <Line>
                        <Display field={field("@dsPolicyHolder.MailingCountry")}/>
                        <Display field={field("@dsPolicyHolder.MailingPostalCode")}/>
                    </Line>
                    <Display field={field("@dsPolicyHolder.Phone")}/>
                </Card>
            );
        }

        // balance({ field, draw, action }: RenderEvent) {
        //   return (
        //     <Card
        //       label="Balance"
        //       actions={
        //         <>
        //           <Button
        //             action={action(this.add_to_balance)}
        //             label="Add funds"
        //             style="link"
        //           />
        //         </>
        //       }
        //     >
        //       <Display
        //         field={field("@dsPolicyBalance.Balance")}
        //         size="medium"
        //         textStyle="bold"
        //       />
        //     </Card>
        //   );
        // }

        advisor({field, draw, action}: RenderEvent) {
            return (
                <Card label="Primary Advisor">
                    <Line>
                        <Display field={field("@dsAdvisor.Name")} textStyle="normal"/>
                        <Display field={field("@dsAdvisor.Phone")}/>
                        <Display field={field("@dsAdvisor.Email")}/>
                    </Line>
                    <Display field={field("@dsAdvisor.Distro")}/>
                </Card>
            );
        }

        sidebar({draw}: RenderEvent) {
            return (
                <Sidebar>
                    <Tab label="Year dashboard" template={this.year_dashboard}/>
                    <Tab label="Branding" template={this.branding}/>
                </Sidebar>
            );
        }

        paSidebar({draw}: RenderEvent) {
            let policyLinks = this.state.AssignedPolicies.map((data, i) => {
                return (
                    <a
                        href={"/ops/planadmin.policy.record?policy_id=" + data.policyId}
                        key={i}
                        className="sidebar-policy-link"
                    >
                        <div style={{display: "block", position: "relative"}}>
              <span
                  style={{
                      paddingLeft: "15px",
                      display: "block",
                      whiteSpace: "nowrap",
                      marginLeft: "8px",
                      marginRight: "auto",
                  }}
              >
                {data.name}
              </span>
                        </div>
                    </a>
                );
            });

            return (
                <div className="pa-sidebar">
                    <label
                        style={{
                            paddingTop: "20px",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                            color: "white",
                            display: "block",
                        }}
                    >
                        Policy Admin
                    </label>
                    {policyLinks}
                </div>
            );
        }

        year_dashboard({field, draw, action}: RenderEvent) {
            return <Year_dashboardScreen policy_id={this.data.dsPolicy.Id}/>;
        }

        branding({field, draw, action}: RenderEvent) {
            return (
                <Card label="Branding">
                    {draw(this.brand_logo)}
                    {draw(this.brand_color)}
                </Card>
            );
        }

        brand_logo({field, draw, action}: RenderEvent) {
            return <FileUpload field={field("@logo")}/>;
        }

        brand_color({field, draw, action}: RenderEvent) {
            return <ColorSwatch field={field("@brandColor")}/>;
        }

        async configure_ui({screen}: ActionEvent) {
            screen.hide(this.add_year);
            screen.hide(this.year_details);
            let year = this.data.dsPolicy.CurrentBenYear_Id;

            if (!year) {
                screen.unhide(this.add_year);
            } else {
                screen.unhide(this.year_details);
            }
        }

        async edit_policy({screen}: ActionEvent) {
            await screen.openDialog(
                <Edit_detailsScreen policy_id={this.data.dsPolicy.Id}/>,
                {onRecordChanged: this.refresh_data}
            );
        }

        async edit_plan_admin({screen}: ActionEvent) {
            await screen.openDialog(
                <Edit_plan_adminScreen policy_id={this.data.dsPolicy.Id}/>,
                {onRecordChanged: this.refresh_data}
            );
        }

        async edit_policy_holder({screen}: ActionEvent) {
            await screen.openDialog(
                <Edit_policy_holderScreen
                    policyholder_id={this.data.dsPolicyHolder.Id}
                />,
                {onRecordChanged: this.refresh_data}
            );
            screen.recordChanged("updated", screen.getValue("@dsPolicy.Id"));
        }

        async change_advisor({screen}: ActionEvent) {
            await screen.openDialog(
                <Change_advisorScreen policy_id={this.data.dsPolicy.Id}/>,
                {onRecordChanged: this.refresh_data}
            );
        }

        async change_policy_holder({screen}: ActionEvent) {
            await screen.openDialog(
                <Change_policy_holderScreen policy_id={this.data.dsPolicy.Id}/>,
                {onRecordChanged: this.refresh_data}
            );
        }

        async edit_note({screen}: ActionEvent) {
            await screen.openDialog(
                <Edit_noteScreen policy_id={this.data.dsPolicy.Id}/>,
                {onRecordChanged: this.refresh_data}
            );
        }

        async add_year({screen}: ActionEvent) {
            await screen.openDialog(
                <Add_first_yearScreen policy_id={this.data.dsPolicy.Id}/>,
                {onRecordChanged: this.refresh_data}
            );
        }

        async edit_year({screen}: ActionEvent) {
            await screen.openDialog(
                <Edit_first_yearScreen
                    year_id={this.data.dsPolicy.CurrentBenYear_Id}
                />,
                {onRecordChanged: this.refresh_data}
            );
        }

        async refresh_data({screen}: ActionEvent) {
            await screen.restart();
        }

        async get_funding_summary() {
            let response = await fetch(
                "/api/PlanAdmin/funding?policyId=" + this.data.dsPolicy.Id
            );
            let funding = await response.json();
            this.setState({
                FundingBalance: funding.fundingBalance,
                OnHoldAmount: funding.onHoldAmount,
                ApprovedClaimCount: funding.approvedClaimCount,
                ApprovedClaimTotal: funding.approvedClaimTotal,
                Shortfall: funding.shortfall,
                PolicyName: funding.policyName,
                PolicyAddress: funding.policyAddress,
                PolicyCity: funding.policyCity,
                PolicyProvince: funding.policyProvince,
                PolicyPostal: funding.policyPostal,
                teladocOnly: funding.teladocOnly,
                hasTeladoc: funding.hasTeladoc,
                hasCorehealth: funding.hasCorehealth,
            });
        }

        async get_assigned_policies() {
            let response = await fetch("/api/PlanAdmin/assignedpolicies");
            let data = await response.json();
            this.setState({
                AssignedPolicies: data.policies,
            });
        }

        async get_assigned_pas() {
            let response = await fetch(
                "/api/PlanAdmin/getplanadmins?policyId=" + this.data.dsPolicy.Id
            );
            let planadmins = await response.json();
            this.setState({AssignedPas: planadmins});
        }

        async getAccess() {
            let response = await fetch(
                "/api/PlanAdmin/hasaccess?policyId=" + this.data.dsPolicy.Id
            );
            let access = await response.json();
            this.setState({hasAccess: access});
        }

        async get_minimum_funding() {
            let response = await fetch(
                "/api/PlanAdmin/minimumbalance?policyId=" + this.data.dsPolicy.Id
            );
            let MinimumBalance = await response.json();
            this.setState({MinimumFunding: MinimumBalance.minimumBalance});
        }

        async getPayeeAccountNumber() {
            let response = await fetch(
                "/api/PlanAdmin/payeeaccountnumber?policyId=" + this.data.dsPolicy.Id
            );
            let res = await response.json();
            this.setState({PayeeAccountNumber: res.spendingAccountNumber});
        }

        async updateDivisionsTab() {
            let response = await fetch(
                "/api/PlanAdmin/divisions?policyId=" + this.data.dsPolicy.Id
            );
            let res = await response.json();
            this.setState({hideDivisionsTab: res.divisions.length == 0});
        }

        async updateCostCentresTab() {
            let response = await fetch(
                "/api/PlanAdmin/costcentres?policyId=" + this.data.dsPolicy.Id
            );
            let res = await response.json();
            this.setState({hideCostCentresTab: res.costCentres.length == 0});
        }

        async updateReportsTab() {
            let response = await fetch(
                "/api/PlanAdmin/updatereportstab?policyId=" + this.data.dsPolicy.Id
            );
            let res = await response.json();
            this.setState({
                showHSAReports: res.hasHsa,
                showLSAReports: res.hasLsa,
                showFlexReports: res.hasFlex,
            });
        }

        update_minimum_funding = async (val) => {
            const requestOptions = {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    PolicyId: this.data.dsPolicy.Id,
                    MinimumBalance: val,
                }),
            };

            await fetch("/api/PlanAdmin/updateminimumbalance", requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    this.get_minimum_funding();
                    this.setState({
                        ShowMinimumBalanceModal: false,
                    });
                    toastr.success("Notification settings updated", "Success");
                });
        };

        toggleMinimumBalanceModal = async () => {
            this.setState({
                ShowMinimumBalanceModal: !this.state.ShowMinimumBalanceModal,
            });
        };

        async edit_minimumBalance({screen}: ActionEvent) {
            await this.toggleMinimumBalanceModal();
        }

        toggleOnlineBankingModal = async () => {
            this.setState({
                showOnlineBankingModal: !this.state.showOnlineBankingModal,
            });
        };

        togglePADModal = async () => {
            this.setState({
                showPADModal: !this.state.showPADModal,
            });
        };

        toggleCompanyCheque = async () => {
            this.setState({
                showCompanyCheque: !this.state.showCompanyCheque,
            });
        };

        handleComapnyChequeSave = async () => {
            this.setState({
                showCompanyCheque: !this.state.showCompanyCheque,
            });
            await this.generatePDFDocumentForCompanyCheque();
        };

        checkIsMigratedCompany = async () => {
            let response = await fetch(
                "/api/PlanAdmin/ismigratedcompany?policyId=" + this.data.dsPolicy.Id
            );
            let res = await response.json();
            this.setState({isMigratedCompany: res.isMigratedCompany});
        };

        generatePDFDocumentForCompanyCheque = async () => {
            let comapnyName = this.data.dsPolicyHolder.Name;
            let policyNumber = this.data.dsPolicy.PolicyNo;
            let chequeAmount = this.state.customChequeAmount;

            let content = {
                pageOrientation: "landscape",
                content: [
                    {
                        columns: [
                            {
                                image: base64.logo,
                                width: 230,
                            },
                            {
                                text:
                                    comapnyName +
                                    " (#" +
                                    policyNumber +
                                    ") \n Cheque Amount: $" +
                                    chequeAmount,
                                fontSize: 12,
                                bold: true,
                                alignment: "right",
                                margin: [0, 0, 20, 0],
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                width: "*",
                                text: [
                                    "\n\n\nNational HealthClaim Corp (FC)\n",
                                    "5320 - 7005 Fairmount Dr. SE\n",
                                    "Calgary, AB\n",
                                    "T2H 0K4\n",
                                ],
                                margin: [30, 0, 0, 50],
                            },
                        ],
                    },
                    {
                        canvas: [
                            {type: "line", x1: 0, y1: 5, x2: 750, y2: 5, lineWidth: 1},
                        ],
                    },
                    {
                        text: "Please attach your company cheque, made out to National HealthClaim Corporation and mail it to the address above. You may use a windowed envelope.",
                        margin: [0, 5, 0, 100],
                        fontSize: 10,
                    },
                    {
                        canvas: [
                            {type: "line", x1: 0, y1: 5, x2: 750, y2: 5, lineWidth: 1},
                        ],
                    },
                ],
            };

            pdfMake.createPdf(content).download("CompanyCheque.pdf");
        };

        async updateCustomCategoryTabs() {
            let response = await fetch(
                "/api/PlanAdmin/showcustomcategoryreports?policyId=" + this.data.dsPolicy.Id
            );
            let res = await response.json();
            this.setState({
                showHsaCustomCatReport: res.showHsaCustomCatReport,
                showLsaCustomCatReport: res.showLsaCustomCatReport
            });
        }
    }
);
