import React from "react";
import Spinner from "components/Spinner";
import axios from "axios";

export default class EligibleExpensesScreen extends React.Component<any, any> {
  state = {
    loaded: false,
    expensesCategories: null,
    openAccordions: [0]
  };

  componentDidMount() {
      this.startLoading();
  }

  async startLoading() {
    const params = new URLSearchParams(window.location.search);
    const employeeId = params.get('employeeId');
    const type = params.get('type');

    axios
      .get(`/api/OpsEmployee/geteligibleexpenses?employeeId=${employeeId}&type=${type}`)
      .then((response) => {
        this.setState({ 
          loaded: true, 
          expensesCategories: response.data.expensesCategories,
        });
      })
      .catch((error) => {
      });
  }

  toggleAccordion = (index: number) => {
    this.setState(prevState => {
      const isOpen = prevState.openAccordions.includes(index);
      return {
        openAccordions: isOpen 
          ? prevState.openAccordions.filter(i => i !== index)
          : [...prevState.openAccordions, index]
      };
    });
  };

  renderAccordionContent(index: number) {
    const { expensesCategories } = this.state;
    const params = new URLSearchParams(window.location.search);
    const employeeId = params.get('employeeId');
    const type = params.get('type');
    
    let content = null;
    if(index === 0)
    {
      content = (
        <ExpenseDetails expensesType={expensesCategories[0]} type={type} />
      );
    }
    else
    {
      content = (
        <CustomCategoriesDetails customCategory={expensesCategories[index].benefits} />
      );
    }

    return content;
  }

  renderContent() {
    const { loaded, expensesCategories } = this.state;

    if (!loaded) {
      return <Spinner />;
    }

    const printButton = (
      <button className="btn btn-primary print-button mg-r-15" onClick={() => window.print()}>
        <i className="fa fa-print mg-r-10" > Print Document </i>
      </button>
    );

    return (
      <div className="br-mainpanel pd-b-50">
        <div className="pd-t-20 pd-sm-t-30" style={{ paddingTop: 20, position: "relative" }}>
          <div 
            className="accordion"
            style={{
              marginLeft: 50,
              marginRight: 50,
              maxWidth: 1250
            }}>
            {expensesCategories.map((data, i) => (
              <>
                <div 
                  key={i} 
                  className="accordion-header"
                  style={{
                    backgroundColor: data.backgroundColor,
                    color: data.textColor,
                    textTransform: "uppercase",
                    fontWeight: 500,
                    height: 40,
                    padding: 10,
                    maxWidth: 1250,
                  }}
                  onClick={() => this.toggleAccordion(i)}
                >
                  {data.label}
                  <i className={this.state.openAccordions.includes(i) ? "fa fa-chevron-up d-print-none" : "fa fa-chevron-down d-print-none"} style={{ float: "inline-end" }}></i>
                </div>
                {this.state.openAccordions.includes(i) && (
                  <div className="accordion-body" style={{padding:0}}>
                    {this.renderAccordionContent(i)}
                  </div>
                )}
              </>
            ))}
          </div>
          <div className="mg-t-20 mg-b-30 mg-x-50 d-print-none">
              {printButton}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <>{this.renderContent()}</>;
  }
}

const ExpenseDetails = ({ expensesType, type }) => {
  const lsaDep = type === "LSA" && expensesType.benefits.length > 0 && (
    expensesType.benefits[0].lsaDepClaim 
    ? <p>Your lifestyle spending account extends coverage to both yourself and the eligible dependents added to your account.</p>
    : <p>Your lifestyle spending account is an employee only benefit and does not cover expenses for dependents added to your account.</p>
  );

  let expenseRows = expensesType.benefits.map((data, i) => {
    const descriptionItems = data.description.split('\\n').map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));

    if ((i + 1) % 5 === 0) {
      return (
        <>
          <tr key={i}>
            <td>{i + 1}</td>
            <td>{data.shortName}</td>
            <td>
                {descriptionItems}
            </td>
            <td className="text-right">{data.coverageRate}%</td>
          </tr>
          <div className="pagebreak"> </div>
        </>
      );
    } else {
      return (
        <>
        <tr key={i}>
          <td>{i + 1}</td>
          <td>{data.shortName}</td>
          <td>
                {descriptionItems}
          </td>
          <td className="text-right">{data.coverageRate}%</td>
        </tr>
        <div className="no-pagebreak"> </div>
        </>
      );
    }
  });

  let description;
  if (type == "LSA") {
    if (expensesType.label === "Wellness Spending Account") {
      description = (
        <div>
          <p>
            This benefit is not insurance but a spending account that reimburses
            you for certain wellness expenses. This means you pay for the
            expenses first, and then submit receipts to us, and we process them
            on behalf of your company.
          </p>
          <div className="pd-l-15">
            <p>
              <b>Note:</b> The Wellness Spending Account (WSA) is reimbursed by
              the date the payment is made for each expense.
            </p>
          </div>
          <p>
            To claim lifestyle expenses you must submit the detailed invoice
            showing who the expense is for, what is being purchased, purchase
            date, total cost, and a confirmation of payment including payment
            date.
          </p>
          {lsaDep}
        </div>
      );
    } else {
      description = (
        <div>
          <p>
            This benefit is not insurance but a spending account that reimburses
            you for certain wellness expenses. This means you pay for the
            expenses first, and then submit receipts to us, and we process them
            on behalf of your company.
          </p>
          <div className="pd-l-15">
            <p>
              <b>Note:</b> The Lifestyle Spending Account (LSA) is reimbursed by
              the date the payment is made for each expense.
            </p>
          </div>
          <p>
            To claim lifestyle expenses you must submit the detailed invoice
            showing who the expense is for, what is being purchased, purchase
            date, total cost, and a confirmation of payment including payment
            date.
          </p>
          {lsaDep}
        </div>
      );
    }
  } else {
    description = (
      <div>
        <p>
          Use this tax-free account to get coverage for the expense(s) listed below.
        </p>
        <div className="pd-l-15">
          <p>
            <b>Note:</b>  You must submit your expenses to all relevant insurance carriers first before using the National HealthClaim (NHC) spending account.
            We are the last payor. Any time there is insurance coverage involved we require the detailed Explanation of Benefits document
            (EOB, sometimes called a Claim Statement) from all insurance providers including other HSAs, for each expense you are claiming.
            This detailed document is issued directly by your insurance provider and shows the treatment/service date, patient name,
            treatment/service details, total cost of treatment, the amount the carriers paid toward each expense (even if it is 0) and
            all applicable messaging/notes for each expense regarding why insurance paid the amounts they did. If you do not have your
            copy, contact your insurance carrier directly to obtain it. Please note, online copies or screenshots are acceptable.
            Summary documents are not acceptable as they do not include all of the required details listed above.
          </p>
          <p>
            If you don’t have other coverage for your expense(s), submit to NHC directly.
          </p>
          <p>
            NHC is as diligent as possible in processing your claims and follows guidelines set by
            Canada Revenue Agency (CRA) to determine the eligibility of medical expenses.
          </p>
        </div>
        <p className="tx-medium pd-b-10 pd-t-5">What can I claim?</p>
      </div>
    );
  }

  return (
    <div className="no-padding no-print-margin" style={{ maxWidth: 1250 }} id="printableArea">
        <div 
          className="no-padding document-body"
          style={{
            boxShadow: "0 3px 8px rgba(0, 0, 0, 0.25)"
          }}
        >
          <div className="card no-print-border no-print-margin" style={{ color: "black" }}>
            <div className="card-body" style={{paddingTop: 20}}>
              <div className="mg-b-0">
                {description}
                <div
                  className="bd bd-gray-300 rounded table-responsive"
                >
                  <table
                    className="table table-hover black"
                    style={{ marginBottom: 0 }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: 35 }}></th>
                        <th style={{ width: 200 }}>Name</th>
                        <th>Description</th>
                        <th style={{ width: 150 }} className="text-right">
                          Coverage Rate
                        </th>
                      </tr>
                    </thead>
                    <tbody>{expenseRows}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

const CustomCategoriesDetails = ({ customCategory }) => {
  return (
    <div className="no-padding no-print-margin" style={{ maxWidth: 1250 }}>
        <div 
          className="no-padding document-body"
          style={{
            boxShadow: "0 3px 8px rgba(0, 0, 0, 0.25)"
          }}
        >
          <div className="card-body" style={{paddingTop: 20}}>
              <div className="bd bd-gray-300 rounded table-responsive">
                <table className="table table-hover black" style={{ marginBottom: 0}}>
                  <thead>
                    <tr>
                      <th style={{ width: 200 }}>Name</th>
                      <th>Description</th>
                      <th style={{ width: 150 }} className="text-right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{customCategory[0].shortName}</td>
                      <td>{customCategory[0].description}</td>
                      <td className="text-right">${customCategory[0].amount}</td>
                    </tr>
                  </tbody>
                </table>
            </div>
        </div>
      </div>
    </div>
  );
};
