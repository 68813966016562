import axios from "axios";
import React from "react";
import { Button, Table } from "react-bootstrap";
import toastr from "toastr";
import { CSVLink } from "react-csv"; 
import { CustomLoading } from "components/CustomLoading";
import "../../styles/Reports.css";
import { EmptyStateTemplate } from "./Empty_state_Template";
import CustomPagination from "components/CustomPagination";
import CustomToolTip from "components/CustomToolTip";
import { DateDropdownWithCalendarPicker } from "./DateDropdownWithCalendarPicker";
import { CustomDropdown } from "components/CustomDropdown";
import base64 from "../../data/base64.json";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class LsaBenefitUtilizationTable extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            dataToExport: [],
            selectedOption: this.props.benefitYearOptions[0],
            currentPage: 1,
            itemsPerPage: 12,
            totalLimit: 0,
            totalBalance: 0,
            totalCarriedIn: 0,
            percentageUtilization: 0,
            totalBalanceUsed: 0,
            showInactiveEmployees: false,
            selectedDivision: {id:null, name:"Display All"},
            divisions: [{id:null, name:"Display All"}, ...this.props.divisions],
            selectedCostCentre: {id:null, name:"Display All"},
            costCentres: [{id:null, name:"Display All"}, ...this.props.costCentres],
            hasSubcat: false,
            subcatTotalLimit: 0,
            subcatCategoryTotalLimit: 0,
            subcatTotalBalance: 0,
            subcatTotalUsage: 0,
            subcatUtilizationPercentage: 0,
            totalCategoryLimit: 0,
            combinedTotalLimit: 0,
            combinedCategoryLimit: 0,
            combinedBalance: 0,
            combinedUsage: 0,
            combinedUtilization: 0,
            isBonusCreditEnabled: false,
            totalBonusCredit: 0
        };

        this.setSelectedOption = this.setSelectedOption.bind(this);
        this.setCurrentPage = this.setCurrentPage.bind(this);
        this.fetchLsaData = this.fetchLsaData.bind(this);
        this.toggleInactiveEmployees = this.toggleInactiveEmployees.bind(this);
        this.getTotals = this.getTotals.bind(this);
    }

    componentDidMount() {
        this.setState({loading: true});
        this.fetchLsaData();
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedOption, showInactiveEmployees } = this.state;
        if (prevState.selectedOption !== selectedOption 
            || prevState.showInactiveEmployees !== showInactiveEmployees
            || prevState.selectedCostCentre !== this.state.selectedCostCentre
            || prevState.selectedDivision !== this.state.selectedDivision)
        {
            this.setState({loading: true});
            this.fetchLsaData();
        }
    }

    fetchLsaData = () => {
        const { selectedOption } = this.state;
        var apiString: string;
        if (selectedOption.id === null) {
            apiString = `/api/Reports/lsabenefitutilization?policyId=${this.props.policyId}&fromDate=${selectedOption.startDate.toLocaleString()}&toDate=${selectedOption.endDate.toLocaleString()}`;
        } else {
            apiString = `/api/Reports/lsabenefitutilization?policyId=${this.props.policyId}&benefitYearId=${selectedOption.id}`;
        }
        axios.get(apiString)
            .then(response => {
                if (response.data.success) {
                    this.getTotals(response.data);
                } else {
                    toastr.error(response.data.message, "Error");
                }
                this.setState({loading: false});
            })
            .catch(error => {
                toastr.error(error, "Error");
            });
    }

    setSelectedOption = (option) => {
        // if(!this.props.isMigratedCompany) {
            let findOption = this.props.benefitYearOptions.find(x => x.dateRange === option);
            this.setState({selectedOption: findOption});
        // } else {
        //     this.setState({selectedOption: option});
        // }
    }
    
    setCurrentPage = (page) => {
        this.setState({currentPage: page});
    }

    toggleInactiveEmployees = () => {
        const { showInactiveEmployees } = this.state;
        this.setState({
            showInactiveEmployees: !showInactiveEmployees,
        });
    }

    getTotals = (data) => {
        const { 
            showInactiveEmployees, 
            selectedOption,
            selectedCostCentre,
            selectedDivision,
        } = this.state;

        const { 
            showCostCentres,
            showDivisions,
        } = this.props;

        var utilizationData = data.result;

        if(!showInactiveEmployees) {
            utilizationData = data.result.filter(x => x.isActive);

            if(data.hasSubcat){
                this.setState({
                    hasSubcat: true,
                    subcatTotalLimit: data.subcatTotals.active_total_limit,
                    subcatCategoryTotalLimit: data.subcatTotals.active_total_category_limit,
                    subcatTotalBalance: data.subcatTotals.active_total_balance,
                    subcatTotalUsage: data.subcatTotals.active_total_usage,
                    subcatUtilizationPercentage: data.subcatTotals.active_utilization_percentage
                })
            }
        }

        if(showInactiveEmployees) {
            if(data.hasSubcat){
                this.setState({
                    hasSubcat: true,
                    subcatTotalLimit: data.subcatTotals.total_limit,
                    subcatCategoryTotalLimit: data.subcatTotals.total_category_limit,
                    subcatTotalBalance: data.subcatTotals.total_balance,
                    subcatTotalUsage: data.subcatTotals.total_usage,
                    subcatUtilizationPercentage: data.subcatTotals.utilization_percentage
                })
            }
        }

        if(selectedCostCentre.id !== null) {
            utilizationData = data.result.filter(x => x.costCentreId === selectedCostCentre.id);
        }

        if(selectedDivision.id !== null) {
            utilizationData = data.result.filter(x => x.divisionId === selectedDivision.id);
        }

        let dataToExport = [];
        let totalLimit = 0;
        let totalCategoryLimit = 0;
        let totalBalance = 0;
        let totalCarriedIn = 0;
        let totalBalanceUsed = 0;
        let totalBonusCredit = 0;

        for (let i = 0; i < utilizationData.length; i++) {
            let item = utilizationData[i];
            let formattedItem = [
                item.lastName,
                item.firstName,
                item.employeeNo,
            ];
            if(showCostCentres) {
                formattedItem.push(item.costCentreName);
            }

            if(showDivisions) {
                formattedItem.push(item.divisionName);
            }

            formattedItem.push(
                item.isActive ? "Active" : "Inactive", 
                item.limit,
                item.categoryLimit,
            );

            if(data.hasBonusCredit) {
                formattedItem.push(item.bonusCredit);
            }

            formattedItem.push(
                item.carriedIn,
                item.balance,  
                item.balanceUsed, 
                item.utilizationPercentage+"%",
            );

            totalLimit += parseFloat(item.limit);
            totalCategoryLimit += parseFloat(item.categoryLimit);
            totalCarriedIn += parseFloat(item.carriedIn);
            totalBalance += parseFloat(item.balance);
            totalBalanceUsed += parseFloat(item.balanceUsed);
            totalBonusCredit += parseFloat(item.bonusCredit);
            dataToExport.push(formattedItem);
        }

        const headerRow = [
            "Last Name", 
            "First Name", 
            "Employee No"
        ];

        if(showCostCentres) {
            headerRow.push("Cost Centre");
        }

        if(showDivisions) {
            headerRow.push("Division");
        }

        headerRow.push(
            "Status", 
            "LSA Total Limit", 
            "LSA Category Limit", 
        );

        if(data.hasBonusCredit) {
           headerRow.push("LSA Bonus Credit");
        }

        headerRow.push(
            "LSA Carried In", 
            "LSA Balance", 
            "LSA Amount Used", 
            "LSA Utilization %"
        );

        dataToExport.unshift(headerRow);

        let totalItem;
        if (this.state.hasSubcat) {
            totalItem = ["Main Benefit Total", "", "", ""];
        } else {
            totalItem = ["Total", "", "", ""];
        }

        if(showCostCentres) {
            totalItem.push("");
        }

        if(showDivisions) {
            totalItem.push("");
        }

        let percentageUtilization = totalLimit != 0 ? ((totalBalanceUsed / totalLimit) * 100).toFixed(2) : "0.00";

        totalItem.push(
            totalLimit.toFixed(2), 
            totalCategoryLimit.toFixed(2), 
        );

        if(data.hasBonusCredit) {
            totalItem.push(totalBonusCredit).toFixed(2);
        }
 
        totalItem.push(
            totalCarriedIn.toFixed(2), 
            totalBalance.toFixed(2), 
            totalBalanceUsed.toFixed(2),
            percentageUtilization+"%"
        );

        dataToExport.push(totalItem);

        let combinedTotalAmount = 0;
        let combinedCategoryTotalAmount = 0;
        let combinedBalanceAmount = 0;
        let combinedUsageAmount = 0;
        let combinedUtilization = "";
        if (this.state.hasSubcat) {
            let subCatTotalRow = ["Enhanced Benefit Total", "", "", "",];
            let combinedTotalRow = ["Overall Combined Total", "", "", "",];

            if(showCostCentres) {
                subCatTotalRow.push("");
                combinedTotalRow.push("");
            }
    
            if(showDivisions) {
                subCatTotalRow.push("");
                combinedTotalRow.push("");
            }

            subCatTotalRow.push(
                this.state.subcatTotalLimit,
                this.state.subcatCategoryTotalLimit,
            );

            if(data.hasBonusCredit) {
                subCatTotalRow.push("");
            }

            subCatTotalRow.push(
                "",
                this.state.subcatTotalBalance, 
                this.state.subcatTotalUsage, 
                this.state.subcatUtilizationPercentage+"%"
            );

            dataToExport.push(subCatTotalRow);

            combinedTotalAmount = parseFloat(this.state.subcatTotalLimit) + totalLimit;
            combinedCategoryTotalAmount = parseFloat(this.state.subcatCategoryTotalLimit) + totalCategoryLimit;
            combinedBalanceAmount = parseFloat(this.state.subcatTotalBalance) + totalBalance;
            combinedUsageAmount = parseFloat(this.state.subcatTotalUsage) + totalBalanceUsed;
            combinedUtilization = combinedTotalAmount != 0 ? ((combinedUsageAmount / combinedTotalAmount) * 100).toFixed(2) : "0.00";

            combinedTotalRow.push(
                combinedTotalAmount.toFixed(2),
                combinedCategoryTotalAmount.toFixed(2),
            );

            if(data.hasBonusCredit) {
                combinedTotalRow.push("");
            }

            combinedTotalRow.push(
                "",
                combinedBalanceAmount.toFixed(2), 
                combinedUsageAmount.toFixed(2), 
                combinedUtilization+"%"
            );

            dataToExport.push(combinedTotalRow);
        }

        const dateRangeRow = [
            "Date Range",
            `${selectedOption.dateRange}`
        ];

        dataToExport.unshift(dateRangeRow);
        
        this.setState({
            data: utilizationData,
            dataToExport: dataToExport,
            totalLimit: totalLimit.toFixed(2),
            totalCategoryLimit: totalCategoryLimit.toFixed(2),
            totalBalance: totalBalance.toFixed(2),
            totalCarriedIn: totalCarriedIn.toFixed(2),
            totalBalanceUsed: totalBalanceUsed.toFixed(2),
            percentageUtilization: percentageUtilization,
            combinedTotalLimit: combinedTotalAmount.toFixed(2),
            combinedCategoryLimit: combinedCategoryTotalAmount.toFixed(2),
            combinedBalance: combinedBalanceAmount.toFixed(2),
            combinedUsage: combinedUsageAmount.toFixed(2),
            combinedUtilization: combinedUtilization,
            isBonusCreditEnabled: data.hasBonusCredit,
            totalBonusCredit: totalBonusCredit.toFixed(2),
        });
    }

    setSelectedCostCentre = (option) => {
        let findCostCentre = this.state.costCentres.find(cc => cc.name === option);
        this.setState({
            selectedCostCentre: findCostCentre,
        });
    }

    setSelectedDivsion = (option) => {
        let findDivision = this.state.divisions.find(div => div.name === option);
        this.setState({
            selectedDivision: findDivision,
        });
    }

    exportToPdf = () => {
        const { dataToExport, hasSubcat } = this.state;
    
        const header = dataToExport[1];
        let bodyRows;
        let total;
        let additionalAccountTotal;
        let combinedTotal;
        if (hasSubcat) {
            bodyRows = dataToExport.slice(2, dataToExport.length - 3);
            total = dataToExport[dataToExport.length - 3];
            additionalAccountTotal = dataToExport[dataToExport.length - 2];
            combinedTotal = dataToExport[dataToExport.length - 1];
        }else{
            bodyRows = dataToExport.slice(2, dataToExport.length - 1);
            total = dataToExport[dataToExport.length - 1];
        }
      
        const headerData = this.formatRow(header, 'tableHeader');
        const bodyData = this.formatTableBody(bodyRows);
        bodyData.push(this.formatRow(total, 'tableFooter'));

        if (hasSubcat) {
            bodyData.push(this.formatRow(additionalAccountTotal, 'tableFooter'), this.formatRow(combinedTotal, 'tableFooter'));
        }

        const docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            content: [
                {
                    columns: [
                        this.createLogo(),
                        this.createDateRange(dataToExport[0][1]),
                    ],
                },
                this.createTitle(),
                this.createTable(headerData, bodyData)
            ],
            styles: this.getPdfStyles(),
            layout: this.getTableLayout()
        };
    
        pdfMake.createPdf(docDefinition).download("LSA_Benefit_Utilization.pdf");
    };
    
    createLogo = () => ({
        image: base64.logo,
        width: 230,
    });

    createTitle = () => ({
        text: 'LSA Benefit Utilization Report',
        fontSize: 16,
        bold: true,
        alignment: 'center',
        margin: [10, 40, 0, 10]
    });
    
    createDateRange = (dateRange) => ({
        text: `Date Range: ${dateRange}`,
        fontSize: 12,
        bold: true,
        alignment: 'right',
        margin: [0, 0, 0, 8]
    });
    
    formatRow = (rowData, style) => {
        return rowData.map(cell => {
            const isNumeric = !isNaN(parseFloat(cell)) && isFinite(cell);
            const isPercentage = typeof cell === 'string' && cell.includes('%');
    
            return {
                text: String(cell || ''),
                style,
                alignment: (isNumeric || isPercentage) ? 'right' : 'left'
            };
        });
    };
    
    formatTableBody = (rows) => rows.map(row => this.formatRow(row, 'tableBody'));
    
    createTable = (header, body) => ({
        style: 'table',
        table: {
            widths: Array(header.length).fill('auto'),
            body: [header, ...body]
        }
    });
    
    getPdfStyles = () => ({
        table: {
            margin: [0, 5, 0, 15]
        },
        tableHeader: {
            bold: true,
            fontSize: 12,
            color: 'black'
        },
        tableBody: {
            fontSize: 10,
            color: 'black',
            padding: [2, 2, 2, 2]
        },
        tableFooter: {
            bold: true,
            fontSize: 10,
            color: 'black'
        }
    });
    
    getTableLayout = () => ({
        hLineWidth: (i, node) => 0.5,
        vLineWidth: (i, node) => 0.5,
        paddingLeft: (i, node) => 4,
        paddingRight: (i, node) => 4
    });
           
    render() {
        const { 
            data,
            selectedOption,
            currentPage,
            itemsPerPage,
            totalLimit,
            totalBalance,
            totalCarriedIn,
            totalApprovalTotal,
            totalPaidTotal,
            percentageUtilization,
            totalBalanceUsed,
            showInactiveEmployees,
            loading,
            dataToExport,
            totalCategoryLimit,
            isBonusCreditEnabled,
            totalBonusCredit
        } = this.state;

        const {
            benefitYearOptions,
            showCostCentres,
            showDivisions,
            isMigratedCompany
        } = this.props;

        const indexOfLastRecord = currentPage * itemsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
        const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(data.length / itemsPerPage);

        let contents = null;
        if (loading) {
            contents = (<CustomLoading />);
        } else {
            let colSpanConfig = 3;
            let colSpanConfig2 = 5;
            if(showCostCentres) {
                colSpanConfig++;
                colSpanConfig2++;
            }

            if(showDivisions) {
                colSpanConfig++;
                colSpanConfig2++;
            }

            let deactivatedEmployeeStyle = null;
            if(showCostCentres || showDivisions) {
                deactivatedEmployeeStyle = {position:"unset"};
            }

            let exportButtonStyle = null;
            if(showCostCentres || showDivisions) {
                exportButtonStyle = {position:"unset", marginLeft:20};
            }
            
            contents = (
                <div className="LSA-Benefit-Utilization-Table report-table-container">
                    <div className="report-toolbar"
                        style={{display: "flex", alignItems: "center", flexDirection: "row", flexWrap: "wrap"}}
                    >
                        {/* {isMigratedCompany ? 
                            <DateDropdownWithCalendarPicker
                                options={[{id:null, dateRange:"Custom", status: "", startDate: "", endDate: ""}, ...benefitYearOptions]}
                                selectedOption={selectedOption}
                                setSelectedOption={this.setSelectedOption}
                            />: */}
                            <CustomDropdown
                                options={benefitYearOptions.map((item) => {
                                            return item.dateRange;
                                        })}
                                selectedOption={selectedOption.dateRange}  
                                setSelectedOption={this.setSelectedOption}
                            />
                        {/* } */}
                        <div className="benefit-year-status">
                            <span className="benefit-year-status-text">{selectedOption.status}</span>
                        </div>
                        {showCostCentres ?
                        <div className={"divisions-dropdown-container"} style={{marginLeft:20}}>
                            <label className="division-label">Division:</label>
                                <CustomDropdown 
                                    options={this.state.divisions.map(cc => cc.name)} 
                                    selectedOption={this.state.selectedDivision.name} 
                                    setSelectedOption={this.setSelectedDivsion}
                                />
                        </div>:null}
                        {showDivisions ?
                        <div className={"cost-centres-dropdown-container"}>
                            <label className="costcentres-label">Cost Centre:</label>
                            <CustomDropdown 
                                options={this.state.costCentres.map(cc => cc.name)} 
                                selectedOption={this.state.selectedCostCentre.name} 
                                setSelectedOption={this.setSelectedCostCentre}/>
                        </div>:null}
                        <label className="utilization-deactivated-employees" style={deactivatedEmployeeStyle}>
                            <input
                            type="checkbox"
                            checked={showInactiveEmployees}
                            onChange={this.toggleInactiveEmployees}
                            style={{marginRight:"10px"}}
                            />
                            Show deactivated employees
                        </label>
                        {dataToExport.length !== 0?
                        <div className="export-button-container" style={exportButtonStyle}>
                            <Button variant="primary" className="export-to-pdf" onClick={this.exportToPdf}>
                                <span className="pdf-text">Export to PDF</span>
                            </Button>
                            <CSVLink data={dataToExport} filename={"LSA_Benefit_Utilization.csv"} className="export-button">
                                <Button variant="primary" className="csv-button export-to-csv">
                                    <span className="csv-text">Export to CSV</span>
                                </Button>
                            </CSVLink>
                        </div>:null}
                    </div>
                    {data.length === 0 ? <EmptyStateTemplate />:
                    <>
                        <Table hover className="report-table">
                            <thead>
                                <tr>
                                    <th className="table-text-content">Last Name</th>
                                    <th className="table-text-content">First Name</th>
                                    <th>Employee ID</th>
                                    {showCostCentres ? <th>Cost Centre</th> : null}
                                    {showDivisions ? <th>Division</th> : null}
                                    <th>
                                        LSA Total Limit
                                        <CustomToolTip
                                            header="LSA Total Limit"
                                            bodyContent="Employee’s total limit after any proration and/or adjustments, and including Carried In amounts if Credit Carry Forward applies."
                                        />
                                    </th>
                                    <th>
                                        LSA Category Limit
                                        <CustomToolTip
                                            header="LSA Category Limit"
                                            bodyContent="Base limit for the benefit year, according to category assigned or allocation made. If Credit Carry Forward applies, this is almost always equal to the Maximum Limit that can be carried forward."
                                        />
                                    </th>
                                    {isBonusCreditEnabled && (
                                    <th>
                                        LSA Bonus Credit
                                        <CustomToolTip
                                            header="LSA Bonus Credit"
                                            bodyContent="Sum total of any credits applied to the limit midyear. These will carry forward with the Category Limit, only if Credit Carry Forward applies."
                                        />
                                    </th>
                                    )}
                                    <th>
                                        LSA Carried In
                                        <CustomToolTip
                                            header="LSA Carried In"
                                            bodyContent="Amount carried in from the previous benefit year. During runoff, remaining balances from the previous benefit year are subject to change. If applicable, Credit Carry Forward amounts will show here after runoff is closed."
                                        />
                                    </th>
                                    <th>LSA Balance</th>
                                    <th>
                                        LSA Amount Used
                                        <CustomToolTip
                                            header="LSA Amount Used"
                                            bodyContent="Amounts claimed out of the selected benefit year. This total is subject to change until runoff is closed."
                                        />
                                    </th>
                                    <th>LSA Utilization %</th>
                                    {/* {!isMigratedCompany ? 
                                    <th>
                                        LSA Approval Total
                                    <CustomToolTip
                                        header="LSA Approval Total"
                                        bodyContent="Claims that have been approved, but are pending reimbursement."
                                    />
                                    </th> : null}
                                    {!this.props.isMigratedCompany ?
                                    <th>
                                        LSA Paid Total
                                        <CustomToolTip
                                            header="LSA Paid Total"
                                            bodyContent="Claims that have been approved and reimbursed. This total is based on date of payment within the date range selected."
                                        />
                                    </th> : null} */}
                                </tr>
                            </thead>
                            <tbody>
                                {currentRecords.map((item, index) => (
                                    <tr key={index} className="table-body-itme" style={item.isActive ? {backgroundColor:"white"}:{backgroundColor:"pink"}}>
                                        <td className="table-text-content">{item.lastName}</td>
                                        <td className="table-text-content">{item.firstName}</td>
                                        <td>{item.employeeNo}</td>
                                        {showCostCentres ? <td>{item.costCentreName}</td> : null}
                                        {showDivisions ? <td>{item.divisionName}</td> : null}
                                        <td>{item.limit}</td>
                                        <td>{item.categoryLimit}</td>
                                        {isBonusCreditEnabled ? <td>{item.bonusCredit}</td> : null}
                                        <td>{item.carriedIn}</td>
                                        <td>{item.balance}</td>
                                        <td>{item.balanceUsed}</td>
                                        <td>{item.utilizationPercentage}%</td>
                                        {/* {!isMigratedCompany ? <td>{item.approvalTotal}</td> : null}
                                        {!isMigratedCompany ? <td>{item.paidTotal}</td> : null} */}
                                    </tr>
                                    )
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    {this.state.hasSubcat ?
                                    <td colSpan={colSpanConfig} className="table-footer-item total-amount">
                                        Main Benefit Total
                                        <CustomToolTip
                                            header="Main Benefit Total"
                                            bodyContent="Main Benefit Total excludes Enhanced Benefits."
                                        />
                                    </td>: 
                                    <td colSpan={colSpanConfig} className="table-footer-item total-amount">Total</td>
                                    }
                                    <td className="table-footer-item">{totalLimit}</td>
                                    <td className="table-footer-item">{totalCategoryLimit}</td>
                                    {isBonusCreditEnabled ? <td className="table-footer-item">{totalBonusCredit}</td> : null}
                                    <td className="table-footer-item">{totalCarriedIn}</td> 
                                    <td className="table-footer-item">{totalBalance}</td>
                                    <td className="table-footer-item">{totalBalanceUsed}</td>
                                    <td className="table-footer-item">{percentageUtilization}%</td>
                                    {/* {!this.props.isMigratedCompany ? <td className="table-footer-item">{totalApprovalTotal}</td> : null}
                                    {!this.props.isMigratedCompany ? <td className="table-footer-item">{totalPaidTotal}</td> : null} */}
                                </tr>
                                {this.state.hasSubcat && (
                                <>
                                    <tr style={{borderTop:"2px solid currentColor"}}>
                                        <td colSpan={colSpanConfig} className="table-footer-item total-amount">
                                            Enhanced Benefits Total
                                            <CustomToolTip
                                                header="Enhanced Benefits Total"
                                                bodyContent="Enhanced Benefits Total excludes Main Benefits."
                                            />
                                        </td>
                                        <td className="table-footer-item">{this.state.subcatTotalLimit}</td>
                                        <td className="table-footer-item">{this.state.subcatCategoryTotalLimit}</td>
                                        {isBonusCreditEnabled ? <td/> : null}
                                        <td/>
                                        <td className="table-footer-item">{this.state.subcatTotalBalance}</td>
                                        <td className="table-footer-item">{this.state.subcatTotalUsage}</td>
                                        <td className="table-footer-item">{this.state.subcatUtilizationPercentage}%</td>
                                    </tr>
                                    <tr style={{borderTop:"2px solid currentColor"}}>
                                        <td colSpan={colSpanConfig} className="table-footer-item total-amount">
                                            Overall Combined Total
                                            <CustomToolTip
                                                header="Overall Combined Total"
                                                bodyContent="Overall Total combining Enhanced Benefit totals with Main Benefit totals."
                                            />
                                        </td>
                                        <td className="table-footer-item">{this.state.combinedTotalLimit}</td>
                                        <td className="table-footer-item">{this.state.combinedCategoryLimit}</td>
                                        {isBonusCreditEnabled ? <td/> : null}
                                        <td/>
                                        <td className="table-footer-item">{this.state.combinedBalance}</td>
                                        <td className="table-footer-item">{this.state.combinedUsage}</td>
                                        <td className="table-footer-item">{this.state.combinedUtilization}%</td>
                                    </tr>
                                </>
                                )}
                            </tfoot>
                        </Table>
                        <div className="pagination-container">
                            <div className="pagination-info">
                                <span>Showing {indexOfFirstRecord + 1} - {indexOfFirstRecord+currentRecords.length} entries out of {data.length} entries</span>
                            </div>
                            <CustomPagination 
                                nPages={nPages} 
                                currentPage={currentPage} 
                                setCurrentPage={this.setCurrentPage} 
                            />
                        </div>
                    </>}
                </div>
            );
        }
        return (<>{contents}</>);
    }
}