import React, {useEffect, useState} from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const CoreHealthWallet = (props) => {
    const [name, setName] = useState(null);
    const [logo, setLogo] = useState(null);

    useEffect(() => {
        if (props.natPolicy === "NAT1001") {
            setLogo("/chlogo.png");
            setName("CoreHealth+");
        } else if (props.natPolicy === "NAT1002") {
            setLogo("/insurpaklogo.png");
            setName("InsurPak");
        }
    }, []);

    const generateCoreHealthWallet = async () => {
        const getImageBase64 = async (url) => {
            const response = await fetch(url);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        };

        const logoBase64 = await getImageBase64(logo);
        const cutoutImage = await getImageBase64('/cutout.png');

        const docDefinition = {
            pageSize: 'LETTER',
            pageOrientation: 'portrait',
            pageMargins: [60, 40, 60, 60],
            info: {
                title: `${name} Wallet Card - ${props.policyNo} - ${props.employeeName}`
            },
            content: [
                {
                    image: logoBase64,
                    width: 170,
                    margin: [0, 5]
                },
                {
                    table: {
                        widths: ['*', 'auto'],
                        body: [
                            [
                                { text: `Master Policy Number: ${props.natPolicy}`, alignment: 'left' },
                                {
                                    text: `Today's Date: ${new Date().toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit'
                                    })}`,
                                    alignment: 'right'
                                }
                            ]
                        ]
                    },
                    layout: 'noBorders',
                },
                {
                    text: `Policy Number: ${props.policyNo}` +
                        `\nPlan Member: ${props.employeeName}` +
                        `\nPlan Type: ${props.chCoverage}`,
                },
                {
                    text: [
                        { text: `This document is a summary of your ${name} plan. Please review your `},
                        { text: 'Certificate of Insurance ', bold: true},
                        { text: `document for the full details of your insurance coverage. The personalized Certificate document is available for download on the ${name} login portal.`}
                    ],
                    margin: [0, 10, 0, 0]
                },
                {
                    canvas: [
                        {
                            type: 'line',
                            x1: 0,
                            y1: 0,
                            x2: 500,
                            y2: 0,
                            lineWidth: 1,
                            color: '#000000'
                        }
                    ],
                    margin: [0, 5]
                },
                {
                    text: 'Insured coverage for unplanned events: ',
                    bold: true,
                    margin: [0, 5]
                },
                {
                    text: 'Coverage is for persons who are under the age of 65 and begins the first day of the month after becoming eligible.' ,
                    margin: [0, 5]
                },
                {
                    table: {
                        widths: ['*'],
                        body: [
                            [
                                {
                                    text: [
                                        { text: props.chCoverage === 'SINGLE' ?
                                                'Life: Employee $15,000' +
                                                '\nAD&D: Employee $60,000' +
                                                '\nCritical Illness: Employee $10,000' +
                                                '\nTravel Emergency Medical: $2,000,000' +
                                                '\nExcess Medical Costs over $2,500: $250,000 lifetime max'
                                                :
                                                'Life: Employee $15,000, Spouse $10,000, Child $5,000' +
                                                '\nAD&D: Employee $60,000' +
                                                '\nCritical Illness: Employee $10,000' +
                                                '\nTravel Emergency Medical: $2,000,000 / family member' +
                                                '\nExcess Medical Costs over $2,500: $250,000 lifetime max / family member'},
                                    ],
                                    margin: [8, 8, 8, 8],
                                }
                            ]
                        ]
                    },
                },
                {
                    text: 'To initiate a Life, AD&D, Critical Illness or Excess Medical Claim contact:',
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                {
                    text: 'National HealthClaim at 1 (866) 342 5908 or email support@nationalhealthclaim.com. Our support team will provide you with a \'Notice of Claim Form\' and provide further instructions.'
                },
                {
                    text: '\nNote: There is a pre-existing medical condition clause that may affect these insured items. Please review your "Certificate of Insurance" document for the full details of your insurance coverage.',
                    margin: [0, 5, 0, 5]
                },
                {
                    text: 'To initiate a Travel Emergency Medical Claim call:',
                    bold: true,
                    margin: [0, 5, 0, 5]
                },
                {
                    text: '1 (800) 268 7955 toll free in Canada & U.S.A.\n+1 (519) 742 2605 collect in other countries',
                    margin: [0, 0, 0, 5]
                },
                {
                    text: 'You can cut out and fold the following Travel Emergency Medical assistance card to carry in your wallet.',
                    margin: [0, 5, 0, 0]
                },
                {
                    canvas: [
                        {
                            type: 'line',
                            x1: 0,
                            y1: 0,
                            x2: 500,
                            y2: 0,
                            lineWidth: 1,
                            color: '#000000'
                        }
                    ],
                    margin: [0, 5]
                },
                {
                    image: cutoutImage,
                    width: 25,
                    absolutePosition: { x: 538, y: 600 },
                },
                {
                    table: {
                        widths: ['50%', '50%'],
                        body: [
                            [
                                {
                                    stack: [
                                        {
                                            image: logoBase64,
                                            fit: [200, 200],
                                            margin: [20, 20, 10, 0],
                                        },
                                        {
                                            text: 'Wawanesa Life Travel Coverage CanAssistance' +
                                                `\nPlan Member: ${props.employeeName}` +
                                                `\nPolicy Number: ${props.policyNo}` +
                                                `\nMaster Policy Number: ${props.natPolicy}`,
                                            margin: [10, 10, 10, 10]
                                        },
                                        {
                                            text: 'Benefits for persons under the age of 65.',
                                            italics: true,
                                            margin: [10, 10, 10, 10]
                                        },
                                    ],
                                },
                                {
                                    stack: [
                                        {
                                            text: `Emergency Travel Assistance`,
                                            bold: true,
                                            margin: [10, 30, 0, 10],
                                            style: 'header',
                                            alignment: 'center',
                                            decoration: 'underline'
                                        },
                                        {
                                            text: 'For 24-hour emergency medical assistance while travelling, call: ',
                                            margin: [10, 0, 0, 10]
                                        },
                                        {
                                            text: 'North America: call 1-800-268-7955' +
                                                '\nAll other locations: call collect 1-519-742-2605',
                                            margin: [10, 0, 0, 10]
                                        },
                                    ]
                                }
                            ]
                        ]
                    },
                    margin: [0, 10, 0, 0],
                    pageBreak: 'after'
                },
                {
                    image: logoBase64,
                    width: 170,
                    margin: [0, 10]
                },
                {
                    table: {
                        widths: ['*', 'auto'],
                        body: [
                            [
                                { text: `Master Policy Number: ${props.natPolicy}`, alignment: 'left' },
                                {
                                    text: `Today's Date: ${new Date().toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit'
                                    })}`,
                                    alignment: 'right'
                                }
                            ]
                        ]
                    },
                    layout: 'noBorders',
                },
                {
                    text: `Policy Number: ${props.policyNo}` +
                        `\nPlan Member: ${props.employeeName}` +
                        `\nPlan Type: ${props.chCoverage}`,
                },
                {
                    canvas: [
                        {
                            type: 'line',
                            x1: 0,
                            y1: 0,
                            x2: 500,
                            y2: 0,
                            lineWidth: 1,
                            color: '#000000'
                        }
                    ],
                    margin: [0, 5]
                },
                {
                    text: 'Health Spending Account (as of today\'s date):',
                    bold: true,
                    style: 'subheader',
                    margin: [0, 0, 0, 10],
                },
                {
                    text: `For the benefit period ${props.chBenefitPeriod}:`,
                    margin: [0, 0, 0, 5]
                },
                {
                    ul: [
                        `Your Limit: $${props.chLimit} (${props.chCoverage})`,
                        `Claims approved/processed: $${props.chApproved}`,
                        `Claims pending/in process: $${props.chPending}`,
                        `Limit Remaining (approx): $${props.chBalance}`
                    ],
                    margin: [0, 0, 0, 10]
                },
                {
                    text: `The expenses that can be claimed using your Health Spending Account must comply with the CRA guidelines and include vision, dental and most items prescribed by a medical practitioner and dispensed by a pharmacist. Please review the documents on your ${name} login portal for a more complete list of eligible expenses.`,
                    margin: [0, 0, 0, 10]
                },
                {
                    text: 'All Health Spending Account claims can be made on line at: employee.nationalhealthclaim.com.',
                    link: 'https://employee.nationalhealthclaim.com',
                    color: 'blue',
                    margin: [0, 0, 0, 10]
                },
                {
                    text: 'To log into the web site, you must use your User Name (email address) and password. If you forget your password or need help email support@nationalhealthclaim.com',
                    margin: [0, 0, 0, 15]
                },
                {
                    canvas: [
                        {
                            type: 'line',
                            x1: 0,
                            y1: 0,
                            x2: 500,
                            y2: 0,
                            lineWidth: 1,
                            color: '#000000'
                        }
                    ],
                    margin: [0, 5]
                },
                {
                    text: 'Your Expert Medical Opinion Teladoc program:',
                    style: 'subheader',
                    margin: [0, 5, 0, 10]
                },
                {
                    text: [
                        { text: 'InterConsultation: ', bold: true },
                        'Get a review of your medical information including medical history, treating physician\'s summary of your condition, test reports and more with the goal of identifying the right diagnosis and right treatment plan.'
                    ],
                    margin: [0, 0, 0, 5]
                },
                {
                    text: [
                        { text: 'FindBestDoc: ', bold: true },
                        'Find Teladoc Experts who can perform requested medical treatment in your geographical area.'
                    ],
                    margin: [0, 0, 0, 5]
                },
                {
                    text: [
                        { text: 'FindBestCare: ', bold: true },
                        'Find Teladoc who can perform requested medical treatment outside of Canada.'
                    ],
                    margin: [0, 0, 0, 5]
                },
                {
                    text: [
                        { text: 'Best Doctors 360: ', bold: true },
                        'You\'ll get a variety of tools and resources including condition specific website links and articles, physician biographies, and contact information for specialists and facilities.'
                    ],
                    margin: [0, 0, 0, 10]
                },
                {
                    text: 'Please review your "Certificate of Insurance" document for the full details of this Teladoc\'s coverage.',
                    margin: [0, 0, 0, 10]
                },
                {
                    text: 'To initiate a Teladoc claim:',
                    style: 'subheader',
                    margin: [0, 10, 0, 5]
                },
                {
                    ol: [
                        'Go online at TeladocHealth.ca',
                        'Create a Profile if this is your first claim',
                        'Select \'Insurer\' under the \'Provider Type\' and type in \'National HealthClaim\' for the Provider Name',
                        'Fill in your remaining information and follow the provided instructions'
                    ],
                    margin: [0, 0, 0, 10]
                },
                {
                    text: 'If you require help, call Teladoc at 1 (877) 419 2378. When asked for the policy details ensure you give them your Master Policy Number (found at the top of this page) and \'National HealthClaim\' as your Provider Name.',
                    margin: [0, 5, 0, 0]
                }
            ],
            styles: {
                header: {
                    fontSize: 14,
                    bold: true
                },
                subheader: {
                    fontSize: 12,
                    bold: true
                },
            },
            defaultStyle: {
                fontSize: 10,
            }
        };

        pdfMake.createPdf(docDefinition).open();
    };

    return (
        <div className="pd-l-45 mg-b-10">
            <a href="#" onClick={generateCoreHealthWallet} style={{color: "blue"}}>
                <i className="fa fa-file pd-r-15"></i> {name} Wallet Card
            </a>
        </div>
    );
};
