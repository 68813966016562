import React from "react";
import axios from "axios";
import Spinner from "components/Spinner";
import SidebarItem from "./SidebarItem";
import { KTMScreen } from "ktm-ui";
import Main from "./Main";
import "../styles/OpsEmployee.css"

class EmployeeApp extends React.Component {
  state = {
    screen: "",
    modal: null,
    loaded: false,
    employeeId: null,
    hasEdis: false,
    hasCh: false,
    run: true,
    claimId: null,
    firstName: null,
    lastName: null,
    companyName: null,
    email: null
  };

  componentDidMount() {
    if (!this.state.loaded) {
      this.startLoading();
    }
  }

  async startLoading() {
    const params = new URLSearchParams(window.location.search);
    const employeeId = params.get('employeeId');
    const claimId = params.get('claimId');

    if(employeeId != null)
      this.setState({ employeeId });

    if(claimId != null)
      this.setState({ claimId });

    const { data } = await axios.get(`/api/OpsEmployee/getemployeeinfo?employeeId=${employeeId}`);
  
    if (data) {
      this.setState({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        companyName: data.companyName,
        loaded : true
      });
    }
  }

  render() {
    if (!this.state.loaded) {
      return <Spinner />;
    }

    const { employeeId, claimId, firstName, lastName, email, companyName } = this.state;
    const homeClass = window.location.pathname === "/ops/employee/home" ? "active" : "";
    const profileClass = window.location.pathname.match(/^\/ops\/employee\/profile/) ? "active" : "";
    const claimHistoryClass = window.location.pathname.match(/^\/ops\/employee\/claimhistory/) ? "active" : "";

    return (
      <div style={{display:"flex", flexDirection: "row", height: "100%"}}>
        <div style={{
          width: "300px", 
          backgroundColor: "#223e7f", 
          borderRight: "1px solid #dee2e6", 
          overflowY: "auto",
          wordWrap: "break-word",
          overflowWrap: "break-word"
        }}>
          <div style={{marginBottom:10, color: "white", padding: 20, fontSize: 17}}>
            <p>{firstName} {lastName}</p>
            <p>{email}</p>
            <p>{companyName}</p>
          </div>
          <SidebarItem
            label={"Home"}
            activeClass={homeClass}
            linkPath={`home?employeeId=${employeeId}`}
          />
          <SidebarItem
            label={"Claims"}
            activeClass={claimHistoryClass}
            linkPath={`claimhistory?employeeId=${employeeId}`}
          />
          <SidebarItem
            label={"Profile"}
            activeClass={profileClass}
            linkPath={`profile?employeeId=${employeeId}`}
          />
        </div>
        <Main employeeId={employeeId} claimId={claimId} />
      </div>
    );
  }
}

export default KTMScreen({ name: "employee" }, EmployeeApp);
