import { IExpressionToken, KeywordSet, SystemFunctionSet } from "./types";

export class Lexer {
    tokens: IExpressionToken[];
    source: string;
    length: number;
    index: number;
    lastToken: IExpressionToken;
    keywords:KeywordSet;
    systemFunctions:SystemFunctionSet;
    error:string;

    public static getExpression(source: string,keywords:KeywordSet,systemFunctions:SystemFunctionSet) {
        var lexer = new Lexer();
        lexer.keywords = keywords;
        lexer.systemFunctions = systemFunctions;
        var tokens = lexer.lexExpression(source);
        if (lexer.error){
            throw lexer.error;
        }
        return tokens;
    }

    private isKeyword(id): boolean {
        let keyword = this.keywords[id];
        if (keyword) return true;
        return false;
    }

    private isDataType(id):boolean {
        let keyword = this.keywords[id];
        if (keyword && keyword.type == "datatype") return true;
        return false;
    }

    private isSystemFunction(id):boolean {
        let f = this.systemFunctions[id];
        if (f) return true;
        return false;
    }

    private isSystemVariable(id):boolean {
        let f = this.systemFunctions[id];
        if (f && f.asVariable) return true;
        return false;
    }

    lexExpression(expression: string): IExpressionToken[] {
        if (!expression) return null;
        this.tokens = [];
        this.source = expression;
        this.index = 0;
        this.length = expression.length;
        this.scanCode();
        return this.tokens;
    }

    private scanCode() {
        var source = this.source;
        var depth = 0;
        var text = null;
        var format: string;

        while (this.index < this.length) {
            var ch = source.charAt(this.index++);
            if (this.isWhiteSpace(ch)) {
                // ignore
            } else if (ch == "|") {
                let peek = source.charAt(this.index);
                format = this.scanFormat();
            } else if (this.isPunctuation(ch)) {
                if (ch == ".") {
                    let peek = source.charAt(this.index);
                    if (this.isNumber(peek)) {
                        this.scanNumber(ch);
                        continue;
                    }
                }
                this.push({ text: ch, type: "punctuation" });
            } else if (ch == "'" || ch == '"') {
                this.scanLiteral(ch);
            } else if (Lexer.isIdentifierStart(ch)) {
                this.scanIdentifier(ch);
            } else if (this.isNumber(ch)) {
                this.scanNumber(ch);
            } else {
                let op = this.getOperator(ch);
                if (!op) {
                    if (!this.error){
                        this.error = "Unexpected char:" + ch;
                    }
                    this.push({ type: "op", text: ch });
                } else {
                    this.push({ type: "op", text: op });
                }
            }
        }
    }

    private push(token: IExpressionToken) {
        this.tokens.push(token);
        this.lastToken = token;
    }

    private scanFormat(): string {
        var format = "";
        var source = this.source;
        while (this.index < this.length) {
            let ch = source.charAt(this.index);
            if (ch == "}") {
                return format.trim();
            }
            format += ch;
            this.index++;
        }
        this.error =  "Missing closing }";
    }

    private scanLiteral(ch: string) {
        var s = "";
        var match = ch;
        let pendingEscape:boolean;
        var source = this.source;
        while (this.index < this.length) {
            ch = source.charAt(this.index++);
            if (pendingEscape) {
                s += ch;
                pendingEscape = false;
            } else if (ch == "\\") {
                pendingEscape = true;
            } else if (ch == match) {
                this.push({ type: "string", text: s });
                return;
            } else {
                s += ch;
            }
        }
        this.push({type:"text",text:match + s});
        this.error = "Missing closing " + match;
    }

    private scanNumber(ch: string) {
        var s = ch;
        var source = this.source;
        while (this.index < this.length) {
            ch = source.charAt(this.index);
            if ((ch >= "0" && ch <= "9") || ch == ".") {
                s += ch;
                this.index++;
            } else {
                break;
            }
        }
        this.push({ type: "number", text: s });
    }

    private isWhiteSpace(ch: string): boolean {
        return ch == " " || ch == "\t";
    }
    public static isIdentifierStart(ch: string): boolean {
        if (ch >= "A" && ch <= "Z") return true;
        if (ch >= "a" && ch <= "z") return true;
        if (ch == "_" || ch == "$" || ch == "@" || ch == '#') return true;
        return false;
    }
    
    private isNumber(ch: string): boolean {
        return ch >= "0" && ch <= "9";
    }


    public static isIdentifierChar(ch: string): boolean {
        return (
            (ch >= "A" && ch <= "Z") ||
            (ch >= "a" && ch <= "z") ||
            (ch >= "0" && ch <= "9") ||
            ch == "_" || ch == "$" || ch == '#'
        );
    }

    private isPunctuation(ch: string): boolean {
        return (
            ch == "." ||
            ch == "," ||
            ch == "(" ||
            ch == ")" ||
            ch == "[" ||
            ch == "]" ||
            ch == "|"
        );
    }

    private getOperator(ch: string): string {
        if (
            ch == "+" ||
            ch == "-" ||
            ch == "*" ||
            ch == "/" ||
            ch == "\\" ||
            ch == "^" ||
            ch == "=" ||
            ch == "%" ||
            ch == "&"
        )
            return ch;

        if (ch == ">") {
            let peek = this.source.charAt(this.index);
            if (peek == "=") {
                this.index++;
                return ">=";
            }
            return ch;
        }
        if (ch == "<") {
            let peek = this.source.charAt(this.index);
            if (peek == ">" || peek == "=") {
                this.index++;
                return ch + peek;
            }
            return ch;
        }
        return null;
    }

    private getKeywordOperator(keyword: string): string {
        var k = keyword.toUpperCase();
        switch (k) {
            case "EQ":
            case "NE":
            case "LT":
            case "LE":
            case "GT":
            case "GE":
            case "MOD":
            case "NOT":
            case "AND":
            case "OR":
            case "XOR":
            case "BEGINS":
            case "CONTAINS":
            case "ENDS":
            case "BETWEEN":
            case "IS":
                return k;
        }
        return null;
    }

    private scanIdentifier(ch: string) {
        var s = ch;
        var source = this.source;
       
        if (ch == "@" && this.index < this.length){
            let peek = source.charAt(this.index);
            if (peek == "@"){
                s += "@";
                this.index++;
                peek = source.charAt(this.index);
            }
            if (peek == "#"){
                this.index++;
                let isGlobalScript = (s == "@@");
                this.scanScript(isGlobalScript);
                return;
            }
        }
        
        while (this.index < this.length) {
            ch = source.charAt(this.index);
            if (Lexer.isIdentifierChar(ch)) {
                this.index++;
                s += ch;
            } else if (
                ch == "-" &&
                Lexer.isIdentifierChar(source.charAt(this.index + 1))
            ) {
                this.index++;
                s += ch;
            }
            else if (ch == ":"){
                this.index++;
                s += ch;
            }
            else {
                break;
            }
        }
        let u = s.toUpperCase();

        let isFunction = (this.index < this.length && ch == "(");
        if (isFunction && this.isSystemFunction(u)){
            this.push({ type: "sysfunc", text: s });
            return;
        }
        let op = this.getKeywordOperator(s);
        if (op) {
            this.push({ type: "op", text: op });
            return;
        }
       
        if (u == "TRUE" || u == "FALSE") {
            this.push({ type: "boolean", text: u });
        } else if ( u == "NULL"){
            this.push({type:"null",text:u});
        } else if (this.isDataType(u)) {
            this.push({ type: "datatype", text: s });
        } else if (this.isKeyword(u)) {
            this.push({ type: "keyword", text: s });
        } else if (this.isSystemVariable(u)){
            this.push({ type: "sysfunc", text: s });
        }
        else {
            this.push({ type: "identifier", text: s });
        }
    }


    private scanScript(isGlobal:boolean) {
        let s = (isGlobal) ? "@@#" : "@#";
        let source = this.source;
        let ch;

        while (this.index < this.length) {
            ch = source.charAt(this.index);
            if (Lexer.isIdentifierChar(ch) || ch == '.') {
                this.index++;
                s += ch;
            } else if (
                ch == "-" &&
                Lexer.isIdentifierChar(source.charAt(this.index + 1))
            ) {
                this.index++;
                s += ch;
            }
            else {
                break;
            }
        }
        if (isGlobal){
            this.push({type:"global-script",text:s});
        }
        else {
            this.push({ type: "script", text: s });
        }
    }  
}