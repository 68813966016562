import { ActionRef } from "./ActionRef";

export class RecordBrowse {
    label:string;
    rows:any[];
    currentIndex:number;
    onRecordChanged:ActionRef;
    renderRecord:(row:any) => any;
    triggerAction:ActionRef;

    move(step:number){
        let newIndex = this.currentIndex + step;
        if (newIndex < 0){
            newIndex = 0;
        }
        if (newIndex >= this.rows.length){
            newIndex = this.rows.length - 1;
        }
        if (newIndex != this.currentIndex){
            this.currentIndex = newIndex;
        }

    }

    get current():any {
        return this.rows[this.currentIndex];
    }
}