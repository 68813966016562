import { useState, useEffect } from 'react';
import { Modal, Button, Card } from 'react-bootstrap';
import axios from 'axios';
import toastr from 'toastr';
import { CustomDropdown } from 'components/CustomDropdown';
import { Line } from 'ktm-ui';
import DatePicker from 'react-date-picker';

const EditIndividualEmpCampaignModal = ({
  showEditIndividualEmpCampaignModal,
  bencatOptions,
  closeEditIndividualEmpCampaignModal,
  employee,
  policyId,
  campaignSelectionDeadline,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    bencatOptions.find((option) => option.name === employee.name) || bencatOptions[0]
  );
  const [selectionDeadline, setSelectionDeadline] = useState(
    employee.flexSelectionDeadline || campaignSelectionDeadline
  );
  const [newSalaryMoney, setNewSalaryMoney] = useState(employee.salaryMoney || 0);
  const [saveCompleted, setSaveCompleted] = useState(false);
  const [showEmailPrompt, setShowEmailPrompt] = useState(false);

  useEffect(() => {
    setSelectedOption(
      bencatOptions.find((option) => option.name === employee.name) || bencatOptions[0]
    );
    setSelectionDeadline(employee.flexSelectionDeadline);
    setNewSalaryMoney(employee.salaryMoney);
  }, [employee, bencatOptions]);

  const handleSave = () => {
    const selectionDate = new Date(selectionDeadline);

    const body = {
      policyId,
      employeeId: employee.id,
      selectedBenCatId: selectedOption.id,
      newSelectionDeadline: selectionDate.toLocaleDateString(),
      newSalaryMoney,
    };

    axios
      .put('/api/Flex/updateindividualemployeecampaign', body)
      .then((response) => {
        if (response.data.success) {
          toastr.success("Employee's campaign updated successfully", 'Success');
          const today = new Date();
          if (selectedOption.isFlex && employee.bencatId !== selectedOption.id) {
            if (new Date(employee.launchDate) <= today) {
              setShowEmailPrompt(true);
            }
            setSaveCompleted(true);
          } else {
            closeEditIndividualEmpCampaignModal(true);
          }
        } else {
          toastr.error(response.data.message, 'Error');
          closeEditIndividualEmpCampaignModal(false);
        }
      })
      .catch(() => {
        toastr.error("Failed to update employee's campaign", 'Error');
      });
  };

  const handleSendEmail = () => {
    const body = {
      PolicyId: policyId,
      RecipientIds: [employee.id],
    };

    axios
      .put('/api/Flex/sendselectionemails', body)
      .then((response) => {
        if (response.data.success) {
          toastr.success('Email sent successfully', 'Success');
        } else {
          toastr.error(response.data.message, 'Error');
        }
      })
      .catch(() => {
        toastr.error('Failed to send email', 'Error');
      });

    setSaveCompleted(false);
    setShowEmailPrompt(false);
    closeEditIndividualEmpCampaignModal(true);
  };

  const handleCloseEmailModal = () => {
    setSaveCompleted(false);
    setShowEmailPrompt(false);
    closeEditIndividualEmpCampaignModal(true);
  };

  const handleSelect = (option) => {
    setSelectedOption(bencatOptions.find((bencat) => bencat.name === option));
  };

  const handleDateChange = (date) => {
    setSelectionDeadline(date);
  };

  const handleSalaryChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '');
    setNewSalaryMoney(parseFloat(value));
  };

  return (
    <div>
      <Modal
        className="edit-employee-campaign-modal-container"
        show={showEditIndividualEmpCampaignModal && !showEmailPrompt}
      >
        <Modal.Header className="campaign-edit-employee-modal-header">
          <Modal.Title>Edit Employee Campaign</Modal.Title>
        </Modal.Header>
        <Modal.Body className="campaign-edit-employee-modal-body">
          <Card>
            <Line>
              <div className="RT-Static-Field" style={{ marginLeft: 10 }}>
                <label className="RT-Static-Field__label" style={{ color: 'black' }}>
                  Employee Name
                </label>
                <div
                  className="RT-Static-Field__content"
                  style={{ height: '21px', fontWeight: 'bold' }}
                >
                  {employee.lastName}, {employee.firstName}
                </div>
              </div>
            </Line>
            <Line>
              <div className="RT-TextBox">
                <label className="RT-Label">Benefit Category</label>
                <CustomDropdown
                  options={bencatOptions.map((option) => option.name)}
                  selectedOption={selectedOption.name}
                  setSelectedOption={handleSelect}
                />
              </div>
            </Line>
            <Line>
              <div className="date-picker-container">
                <label className="RT-Label">Selection Deadline</label>
                <DatePicker
                  onChange={handleDateChange}
                  value={new Date(selectionDeadline)}
                  format={"y-MM-dd"}
                  minDate={new Date()}
                  clearIcon={null}
                />
              </div>
            </Line>
            {(employee.creditCalcMethod === 'SALARY' ||
              selectedOption.creditCalcMethod === 'SALARY') && (
              <Line>
                <div className="form-group" style={{ marginLeft: 10, width: '50%' }}>
                  <label htmlFor="limit">Salary</label>
                  <div className="input-group" style={{ width: 250, height: 35 }}>
                    <span className="input-group-text">$</span>
                    <input
                      type="number"
                      className="form-control"
                      id="limit"
                      value={newSalaryMoney}
                      onChange={handleSalaryChange}
                    />
                  </div>
                </div>
              </Line>
            )}
          </Card>
        </Modal.Body>
        <Modal.Footer className="campaign-edit-employee-modal-footer">
          <Button variant="secondary" onClick={() => closeEditIndividualEmpCampaignModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal className="edit-employee-campaign-modal-container" show={showEmailPrompt}>
        <Modal.Header className="campaign-edit-employee-modal-header">
          <Modal.Title>Send Flex Email</Modal.Title>
        </Modal.Header>
        <Modal.Body className="campaign-edit-employee-modal-body">
          <Card>
            <p style={{ fontWeight: 'bold', color: 'red' }}>
              Do you want to send a flex email to the employees?
            </p>
          </Card>
        </Modal.Body>
        <Modal.Footer className="campaign-edit-employee-modal-footer">
          <Button variant="secondary" onClick={handleCloseEmailModal}>
            No
          </Button>
          <Button variant="primary" onClick={handleSendEmail}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditIndividualEmpCampaignModal;
