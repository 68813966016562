import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import axios from "axios";
import toastr from "toastr";
import { CustomLoading } from 'components/CustomLoading';

const ViewPolicy = ({ data }) => {
    const [hasHsa, setHasHsa] = useState(false);
    const [hasLsa, setHasLsa] = useState(false);
    const [hasFlex, setHasFlex] = useState(false);
    const [hasAdditionalAccounts, setHasAdditionalAccounts] = useState(false);
    const [policyNotes, setPolicyNotes] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let localHasHsa = false;
        let localHasLsa = false;
        let localHasFlex = false;
        
        data.categories.forEach(item => {
            if (item.hasHsa) {
                localHasHsa = true;
            }
            if (item.hasLsa) {
                localHasLsa = true;
            }
            if (item.hasFlex) {
                localHasFlex = true;
            }
        });

        setHasHsa(localHasHsa);
        setHasLsa(localHasLsa);
        setHasFlex(localHasFlex);
        setHasAdditionalAccounts(data.hasAdditionalAccounts);
        
        fetchPolicyNotes();
    }, [data]);

    const handleNotesChange = (e) => {
        setPolicyNotes(e.target.value);
    };

    const fetchPolicyNotes = async () => {
        try {
          const response = await axios.get(`/api/support/policynotes?policyHolderId=${data.policyHolderId}`);
          if (response.data && response.data.noteText != null) {
            setPolicyNotes(response.data.noteText);
          } else {
            setPolicyNotes("");
          }
          setLoading(false);
        } catch (error) {
          toastr.error("Failed to fetch company notes", "Error");
        }
      };

    const savePolicyNotes = () => {
        var noteId = null;
        if (data.notes != null) {
            noteId = data.notes.id;
        }

        var body = {
            Id: noteId,
            PolicyHolderId: data.policyHolderId,
            Notes: policyNotes,
        };
        var self = this;
        axios({
            method: "post",
            url: "/api/support/savepolicynotes",
            data: body,
        })
        .then(function (response) {
            if (!response.data.success) {
                toastr.success("Notes updated", "Success");
            }
        })
        .catch(function (error) {
            toastr.error("Failed save notes", "Error");
        });
    };

    const planAdmins = data.planAdmins && data.planAdmins.map((item, i) => (
        <div key={i}>
            {item.lastName} {item.firstName}{" "}
            <a
                href={`mailto:${item.email}`}
                target="_blank"
                style={{color: "blue"}}
            >
                {item.email}
            </a>
        </div>
    ));

    const notes = data.notes ? (
        <div>
            <div>
                <span>
                    <Moment local format="YYYY/MM/DD hh:mm A">
                        {data.notes.updatedAt}
                    </Moment>
                </span>
            </div>
            <div className="mt-2">
                <textarea
                    className="form-control"
                    value={policyNotes}
                    onChange={handleNotesChange}
                ></textarea>
                <button
                    type="button"
                    className="btn btn-outline-primary btn-sm mt-1"
                    onClick={savePolicyNotes}
                >
                    Save
                </button>
            </div>
        </div>
    ) : (
        <div className="mt-2">
            <textarea
                className="form-control"
                value={policyNotes}
                onChange={handleNotesChange}
            ></textarea>
            <button
                type="button"
                className="btn btn-outline-primary btn-sm mt-1"
                onClick={savePolicyNotes}
            >
                Save
            </button>
        </div>
    );

    if (loading) {
        return <CustomLoading />;
    }

    return(
        <div className="mt-3 card shadow-sm p-3 mb-5 bg-body rounded">
            <div>
                <div className="row">
                    <div className="col-3">Policy Account</div>
                    <div className="col-9">
                        <b>{data.name}</b>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">DBA</div>
                    <div className="col-9">
                        <b>{data.dba}</b>
                    </div>
                </div>
                <div className="row pt-1">
                    <div className="col-3">Plan Admin</div>
                    <div className="col-9">{planAdmins}</div>
                </div>
                <div className="row pt-1">
                    <div className="col-3">Notes</div>
                    <div className="col-9">{notes}</div>
                </div>
                <div className="row pt-1">
                    <div className="col-3">Email History</div>
                    <div className="col-9">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Email Type</th>
                                <th>Date Sent</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row pt-1">
                    <div className="col-3">2 Factor Authentication</div>
                    <div className="col-9">
                        {data.twoFactorEnabled ? "Yes" : "No"}
                    </div>
                </div>
                <div className="row pt-1">
                    <div className="col-3">Allow Dependent Edits by EEs</div>
                    <div className="col-9">
                        {data.allowDependentEdit ? "Yes" : "No"}
                    </div>
                </div>
                <div className="row pt-1">
                    <div className="col-3">Wellness Instead of Lifestyle</div>
                    <div className="col-9">{data.enableWellness ? "Yes" : "No"}</div>
                </div>
                <div className="row pt-1">
                    <div className="col-3">Has HSA</div>
                    <div className="col-9">{hasHsa ? "Yes" : "No"}</div>
                </div>
                <div className="row pt-1">
                    <div className="col-3">Has LSA</div>
                    <div className="col-9">{hasLsa ? "Yes" : "No"}</div>
                </div>
                <div className="row pt-1">
                    <div className="col-3">Has Flex</div>
                    <div className="col-9">{hasFlex ? "Yes" : "No"}</div>
                </div>
                <div className="row pt-1">
                    <div className="col-3">Has Enhanced Benefits</div>
                    <div className="col-9">{hasAdditionalAccounts ? "Yes" : "No"}</div>
                </div>
                <div className="row pt-1">
                    <div className="col-3">PAD Enabled</div>
                    <div className="col-9">{data.enablePad ? "Yes" : "No"}</div>
                </div>
                <div className="row pt-1">
                    <div className="col-3">PAD Completed</div>
                    <div className="col-9">{data.isPadComplete ? "Yes" : "No"}</div>
                </div>
                {data.hasCoveragePay ? 
                (<div className="row pt-1">
                    <div className="col-3">Has Coverage %</div>
                    <div className="col-9">Yes</div>
                </div>) 
                : <></>}
                <div className="row pt-1">
                    <div className="col-3">Enrollment Notes</div>
                    <div className="col-9">{data.enrollmentNotes}</div>
                </div>
            </div>
        </div>
    )
};

export default ViewPolicy;
