import React from 'react';
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from 'ktm-ui';
import { KTMApi } from 'api/KTMApi';
import { Layout, Dialog, Button, Display, Line, Column, Panel, Card } from 'ktm-ui';

interface IdsBenCat {
    Id: string;
    LSASingleLimit: number;
    LSACoupleLimit: number;
    LSAFamilyLimit: number;
    LSASinglePercent: number;
    LSAcouplePercent: number;
    LSAfamilyPercent: number;
    CreditCalcMethod: string;
    Policy_Id: string;
};

export default KTMScreen({ name: "enroll.ben-limit.edit-lsa-limits" },
    class extends React.Component<{ limit_id: string, employeesCount: number, employeeHasClaims: boolean, benefitStartDate: string }> {

        data: {
            dsBenCat: IdsBenCat;
        }

        state = {
            originalLimits: {
                LSASingleLimit: 0,
                LSACoupleLimit: 0,
                LSAFamilyLimit: 0,
            },
            disableButton: false,
            showCampaignWarning: false,
        };

        render() {
            return renderScreen(this);
        }

        async start({ screen }: ActionEvent) {
            screen.defaultRecord = "@dsBenCat";
            await KTMApi.start(screen, {
                "@limit_id": this.props.limit_id,
            });
            screen.title = "Edit LSA limits";

            this.setState({
                originalLimits: {
                    LSASingleLimit: this.data.dsBenCat.LSASingleLimit,
                    LSACoupleLimit: this.data.dsBenCat.LSACoupleLimit,
                    LSAFamilyLimit: this.data.dsBenCat.LSAFamilyLimit,
                }
            });
            await this.checkCampaignStatus();
            await screen.triggerAction(this.set_limit_visibility);
        }

        main({ field, draw, action }: RenderEvent) {
            return (
                <Layout>
                    {draw(this.header)}
                    {(this.state.showCampaignWarning && this.props.employeesCount > 0) && (
                        <Card>
                            <Line>
                                <span style={{ color: "red", fontSize: 14, fontWeight: 'bold' }}>
                                    This benefit category has been assigned to employees as part of their year-end Flex Campaign. Any changes saved here will apply to the next benefit year as well as to the current one, and the assigned employees may be required to re-select.
                                    <br />
                                    If assistance is required, please click ‘Cancel’ and contact enrollments@nationalhealthclaim.com.
                                </span>
                            </Line>
                        </Card>
                    )}
                    {draw(this.main_tab)}
                    {this.isButtonDisabled() ? <p style={{ color: "red" }}>Benefit category cannot be decreased after assigned employees have claimed amounts.</p> : ""}
                    {draw(this.footer)}
                </Layout>
            );
        }

        header({ field, draw, action }: RenderEvent) {
            return (
                <Dialog.Header label="Edit LSA Limits" />
            );
        }

        isButtonDisabled() {
            const { LSASingleLimit, LSACoupleLimit, LSAFamilyLimit } = this.data.dsBenCat;
            const { originalLimits } = this.state;

            const benefitStartDate = new Date(this.props.benefitStartDate);
            const currentDate = new Date();
            const oneMonthAfterStartDate = new Date(benefitStartDate);
            oneMonthAfterStartDate.setMonth(oneMonthAfterStartDate.getMonth() + 1);

            if (currentDate <= oneMonthAfterStartDate) {
                return false;
            }

            const disableButton = this.props.employeesCount > 0 &&
                (LSASingleLimit < originalLimits.LSASingleLimit ||
                    LSACoupleLimit < originalLimits.LSACoupleLimit ||
                    LSAFamilyLimit < originalLimits.LSAFamilyLimit)
                && this.props.employeeHasClaims;
            return disableButton;
        }

        footer({ field, draw, action }: RenderEvent) {
            return (
                <Dialog.Footer>
                    <Button
                        action={action(this.submit)}
                        label="Save"
                        size="large"
                        style="primary"
                        disable={this.isButtonDisabled()}
                    />
                </Dialog.Footer>
            );
        }

        limits({ field, draw, action }: RenderEvent) {
            return (
                <Line>
                    <Display field={field("LSASingleLimit")} />
                    <Display field={field("LSACoupleLimit")} />
                    <Display field={field("LSAFamilyLimit")} />
                </Line>
            );
        }

        percentages({ field, draw, action }: RenderEvent) {
            return (
                <Line>
                    <Display field={field("LSASinglePercent")} />
                    <Display field={field("LSAcouplePercent")} />
                    <Display field={field("LSAfamilyPercent")} />
                </Line>
            );
        }

        main_tab({ field, draw, action }: RenderEvent) {
            return (
                <Panel>
                    <Column>
                        {draw(this.details)}
                    </Column>
                </Panel>
            );
        }

        details({ field, draw, action }: RenderEvent) {
            return (
                <Card>
                    {draw(this.limits)}
                    {draw(this.percentages)}
                </Card>
            );
        }

        async submit({ screen }: ActionEvent) {
            if (this.data.dsBenCat.LSASingleLimit == null)
                this.data.dsBenCat.LSASingleLimit = 0;
            if (this.data.dsBenCat.LSACoupleLimit == null)
                this.data.dsBenCat.LSACoupleLimit = 0;
            if (this.data.dsBenCat.LSAFamilyLimit == null)
                this.data.dsBenCat.LSAFamilyLimit = 0;
            if (this.data.dsBenCat.LSASinglePercent == null)
                this.data.dsBenCat.LSASinglePercent = 0;
            if (this.data.dsBenCat.LSAcouplePercent == null)
                this.data.dsBenCat.LSAcouplePercent = 0;
            if (this.data.dsBenCat.LSAfamilyPercent == null)
                this.data.dsBenCat.LSAfamilyPercent = 0;

            await KTMApi.action(screen, "submit", {
                "@dsBenCat.LSASingleLimit": this.data.dsBenCat.LSASingleLimit,
                "@dsBenCat.LSACoupleLimit": this.data.dsBenCat.LSACoupleLimit,
                "@dsBenCat.LSAFamilyLimit": this.data.dsBenCat.LSAFamilyLimit,
                "@dsBenCat.LSASinglePercent": this.data.dsBenCat.LSASinglePercent,
                "@dsBenCat.LSAcouplePercent": this.data.dsBenCat.LSAcouplePercent,
                "@dsBenCat.LSAfamilyPercent": this.data.dsBenCat.LSAfamilyPercent,
                "@limit_id": this.props.limit_id,
            });
            screen.close({ "continue": true });
        }

        async set_limit_visibility({ screen }: ActionEvent) {
            screen.hide(this.limits);
            screen.hide(this.percentages);
            if (screen.getValue("@dsBenCat.CreditCalcMethod") === "SALARY") {
                screen.unhide(this.percentages);
            } else {
                screen.unhide(this.limits);
            }
        }

        async checkCampaignStatus() {
            let response = await fetch("/api/Utility/checkcampaignstatus?policyId=" + this.data.dsBenCat.Policy_Id);
            let res = await response.json();
            if (res.isCampaignOpen) {
                this.setState({ showCampaignWarning: true });
            }
        }
    });
