import axios from "axios";
import { CustomLoading } from "components/CustomLoading";
import { Card } from "ktm-ui";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import toastr from 'toastr';
class CustomCategoriesScreen extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            showAddHSABtn: false,
            showAddLSABtn: false,
            showAddCustomCategoryModal: false,
            selectedAccountType: "HSA",
            showEditCustomCategoryModal: false,
            selectedCustomCategory: {},
            showRemoveCustomCategoryModal: false,
            showRemoveBtn: false
        }
    }

    componentDidMount() {
        this.getNumberofEmployees();
        this.getCustomCategories();
    }

    getNumberofEmployees = () => {
        try {
            const { category_id } = this.props;
            axios.get("/api/BenefitCategories/getvalicdationofremovingcustomcat", {
                params: { 
                    benefitCategoryId: category_id 
                }
            }).then((response) => {
                if (response.data.success) {
                    this.setState({
                        showRemoveBtn: response.data.showRemoveBtn
                    });
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    getCustomCategories = () => {
        try {
            const { policy_id, limit_id, category_id } = this.props;
            axios.get("/api/BenefitCategories/getcustomcategories", {
                params: { 
                    policyId: policy_id, 
                    bencatLimitId: limit_id, 
                    benefitCategoryId: category_id 
                }
            }).then((response) => {
                if (response.data.success) {
                    this.setState({
                        data: response.data.customCategories,
                        showAddHSABtn: response.data.showAddHSACustomCategory,
                        showAddLSABtn: response.data.showAddLSACustomCategory,
                        loading: false
                    });
                } else {
                    this.setState({
                        data: [],
                        loading: false,
                        showAddHSABtn: true,
                        showAddLSABtn: true,
                    });
                }
            });
        } catch (error) {
            this.setState({loading: false });
        }
    }

    addCustomCategory = (accountType: string, name: string, description: string, limit: number, allowDependentClaims: boolean) => {
        try {
            const { policy_id, limit_id, category_id } = this.props;

            axios.post("/api/BenefitCategories/addcustomcategory", {
                policyId: policy_id,
                bencatLimitId: limit_id,
                benefitCategoryId: category_id,
                accountType: accountType,
                name: name,
                description: description,
                limit: limit,
                allowDependentClaims: allowDependentClaims
            }).then((response) => {
                if (response.data.success) {
                    this.setState({
                        showAddCustomCategoryModal: false,
                        loading: true
                    });
                    
                    this.getCustomCategories();
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    updateCustomCategory = (selectedCustomCategory: any, newName: string, newDescription: string, newLimit: number, allowDependentClaims: boolean) => {
        try {
            const { policy_id, limit_id, category_id } = this.props;
            
            const { 
                customCategory_Id,
                customCategoryLimit_Id
            } = selectedCustomCategory;

            axios.post("/api/BenefitCategories/updatecustomcategory", {
                policyId: policy_id,
                bencatLimitId: limit_id,
                benefitCategoryId: category_id,
                customCategoryId: customCategory_Id,
                customCategoryLimitId : customCategoryLimit_Id,
                name: newName,
                description: newDescription,
                limit: newLimit,
                allowDependentClaims: allowDependentClaims
            }).then((response) => {
                if (response.data.success) {
                    this.setState({
                        loading: true,
                        showEditCustomCategoryModal: false
                    });

                    this.getCustomCategories();
                } else {
                    toastr.error(response.data.message, "Error");
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    removeCustomCategory = (selectedCustomCategory: any) => {
        try {
            const { 
                customCategory_Id,
                customCategoryLimit_Id
            } = selectedCustomCategory;

            axios.delete("/api/BenefitCategories/removecustomcategory", {
                params: {
                    customCategoryId: customCategory_Id,
                    customCategoryLimitId : customCategoryLimit_Id,
                }
            }).then((response) => {
                if (response.data.success) {
                    this.setState({
                        loading: true,
                    });

                    this.getCustomCategories();
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    openAddCustomCategoryModal = (accountType:string) => {
        this.setState({
            showAddCustomCategoryModal: true,
            selectedAccountType: accountType
        });
    }

    handleRemoveClick = (customCategory) => {
        this.setState({
            showRemoveCustomCategoryModal: true,
            selectedCustomCategory: customCategory
        });
    }

    handleEditClick = (customCategory) => {
        this.setState({
            showEditCustomCategoryModal: true,
            selectedCustomCategory: customCategory
        });
    }

    renderCustomCategories = () => {
        const { data } = this.state;

        if(data.length === 0) {
            return (<></>);
        }

        const categories = [];
        for (let i = 0; i < data.length; i++) {
            if (data[i].accountType === this.props.accountType) {
                categories.push(
                    <div key={i} className="RT-Section">
                        <div className="RT-Section__header">
                            <div className="RT-Section__title">{data[i].accountType} Enhanced Benefit</div>
                            <div className="RT-Section__actions">
                                <a href="#" className="RT-Link" onClick={() => this.handleEditClick(data[i])}>
                                    Edit
                                </a>
                                {this.state.showRemoveBtn && (
                                    <a href="#" className="RT-Link" onClick={() => this.handleRemoveClick(data[i])}>
                                        Remove
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className="RT-Line">
                            <div className="RT-Line__content RT-Line__content--space-evenly">
                                <div className="RT-Line__item">
                                    <div className="RT-Static-Field">
                                        <label className="RT-Static-Field__label">Name</label>
                                        <div className="RT-Static-Field__content">{data[i].name}</div>
                                    </div>
                                </div>
                                <div className="RT-Line__item">
                                    <div className="RT-Static-Field">
                                        <label className="RT-Static-Field__label">Description</label>
                                        <div className="RT-Static-Field__content">{data[i].description}</div>
                                    </div>
                                </div>
                                <div className="RT-Line__item">
                                    <div className="RT-Static-Field">
                                        <label className="RT-Static-Field__label">Limit</label>
                                        <div className="RT-Static-Field__content"
                                             style={{fontWeight: 500}}>${data[i].limit}</div>
                                    </div>
                                </div>
                                <div className="RT-Line__item">
                                    <div className="RT-Static-Field">
                                        <label className="RT-Static-Field__label">Allow Dependent Claims</label>
                                        <div
                                            className="RT-Static-Field__content">{data[i].allowDependentClaims ? "Yes" : "No"}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    
        return categories;
    }

    render() {
        const { 
            loading,
            showAddCustomCategoryModal, 
            selectedAccountType,
            showEditCustomCategoryModal,
            selectedCustomCategory,
            showRemoveCustomCategoryModal,
            showAddHSABtn,
            showAddLSABtn
        } = this.state;

        const addCustomCategoryModal = (
            <AddCustomCategoryModal
                show={showAddCustomCategoryModal}
                onHide={() => this.setState({ showAddCustomCategoryModal: false })}
                addCustomCategory={this.addCustomCategory}
                accountType={selectedAccountType}
            />
        );

        const editCustomCategoryModal = (
            <EditCustomCategoryModal
                show={showEditCustomCategoryModal}
                onHide={() => this.setState({ showEditCustomCategoryModal: false })}
                updateCustomCategory={this.updateCustomCategory}
                selectedCustomCategory={selectedCustomCategory}
            />
        );

        const removeCustomCategoryModal = (
            <RemoveCustomCategoryModal
                show={showRemoveCustomCategoryModal}
                onHide={() => this.setState({ showRemoveCustomCategoryModal: false })}
                removeCustomCategory={this.removeCustomCategory}
                selectedCustomCategory={selectedCustomCategory}
            />
        );
        
        const addCustomCategoriesButtons = ( showAddHSABtn || showAddLSABtn) && (
            <div className="custom-categories-add-buttons" style={{ display: "flex", marginBottom:20, marginTop:10 }}>
                {this.props.accountType === "HSA" && showAddHSABtn && (
                    <Button 
                        variant="primary" 
                        onClick={() => this.openAddCustomCategoryModal("HSA")}
                        style={{ marginRight: "20px", borderRadius: "90px", backgroundColor: "#4987ef" }}
                    >
                        Add HSA Enhanced Benefit
                    </Button>
                )}
                {this.props.accountType === "LSA" && showAddLSABtn && (
                    <Button 
                        variant="primary" 
                        onClick={() => this.openAddCustomCategoryModal("LSA")}
                        style={{ borderRadius: "90px", backgroundColor: "#4987ef" }}
                    >
                        Add LSA Enhanced Benefit
                    </Button>
                )}
            </div>
        );

        let contents;
        if (loading) {
            contents = <CustomLoading />;
        } else {
            contents = this.renderCustomCategories();
        }

        let textHeader;
        let textStyle = {
            fontSize: 13,
            fontWeight: 500,
            paddingTop: 10,
            paddingBottom: 10,
            display: "block"
        };

        if(this.props.is_flex)
        {
            textHeader = (
                <span style={textStyle}>
                    Additional benefits that are dedicated to a specific expense, separate from the main category total, and excluded from regular Flex allocations. Renewed annually with no carryover.
                    <br/>
                    After reaching the maximum, any remaining claim amounts submitted are reimbursed out of regular HSA or LSA benefits if eligible.
                    <br/>
                    These totals are also subject to proration if it applies at the employee level. Up to 6 Enhanced Benefits allowed per HSA and LSA.
                </span>
            )
        } else {
            textHeader = (
                <span style={textStyle}>
                    Additional benefits that are dedicated to a specific expense, and separate from the main category total. Renewed annually with no carryover. 
                    <br/>
                    After reaching the maximum, any remaining claim amounts submitted are reimbursed out of regular HSA or LSA benefits if eligible. 
                    <br/>
                    These totals are also subject to proration if it applies at the employee level. Up to 6 Enhanced Benefits allowed per HSA and LSA.
                </span>
            )
        }

        return(
            <div>
                {textHeader}
                {addCustomCategoriesButtons}
                {contents}
                {addCustomCategoryModal}
                {editCustomCategoryModal}
                {removeCustomCategoryModal}
            </div>
        );
    }

}

export default CustomCategoriesScreen;

const AddCustomCategoryModal = ({addCustomCategory, ...props}) => {
    const [name, setName] = React.useState("");
    const [limit, setLimit] = React.useState(0);
    const [accountType, setAccountType] = React.useState(props.accountType || "");
    const [description, setDescription] = React.useState("");
    const [allowDependentClaims, setAllowDependentClaims] = React.useState(true)

    React.useEffect(() => {
        setAccountType(props.accountType || "");
    }, [props.accountType]);

    const handleNameChange = (event) => {
        setName(event.target.value);
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    }

    const handlelimitChange = (event) => {
        const newValue = event.target.value;
        if (newValue === '' || /^[0-9]*$/.test(newValue)) {
            setLimit(newValue);
        }
    }

    const handleAllowDependentClaims = (event) => {
        setAllowDependentClaims(!allowDependentClaims)
    }

    const handleSubmit = () => {
        props.onHide();
        addCustomCategory(accountType, name, description, limit, allowDependentClaims);
        setName("");
        setDescription("");
        setLimit(0);
        setAllowDependentClaims(true);
    }

    return(
        <Modal
            {...props}
            className="react-modal"
            keyboard={false}
            centered
            style={{ maxWidth: "500px", left: "40%" }}
        >
        <Modal.Header className="react-modal-title">
            <Modal.Title>
                Add {props.accountType} Enhanced Benefit
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Card>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="name" 
                        value={name}
                        onChange={handleNameChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="name">Description</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="description"
                        value={description}
                        onChange={handleDescriptionChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="limit">Limit</label>
                    <div className="input-group">
                        <span className="input-group-text">$</span>
                        <input 
                            type="number" 
                            className="form-control" 
                            id="limit" 
                            value={limit}
                            onChange={handlelimitChange}
                        />
                    </div>
                </div>
                <div className="form-check" 
                    style={{ 
                        paddingLeft: 0,
                        display: "flex",
                        alignItems: "center"
                    }}>
                    <input
                        type="checkbox"
                        checked={allowDependentClaims}
                        onChange={handleAllowDependentClaims}
                        style={{ paddingLeft: 0, marginRight: 10 }}
                    />
                    <label style={{marginBottom: 0}}>
                        Allow Dependent Claim
                    </label>
                </div>
            </Card>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide} >Cancel</Button>
            <Button variant="primary" onClick={handleSubmit}>Save</Button>
        </Modal.Footer>
        </Modal>
    );
}

const EditCustomCategoryModal = ({updateCustomCategory, ...props}) => {
    const [accountType, setAccountType] = React.useState(props.selectedCustomCategory.accountType || "HSA" );
    const [name, setName] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [limit, setLimit] = React.useState(0);
    const [allowDependentClaims, setAllowDependentClaims] = React.useState(props.selectedCustomCategory.allowDependentClaims || false);

    React.useEffect(() => {
        if (props.selectedCustomCategory) {
            setName(props.selectedCustomCategory.name || "");
            setDescription(props.selectedCustomCategory.description || "");
            setLimit(props.selectedCustomCategory.limit || 0);
            setAllowDependentClaims(props.selectedCustomCategory.allowDependentClaims || false);
        }
    }, [props.selectedCustomCategory]);

    const handleNameChange = (event) => {
        setName(event.target.value);
    }

    const handlelimitChange = (event) => {
        const newValue = event.target.value;
        if (newValue === '' || /^[0-9]*$/.test(newValue)) {
            setLimit(newValue);
        }
    }

    const handleAllowDependentClaims = (event) => {
        setAllowDependentClaims(!allowDependentClaims)
    }

    const handleSubmit = () => {
        updateCustomCategory(props.selectedCustomCategory, name, description, limit, allowDependentClaims);
    }

    return(
        <Modal
            {...props}
            className="react-modal"
            keyboard={false}
            centered
            style={{ maxWidth: "500px", left: "40%" }}
        >
        <Modal.Header className="react-modal-title">
            <Modal.Title>
                Edit  {accountType} Enhanced Benefit
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Card>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="name" 
                        value={name}
                        onChange={handleNameChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="name">Description</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="description"
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="limit">Limit</label>
                    <div className="input-group">
                        <span className="input-group-text">$</span>
                        <input 
                            type="number" 
                            className="form-control" 
                            id="limit" 
                            value={limit}
                            onChange={handlelimitChange}
                        />
                    </div>
                </div>
                <div className="form-check" 
                    style={{ 
                        paddingLeft: 0,
                        marginTop: 20
                    }}>
                    <label style={{ 
                        paddingLeft: 0,
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <input
                            type="checkbox"
                            checked={allowDependentClaims}
                            onChange={handleAllowDependentClaims}
                            style={{ paddingLeft: 0, marginRight: 10 }}
                        />
                        Allow Dependent Claim
                    </label>
                </div>
            </Card>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide} >Cancel</Button>
            <Button variant="primary" onClick={handleSubmit}>Save</Button>
        </Modal.Footer>
        </Modal>
    );
}

const RemoveCustomCategoryModal = ({removeCustomCategory, ...props}) => {
    const [accountType, setAccountType] = React.useState(props.selectedCustomCategory.accountType || "HSA" );
    const [name, setName] = React.useState(props.selectedCustomCategory.name || "");

    React.useEffect(() => {
        if (props.selectedCustomCategory) {
            setName(props.selectedCustomCategory.name || "");
        }
    }, [props.selectedCustomCategory]);

    const handleSubmit = () => {
        props.onHide();
        removeCustomCategory(props.selectedCustomCategory);
    }

    return(
        <Modal
            {...props}
            className="react-modal"
            keyboard={false}
            centered
            style={{ maxWidth: "500px", left: "40%" }}
        >
        <Modal.Header className="react-modal-title">
            <Modal.Title>
                Remove {accountType} Enhanced Benefit
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                Are you sure you want to remove {name}?
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide} >Cancel</Button>
            <Button variant="danger" onClick={handleSubmit} >Remove</Button>
        </Modal.Footer>
        </Modal>
    );
}
    