import { Application } from "./Application";


export type IUpdateSpinnerState = (visible:boolean) => void;

export class SpinnerManager {
    private spinnerState:string[] = [];
    private spinnerTimer:any;
    private pendingAction:'show' | 'hide';
    private app:Application;
    private lastAction:'show' | 'hide';

    constructor(app:Application){
        this.app = app;
    }

    public show(){
        if (this.lastAction){
            this.spinnerState.push(this.lastAction);
        }
        this.lastAction = 'show';
        this.showSpinnerElem();
    }

    public pause(){
        this.spinnerState.push("hide");
        this.lastAction = 'hide';
        this.hideSpinnerElem();
    }

    public resume(){
        
        if (this.spinnerState.length){
            this.lastAction = this.spinnerState.pop() as any;
            if (this.lastAction == "show"){
                this.showSpinnerElem();
            }
            else {
                this.hideSpinnerElem();
            }
        }
        else {
            this.hideSpinnerElem();
        }
    }

    public hide(){
        if (this.spinnerState.length){
            this.lastAction = this.spinnerState.pop() as any;
            if (this.lastAction != "show"){
                this.hideSpinnerElem();
            }
        }
        else {
            this.hideSpinnerElem();
            this.lastAction = null;
        }
    }

   

    private showSpinnerElem(){  
        this.startTimer('show');
    }

    public kill(){
        if (this.spinnerTimer){
            clearTimeout(this.spinnerTimer);
            this.spinnerTimer = null;
        }
        this.spinnerState = [];
        this.pendingAction = null;
        this.lastAction = null;
        try {
            this.app.updateSpinnerState(false);
        }
        catch(e){
            console.error("Error showing spinner",e);
        }
    }
    
    private startTimer(action:'hide' | 'show'){
        if (this.spinnerTimer){
            this.pendingAction = action;
            return;
        }
        this.pendingAction = action;
        let delay = action == 'show' ? 250 : 25;
        this.spinnerTimer = setTimeout(()=>{
            this.spinnerTimer = null;
            let visible:boolean;
            if (this.pendingAction == "show"){
                visible = true;
            }
            else {
                visible = false;
            }
            this.pendingAction = null;
            if (this.app.updateSpinnerState){
                try {
                    this.app.updateSpinnerState(visible);
                }
                catch(e){
                    console.error("Error showing spinner",e);
                }
            }
            
        },delay);
    }
  
    private hideSpinnerElem(){
        this.startTimer('hide');
    }
}