import React from 'react';
import ReactButton from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import {BsInfoCircle} from "react-icons/bs";

class CustomToolTip extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            show: false,
            placement: 'right',
        };
    }

    handleMouseLeave = () => {
      this.setState({show: false});
    }

    handleMouseEnter = () => {
      const tooltipElement = document.getElementById('popover-basic');
      const tooltipRect = tooltipElement.getBoundingClientRect();
      const newPlacement = tooltipRect.right > window.innerWidth ? 'left' : 'right';
      this.setState({ placement: newPlacement, show: true });
    }

    render() {
      const { header, bodyContent } = this.props;
      const { show, placement } = this.state;

      const popover = (
          <Popover 
            id="popover-basic"  
            style={{zIndex: 9999}} 
            onMouseEnter={this.handleMouseEnter} 
            onMouseLeave={this.handleMouseLeave}
          >
            <Popover.Header as="h3">{header}</Popover.Header>
            <Popover.Body>
              {bodyContent}
            </Popover.Body>
          </Popover>
        );
        
      const btnStyle = {
        padding:"0px 0px 0px 2px", 
        background: "transparent", 
        borderColor: "transparent",
        boxShadow: "none",
      };

      return (<OverlayTrigger
                trigger={['hover', 'focus']}
                placement={placement}
                overlay={popover}
                show={show}
                onToggle={(e) => {
                  this.setState({show: e});
                }}
                flip
              >
                <ReactButton style={btnStyle} variant="light">
                  <BsInfoCircle/>
                </ReactButton>
              </OverlayTrigger>);
    }
  
}

export default CustomToolTip;