import { useEffect, useState } from "react";
import { CustomLoading } from "./CustomLoading";
import { Card, Line } from "ktm-ui";
import "../styles/Settings.css";
import EditGeneralSettingModal from "./EditGeneralSettingModal";
import { CustomImageUploader } from "./CustomImageUploader";
import { CustomColorPicker } from "./CustomColorPicker";
import axios from "axios";

const BrandingSettings = ({ policyId }) => {
    const [showEditGeneralModal, setShowEditGeneralModal] = useState(false);
    const [showLogoUploader, setShowLogoUploader] = useState(false);
    const [enableWellness, setEnableWellness] = useState(null);
    const [brandLogo, setBrandLogo] = useState(null);
    const [brandColor, setBrandColor] = useState("#223e7f");
    const [loading, setLoading] = useState(true);
    const [showColorPicker, setShowColorPicker] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([getEnableWellness(), getBrandLogo(), getBrandColor()]);
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (enableWellness !== null && brandColor !== null) {
            setLoading(false);
        }
    }, [enableWellness, brandLogo, brandColor]);

    const openEditGeneralModal = (event) => {
        event.preventDefault();
        setShowEditGeneralModal(true);
    }

    const updateBrandLogo = async (newLogo) => {
        setBrandLogo(newLogo);
        setShowLogoUploader(false);
    }

    const updateBrandColor = async (color) => {
        setBrandColor(color);
        setShowColorPicker(false);
    }
    
    const getEnableWellness = async () => {
        try {
            const response = await axios.get("/api/Settings/checkenablewellness", {
                params: {
                    policyId: policyId
                }
            });
    
            if (response.data.success) {
                setEnableWellness(response.data.enableWellness.value);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getBrandLogo = async () => {
        try {
            if(policyId === undefined) return;

            const response = await axios.get("/api/Settings/getcompanybrandlogo", {
                params: {
                    policyId: policyId
                }
            });
    
            if (response.data.success) {
                setBrandLogo(response.data.companyBrandLogo);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getBrandColor = async () => {
        try {
            if(policyId === undefined) return;
            
            const response = await axios.get("/api/Settings/getcompanybrandcolor", {
                params: {
                    policyId: policyId
                }
            });
    
            if (response.data.success) {
                setBrandColor(response.data.companyBrandColor);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const removeBrandLogoAndColor = async () => {
        try {
            const response = await axios.put("/api/Settings/removebrandlogoandcolor", { policyId: policyId });
            if (response.data.success) {
                setBrandLogo(null);
                setBrandColor("#223e7f");
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const updateEnableWellness = async (value) => {
        try {
            const response = await axios.put("/api/Settings/updateenablewellness", { policyId: policyId, enableWellness: value });
            if (response.data.success) {
                await getEnableWellness();
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    }

    let editGeneralModal = null;
    if (showEditGeneralModal) {
        editGeneralModal = (
            <EditGeneralSettingModal
                modalLabel={"Edit General"}
                options={["Yes", "No"]}
                showEditModal={showEditGeneralModal}
                selectedOption={enableWellness}
                setSelectedOption={updateEnableWellness}
                closeModal={() => setShowEditGeneralModal(false)}
                dropdownLabel={"Wellness instead of Lifestyle:"}
            />
        );
    }

    let brandAction = showLogoUploader && brandLogo ? (
        <a href="#" className="RT-Link" onClick={(e) => { e.preventDefault(); setShowLogoUploader(false); }}>Cancel</a>
    ) : (
        brandLogo && (
            <>
                <a href="#" className="RT-Link" onClick={(e) => { e.preventDefault(); setShowLogoUploader(true); }}>Edit</a>
                <a href="#" className="RT-Link" onClick={(e) => { e.preventDefault(); removeBrandLogoAndColor(); }}>Remove</a>
            </>
        )
    );    

    let contents = loading ? (
        <CustomLoading />
    ) : (
        <div className="appearance-setting-container">
            {editGeneralModal}
            <Card
                actions={<><a href="#" className="RT-Link" onClick={openEditGeneralModal}>Edit</a></>}
            >
                <Line>
                    <div className="RT-Static-Field">
                        <div className='RT-Static-Field__label'>Wellness instead of Lifestyle:</div>
                        <div className='RT-Static-Field__content'>{enableWellness}</div>
                    </div>
                </Line>
            </Card>
            <Card
                label="Brand Logo"
                actions={brandAction}
            >
                {showLogoUploader || !brandLogo ? (
                    <CustomImageUploader policyId={policyId} updateLogo={updateBrandLogo} />
                ) : (
                    <Line>
                        <div className="brand-logo-container" style={{width:"200px"}}>
                            <img className="logo" src={"https://nhcfilestorage.blob.core.windows.net/images/" + brandLogo} />
                        </div>
                    </Line>
                )}
            </Card>
            <Card
                label="Brand Color"
            >
                <Line>
                    <div className="brand-color-container">
                        <div className="color" 
                            style={{
                                width: "100px",
                                height: "30px",
                                backgroundColor: brandColor,
                                border: "1px solid #000000",
                                borderRadius: "5px"
                            }}
                            onClick={() => setShowColorPicker(!showColorPicker)}
                        />
                        {showColorPicker ? ( 
                        <CustomColorPicker 
                            policyId={policyId} 
                            selectedColor={brandColor}
                            updateColor={updateBrandColor}
                            closeColorPicker={() => setShowColorPicker(false)}
                        /> ) : (<></>)}
                    </div>
                </Line>
            </Card>
        </div>
    );

    return (
        <div className="appearance-settings">{contents}</div>
    );
}

export default BrandingSettings;
