import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { Card } from "ktm-ui";
import { Modal, Spinner, Table} from "react-bootstrap";
import toastr from "toastr";
import moment from "moment";
import CustomPagination from "components/CustomPagination";
import "../../styles/Campaign.css";
import BulkEditEmployeeBenCatCampaignModal from "../../components/Bulk_edit_employee_bencat_campaign";
import EditIndividualEmpCampaignModal from "../../components/Edit_individual_emp_campaign";
import BulkEditEmployeeDeadlineCampaignModal from "../../components/Bulk_edit_employee_deadline_campaign";
import CustomToolTip from "components/CustomToolTip";

const EmployeeCampaignTable = ({ policy_id, benefitEndDate, disableBtn, selectionDeadline, launchDate }) => {
    const [loading, setLoading] = useState(true);
    const [employeeCampaignDetails, setEmployeeCampaignDetails] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const [hasSalaryBaseBenefits, setHasSalaryBaseBenefits] = useState(false);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [showBulkEditEmployeeBenCatCampaignModal, setShowBulkEditEmployeeBenCatCampaignModal] = useState(false);
    const [showBulkEditEmployeeDeadlineCampaignModal, setShowBulkEditEmployeeDeadlineCampaignModal] = useState(false);
    const [showEditIndividualEmpCampaignModal, setShowEditIndividualEmpCampaignModal] = useState(false);
    const [showSendFlexSelectionModal, setShowSendFlexSelectionModal] = useState(false);
    const [showAcknowledgmentModal, setShowAcknowledgmentModal] = useState(false);
    const [selectedEmpBenCat, setSelectedEmpBenCat] = useState(null);
    const [selectedEmpFlexSelectionDeadline, setSelectedEmpFlexSelectionDeadline] = useState(null);
    const [selectedIndividualEmployee, setSelectedIndividualEmployee] = useState(null);
    const [bencatOptions, setBencatOptions] = useState([]);
    const [isEmployeeUpdated, setIsEmployeeUpdated] = useState(false);

    const fetchEmployeeCampaigns = useCallback(async () => {
        try {
            const response = await fetch(`/api/Flex/getemployeecampaigns?PolicyId=${policy_id}`);
            const data = await response.json();
            if (data.success) {
                setEmployeeCampaignDetails(data.employeeCampaigns);
                setHasSalaryBaseBenefits(data.hasSalaryBaseBenefits);
                setLoading(false);
            }
        } catch (error) {
            toastr.error("Failed to fetch employee campaigns", "Error");
        }
    }, [policy_id]);

    const fetchBenefitCategories = useCallback(async () => {
        try {
            const response = await fetch(`/api/Utility/getnextyearbenefitcategories?policyId=${policy_id}`);
            const data = await response.json();
            if (data.success) {
                setBencatOptions([{ Id: null, name: "--Select--", CreditCalcMethod: null }, ...data.benefitCategories]);
            }
        } catch (error) {
            toastr.error("Failed to fetch benefit categories", "Error");
        }
    }, [policy_id]);

    useEffect(() => {
        setLoading(true);
        fetchEmployeeCampaigns();
        fetchBenefitCategories();
    }, [fetchEmployeeCampaigns, fetchBenefitCategories]);

    useEffect(() => {
        if (isEmployeeUpdated) {
            fetchEmployeeCampaigns();
            setIsEmployeeUpdated(false);
        }
    }, [isEmployeeUpdated, fetchEmployeeCampaigns]);

    useEffect(() => {
        fetchEmployeeCampaigns();
    }, [selectionDeadline, benefitEndDate, fetchEmployeeCampaigns]);

    const handleSelectAll = () => {
        if (selectedEmployees.length === employeeCampaignDetails.length) {
            setSelectedEmployees([]);
        } else {
            setSelectedEmployees(employeeCampaignDetails.map(employee => employee.id));
        }
    };

    const handleSendSelection = async () => {
        try {
            const body = {
                PolicyId: policy_id,
                RecipientIds: selectedEmployees,
            };
            const response = await axios.put("/api/Flex/sendselectionemails", body);
            if (response.data.success) {
                toastr.success("Email Sent", "Success");
                setShowSendFlexSelectionModal(false);
            } else {
                toastr.error("Failed to send email", "Error");
            }
        } catch (error) {
            toastr.error("Failed to send email", "Error");
        }
    };

    const handleToggleEmails = async () => {
        try {
            const body = {
                PolicyId: policy_id,
                selectedEmployees,
            };
            const response = await axios.put("/api/Flex/toggleflexemails", body);
            if (response.data.success) {
                toastr.success("Emails Toggled", "Success");
                fetchEmployeeCampaigns();
                setSelectedEmployees([]);
            } else {
                toastr.error("Failed to toggle emails", "Error");
            }
        } catch (error) {
            toastr.error("Failed to toggle emails", "Error");
        }
        setShowAcknowledgmentModal(false);
    };

    const currentRecords = employeeCampaignDetails.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const nPages = Math.ceil(employeeCampaignDetails.length / itemsPerPage);

    return (
        <div className="employee-campaigns-container">
            <Card>
                {showBulkEditEmployeeBenCatCampaignModal && (
                    <BulkEditEmployeeBenCatCampaignModal
                        showBulkEditEmployeeBenCatCampaignModal={showBulkEditEmployeeBenCatCampaignModal}
                        bencatOptions={bencatOptions}
                        policyId={policy_id}
                        employeeIds={selectedEmployees}
                        campaignLaunchDate={launchDate}
                        closeBulkEditEmployeeBenCatCampaignModal={() => {
                            setShowBulkEditEmployeeBenCatCampaignModal(false);
                            fetchEmployeeCampaigns();
                            setSelectedEmployees([]);
                        }}
                    />
                )}
                {showBulkEditEmployeeDeadlineCampaignModal && (
                    <BulkEditEmployeeDeadlineCampaignModal
                        showBulkEditEmployeeDeadlineCampaignModal={showBulkEditEmployeeDeadlineCampaignModal}
                        policyId={policy_id}
                        employeeIds={selectedEmployees}
                        selectionDeadline={selectedEmpFlexSelectionDeadline}
                        campaignLaunchDate={launchDate}
                        closeBulkEditEmployeeDeadlineCampaignModal={() => {
                            setShowBulkEditEmployeeDeadlineCampaignModal(false);
                            fetchEmployeeCampaigns();
                            setSelectedEmployees([]);
                        }}
                    />
                )}
                {showEditIndividualEmpCampaignModal && (
                    <EditIndividualEmpCampaignModal
                        showEditIndividualEmpCampaignModal={showEditIndividualEmpCampaignModal}
                        bencatOptions={bencatOptions}
                        policyId={policy_id}
                        employee={selectedIndividualEmployee}
                        campaignSelectionDeadline={selectionDeadline}
                        closeEditIndividualEmpCampaignModal={() => {
                            setShowEditIndividualEmpCampaignModal(false);
                            fetchEmployeeCampaigns();
                            setSelectedEmployees([]);
                        }}
                    />
                )}
                {showSendFlexSelectionModal && (
                    <Modal
                        className="react-modal react-modal-confirm"
                        show={showSendFlexSelectionModal}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header className="react-modal-title">
                            <Modal.Title>Acknowledgment</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Card>
                                <span style={{ fontSize: 14 }}>
                                    Are you sure you want to send a Flex Selection email to the selected employees immediately?
                                </span>
                            </Card>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary" onClick={() => setShowSendFlexSelectionModal(false)}>
                                Cancel
                            </button>
                            <button className="btn btn-primary" onClick={handleSendSelection}>
                                Send
                            </button>
                        </Modal.Footer>
                    </Modal>
                )}
                {showAcknowledgmentModal && (
                    <Modal
                        show={showAcknowledgmentModal}
                        style={{ top: 200 }}
                        centered
                    >
                        <Modal.Header>
                            <Modal.Title style={{ fontSize: 17, fontWeight: 'bold' }}>Acknowledgment</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ maxHeight: 400, overflowY: "auto" }}>
                            <Card>
                                <p>
                                    Disabling selection emails does not exclude employees from flex benefits, 
                                    only from further scheduled emailing. For further assistance, 
                                    contact <a style={{"color":"#0d6efd"}} href="mailto:enrollments@nationalhealthclaim.com">enrollments@nationalhealthclaim.com</a>.
                                </p>
                            </Card>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary" onClick={() => setShowAcknowledgmentModal(false)}>
                                Cancel
                            </button>
                            <button className="btn btn-primary" onClick={handleToggleEmails}>
                                Confirm
                            </button>
                        </Modal.Footer>
                    </Modal>
                )}
                <div className="bulk-actions-toolbar" style={{ marginBottom: '15px' }}>
                    <button className="btn btn-secondary" onClick={handleSelectAll}>
                        Select/Deselect All
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={(event) => setShowBulkEditEmployeeBenCatCampaignModal(true)}
                        disabled={disableBtn || selectedEmployees.length === 0}
                        style={{ marginLeft: '10px' }}
                    >
                        Bulk Edit Category
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={(event) => {setShowBulkEditEmployeeDeadlineCampaignModal(true)}}
                        disabled={disableBtn || selectedEmployees.length === 0}
                        style={{ marginLeft: '10px' }}
                    >
                        Bulk Edit Deadline
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => setShowAcknowledgmentModal(true)}
                        disabled={disableBtn || selectedEmployees.length === 0}
                        style={{ marginLeft: '10px' }}
                    >
                        Toggle Email(s)
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => setShowSendFlexSelectionModal(true)}
                        disabled={disableBtn || selectedEmployees.length === 0 || new Date(launchDate) > new Date()}
                        style={{ marginLeft: '10px' }}
                    >
                        Send Manual Email(s)
                    </button>
                </div>
                {loading ? (
                    <div className="pad-container">
                        <Spinner className="pad-spinner" animation="border" variant="primary" />
                    </div>
                ) : (
                    <>
                        <Table hover>
                            <thead>
                            <tr style={{fontSize: "14px", fontWeight: "bold"}}>
                                <th/>
                                <th>Last Name</th>
                                <th>First Name</th>
                                <th>Email</th>
                                <th>
                                    Email Notifications Enabled
                                    <CustomToolTip
                                        header="Email Notifications Enabled"
                                        bodyContent="Employees will receive all scheduled emails if 'Yes'. 
                                            If 'N/A', the employee does not have flex dollars for the next year 
                                            and will be excluded from all scheduled emailing."
                                    />
                                </th>
                                <th>Benefit Category</th>
                                {hasSalaryBaseBenefits && (
                                    <>
                                        <th>Limit Calculation Method</th>
                                        <th>Salary</th>
                                    </>
                                )}
                                <th>Is Flex</th>
                                <th>Coverage</th>
                                <th>Flex Total Limit</th>
                                <th>Selection Deadline</th>
                                {moment(benefitEndDate).isAfter(moment()) && (<th/>)}
                            </tr>
                            </thead>
                            <tbody>
                            {currentRecords.map(employee => (
                                <tr key={employee.id}>
                                    <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedEmployees.includes(employee.id)}
                                        onChange={() => {
                                            setSelectedEmployees(prev =>
                                                prev.includes(employee.id)
                                                    ? prev.filter(id => id !== employee.id)
                                                    : [...prev, employee.id]
                                            );
                                        }}
                                        style={{ marginRight: "10px", marginTop: "2px" }}
                                    />
                                        </td>
                                        <td>{employee.lastName}</td>
                                        <td>{employee.firstName}</td>
                                        <td>{employee.email}</td>
                                        <td>{employee.enableFlexEmails ? 
                                            (employee.isFlex && employee.flexBase > 0 ? "Yes" : "N/A") : "No"}</td>
                                        <td>{employee.name}</td>
                                        {hasSalaryBaseBenefits && (
                                            <>
                                                <td>{employee.creditCalcMethod}</td>
                                                <td>{employee.creditCalcMethod === "SALARY" ? employee.salaryMoney : "N/A"}</td>
                                            </>
                                        )}
                                        <td>{employee.isFlex ? "Yes" : "No"}</td>
                                        <td>{employee.coverage}</td>
                                        <td>{employee.isFlex ? employee.flexBase : "N/A"}</td>
                                        <td>
                                            {employee.flexSelectionDeadline != null ? employee.flexSelectionDeadline.substring(0, 10) : "N/A"}
                                        </td>
                                        {moment(benefitEndDate).isAfter(moment()) && (
                                            <td>
                                                <a
                                                    href="#"
                                                    className="RT-Link"
                                                    onClick={(event) => {
                                                        setSelectedEmployees([employee.id]);
                                                        setSelectedIndividualEmployee(employee);
                                                        setShowEditIndividualEmpCampaignModal(true);
                                                    }}
                                                >
                                                    Edit
                                                </a>
                                            </td> 
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "15px" }}>
                            <span className="selected-employees-info" style={{ fontWeight: 500 }}>
                                {`Selected Employees: ${selectedEmployees.length}`}
                            </span>
                            <div className="pagination-container">
                                <div className="pagination-info">
                                    <span>
                                        Showing {currentRecords.length > 0 ? (currentPage - 1) * itemsPerPage + 1 : 0} - {(currentPage - 1) * itemsPerPage + currentRecords.length} entries out of {employeeCampaignDetails.length} entries
                                    </span>
                                </div>
                                <CustomPagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                            </div>
                        </div>
                    </>
                )}
            </Card>
        </div>
    );
};

export default EmployeeCampaignTable;
