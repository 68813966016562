import React from 'react';
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from 'ktm-ui';
import { KTMApi } from 'api/KTMApi';
import { Layout, Dialog, Button, Display, Line, Column, Panel, Card } from 'ktm-ui';

interface IdsLimit {
    Id: string;
    HSASingleLimit: number;
    HSACoupleLimit: number;
    HSAFamilyLimit: number;
    HSASinglePercent: number;
    HSACouplePercent: number;
    HSAFamilyPercent: number;
    CreditCalcMethod: string;
    Policy_Id: string;
};

export default KTMScreen({ name: "enroll.ben-limit.edit-hsa-limits" },
    class extends React.Component<{ limit_id: string, employeesCount: number, employeeHasClaims: boolean, benefitStartDate: string }> {

        data: {
            dsLimit: IdsLimit;
        };

        state = {
            originalLimits: {
                HSASingleLimit: 0,
                HSACoupleLimit: 0,
                HSAFamilyLimit: 0,
            },
            disableButton: false,
            showCampaignWarning: false,
        };

        render() {
            return renderScreen(this);
        }

        async start({ screen }: ActionEvent) {
            screen.defaultRecord = "@dsLimit";
            await KTMApi.start(screen, {
                "@limit_id": this.props.limit_id,
            });
            screen.title = "Edit HSA limits";
            this.setState({
                originalLimits: {
                    HSASingleLimit: this.data.dsLimit.HSASingleLimit,
                    HSACoupleLimit: this.data.dsLimit.HSACoupleLimit,
                    HSAFamilyLimit: this.data.dsLimit.HSAFamilyLimit,
                }
            });
            await this.checkCampaignStatus();
            await screen.triggerAction(this.set_limit_visibility);
        }

        main({ field, draw, action }: RenderEvent) {
            return (
                <Layout>
                    {draw(this.header)}
                    {(this.state.showCampaignWarning && this.props.employeesCount > 0) && (
                        <Card>
                            <Line>
                                <span style={{ color: "red", fontSize: 14, fontWeight: 'bold' }}>
                                    This benefit category has been assigned to employees as part of their year-end Flex Campaign. Any changes saved here will apply to the next benefit year as well as to the current one, and the assigned employees may be required to re-select.
                                    <br />
                                    If assistance is required, please click ‘Cancel’ and contact enrollments@nationalhealthclaim.com.
                                </span>
                            </Line>
                        </Card>
                    )}
                    {draw(this.main_tab)}
                    {this.isButtonDisabled() ? <p style={{ color: "red" }}>Benefit category cannot be decreased after assigned employees have claimed amounts.</p> : ""}
                    {draw(this.footer)}
                </Layout>
            );
        }

        header({ field, draw, action }: RenderEvent) {
            return (
                <Dialog.Header label="Edit HSA Limits" />
            );
        }

        isButtonDisabled() {
            const { HSASingleLimit, HSACoupleLimit, HSAFamilyLimit } = this.data.dsLimit;
            const { originalLimits } = this.state;

            const benefitStartDate = new Date(this.props.benefitStartDate);
            const currentDate = new Date();
            const oneMonthAfterStartDate = new Date(benefitStartDate);
            oneMonthAfterStartDate.setMonth(oneMonthAfterStartDate.getMonth() + 1);

            if (currentDate <= oneMonthAfterStartDate) {
                return false;
            }

            const disableButton = this.props.employeesCount > 0 &&
                (HSASingleLimit < originalLimits.HSASingleLimit ||
                    HSACoupleLimit < originalLimits.HSACoupleLimit ||
                    HSAFamilyLimit < originalLimits.HSAFamilyLimit)
                && this.props.employeeHasClaims;
            return disableButton;
        }

        footer({ field, draw, action }: RenderEvent) {
            return (
                <Dialog.Footer>
                    <Button
                        action={action(this.submit)}
                        label="Save"
                        size="large"
                        style="primary"
                        disable={this.isButtonDisabled()}
                    />
                </Dialog.Footer>
            );
        }

        limits({ field, draw, action }: RenderEvent) {
            return (
                <Line>
                    <Display field={field("HSASingleLimit")} />
                    <Display field={field("HSACoupleLimit")} />
                    <Display field={field("HSAFamilyLimit")} />
                </Line>
            );
        }

        percentages({ field, draw, action }: RenderEvent) {
            return (
                <Line>
                    <Display field={field("HSASinglePercent")} />
                    <Display field={field("HSACouplePercent")} />
                    <Display field={field("HSAFamilyPercent")} />
                </Line>
            );
        }

        main_tab({ field, draw, action }: RenderEvent) {
            return (
                <Panel>
                    <Column>
                        {draw(this.details)}
                    </Column>
                </Panel>
            );
        }

        details({ field, draw, action }: RenderEvent) {
            return (
                <Card>
                    {draw(this.limits)}
                    {draw(this.percentages)}
                </Card>
            );
        }

        async submit({ screen }: ActionEvent) {
            if (this.data.dsLimit.HSASingleLimit == null)
                this.data.dsLimit.HSASingleLimit = 0;
            if (this.data.dsLimit.HSACoupleLimit == null)
                this.data.dsLimit.HSACoupleLimit = 0;
            if (this.data.dsLimit.HSAFamilyLimit == null)
                this.data.dsLimit.HSAFamilyLimit = 0;
            if (this.data.dsLimit.HSASinglePercent == null)
                this.data.dsLimit.HSASinglePercent = 0;
            if (this.data.dsLimit.HSACouplePercent == null)
                this.data.dsLimit.HSACouplePercent = 0;
            if (this.data.dsLimit.HSAFamilyPercent == null)
                this.data.dsLimit.HSAFamilyPercent = 0;

            await KTMApi.action(screen, "submit", {
                "@dsLimit.HSASingleLimit": this.data.dsLimit.HSASingleLimit,
                "@dsLimit.HSACoupleLimit": this.data.dsLimit.HSACoupleLimit,
                "@dsLimit.HSAFamilyLimit": this.data.dsLimit.HSAFamilyLimit,
                "@dsLimit.HSASinglePercent": this.data.dsLimit.HSASinglePercent,
                "@dsLimit.HSACouplePercent": this.data.dsLimit.HSACouplePercent,
                "@dsLimit.HSAFamilyPercent": this.data.dsLimit.HSAFamilyPercent,
                "@limit_id": this.props.limit_id,
            });
            screen.close({ "continue": true });
        }

        async set_limit_visibility({ screen }: ActionEvent) {
            screen.hide(this.limits);
            screen.hide(this.percentages);
            if (screen.getValue("@dsLimit.CreditCalcMethod") === "SALARY") {
                screen.unhide(this.percentages);
            } else {
                screen.unhide(this.limits);
            }
        }

        async checkCampaignStatus() {
            let response = await fetch("/api/Utility/checkcampaignstatus?policyId=" + this.data.dsLimit.Policy_Id);
            let res = await response.json();
            if (res.isCampaignOpen) {
                this.setState({ showCampaignWarning: true });
            }
        }
    });
