import axios from "axios";
import React from "react";
import { Button, Table } from "react-bootstrap";
import toastr from "toastr";
import { CSVLink } from "react-csv"; 
import { CustomLoading } from "components/CustomLoading";
import "../../styles/Reports.css";
import { EmptyStateTemplate } from "./Empty_state_Template";
import CustomPagination from "components/CustomPagination";
import { DateDropdownWithCalendarPicker } from "./DateDropdownWithCalendarPicker";
import base64 from "../../data/base64.json";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { CustomDropdown } from "components/CustomDropdown";
import { Modal } from 'react-bootstrap';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class CustomCatEmployeeUsageReport extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            dataToExport: [],
            fullData: [],
            displayedData: [],
            selectedOption: this.props.benefitYearOptions[0],
            bencatOptions: [],
            selectedBenCat: null,
            selectedCustomCategories: null,
            currentPage: 1,
            itemsPerPage: 12,
            showModal: false,
        };

        this.setSelectedOption = this.setSelectedOption.bind(this);
        this.setCurrentPage = this.setCurrentPage.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.formatDataToExport = this.formatDataToExport.bind(this);
        this.toggleModal = this.toggleModal.bind(this); 
        this.updateDisplayedData = this.updateDisplayedData.bind(this);
        this.createTable = this.createTable.bind(this);
    }

    componentDidMount() {
        this.setState({loading: true});
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedOption, selectedBenCat } = this.state;
        if(prevState.selectedOption !== selectedOption){
            this.setState({loading: true});
            this.fetchData()
        }
        if (prevState.selectedBenCat !== selectedBenCat) 
        {
            this.updateDisplayedData();
        }
    }

    fetchData = () => {
        const { selectedOption } = this.state;

        var apiString: string;
        if (selectedOption.id === null) {
            apiString = `/api/Reports/customcatemployeeusage?policyId=${this.props.policyId}&fromDate=${selectedOption.startDate.toLocaleString()}&toDate=${selectedOption.endDate.toLocaleString()}`;
        } else {
            apiString = `/api/Reports/customcatemployeeusage?policyId=${this.props.policyId}&benefitYearId=${selectedOption.id}`;
        }
        axios.get(apiString)
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        bencatOptions : response.data.benefitCategoryWithCustomCat,
                        selectedBenCat : response.data.benefitCategoryWithCustomCat[0],
                        fullData: response.data.categoryResults,
                        loading: false
                    }, this.updateDisplayedData)

                    this.formatDataToExport(response.data.categoryResults);
                } else {
                    this.setState({loading: false});
                    toastr.error(response.data.message, "Error");
                }
            })
            .catch(error => {
                toastr.error(error, "Error");
            });
    }

    setSelectedOption = (option) => {
        this.setState({selectedOption: option});
    }
    
    setSelectedBenCat = (option) => {
        let findOption = this.state.bencatOptions.find(x => x.bencatName === option);
        this.setState({selectedBenCat: findOption});
    }

    setCurrentPage = (page) => {
        this.setState({currentPage: page});
    }

    updateDisplayedData = () => {
        const { fullData, selectedBenCat } = this.state;
        const findedData = fullData.find(cat => cat.category.bencatId === selectedBenCat.bencatId);
        this.setState({ 
            displayedData : findedData.results,
            selectedCustomCategories: findedData.customCategories 
        });
    }

    formatDataToExport = (data) => {
        let dataToExport = [];
    
        data.forEach(categoryResult => {
            const headerRow = ["Last Name", "First Name"];
            categoryResult.customCategories.forEach(cat => {
                headerRow.push(`${cat.name} Limit`, `${cat.name} Usage`, `${cat.name} Balance`);
            });
    
            let categoryData = [headerRow];
    
            categoryResult.results.forEach(employee => {
                const formattedItem = [
                    employee.lastName,
                    employee.firstName,
                    ...employee.subCategories.flatMap(subcat => [
                        subcat.isEligible ? subcat.limit : `${subcat.limit} (Ineligible)`,
                        subcat.isEligible ? subcat.used : `${subcat.used} (Ineligible)`,
                        subcat.isEligible ? subcat.balance : `${subcat.balance} (Ineligible)`
                    ])
                ];
                categoryData.push(formattedItem);
            });
    
            const totalRow = ["Total", ""];
            categoryResult.customCategories.forEach(cat => {
                totalRow.push(cat.totalLimit, cat.totalUsed, cat.totalBalance);
            });
    
            categoryData.push(totalRow);
    
            dataToExport.push({
                categoryName: categoryResult.category.bencatName,
                tableData: categoryData
            });
        });
    
        this.setState({
            dataToExport: dataToExport
        });
    }    

    exportToPdf = () => {
        const { dataToExport, selectedOption } = this.state;

        let content: any[] = [
            {
                columns: [
                    this.createLogo(),
                    this.createDateRange(selectedOption.dateRange),
                ],
            },
            this.createTitle(),
        ];
    
        dataToExport.forEach(category => {
            content.push({ text: category.categoryName, style: 'subheader' });
            content.push(this.createTable(category.tableData));
        });
    
        const docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            content: content,
            styles: this.getPdfStyles(),
            layout: this.getTableLayout()
        };
    
        pdfMake.createPdf(docDefinition).download('Enhanced_Benefit_Employee_Usage_Report.pdf');
    }
    
    createTable = (data) => {
        const numColumns = data[0].length;
        const columnWidths = Array(numColumns).fill(`${100 / numColumns}%`);
    
        return {
            style: 'tableExample',
            table: {
                headerRows: 1,
                widths: columnWidths,
                body: this.formatTableBody(data)
            },
            layout: {
                fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex === 0) ? '#CCCCCC' : null;
                }
            }
        };
    }    
    
    createLogo = () => ({
        image: base64.logo,
        width: 230,
    });
    
    createTitle = () => ({
        text: 'Enhanced Benefits Employee Usage Report',
        fontSize: 16,
        bold: true,
        alignment: 'center',
        margin: [10, 40, 0, 10]
    });
    
    createDateRange = (dateRange) => ({
        text: `Date Range: ${dateRange}`,
        fontSize: 12,
        bold: true,
        alignment: 'right',
        margin: [0, 0, 0, 8]
    });
    
    formatRow = (rowData, style) => {
        return rowData.map(cell => {
            const isNumeric = !isNaN(parseFloat(cell)) && isFinite(cell);
            const isPercentage = typeof cell === 'string' && cell.includes('%');
            const isIneligible = typeof cell === 'string' && cell.includes('(Ineligible)');
    
            return {
                text: String(cell || ''),
                style,
                alignment: (isNumeric || isPercentage || isIneligible) ? 'right' : 'left'
            };
        });
    };
    
    formatTableBody = (rows) => rows.map((row, index) => this.formatRow(row, index === 0 ? 'tableHeader' : (index === rows.length - 1 ? 'tableFooter' : 'tableBody')));
    
    getPdfStyles = () => ({
        header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10]
        },
        subheader: {
            fontSize: 14,
            bold: true,
            margin: [0, 10, 0, 5]
        },
        tableExample: {
            margin: [0, 5, 0, 15]
        },
        tableHeader: {
            bold: true,
            fontSize: 12,
            color: 'black'
        },
        tableBody: {
            fontSize: 10,
            color: 'black',
            padding: [2, 2, 2, 2]
        },
        tableFooter: {
            bold: true,
            fontSize: 12,
            color: 'black'
        }
    });
    
    getTableLayout = () => ({
        hLineWidth: (i, node) => 0.5,
        vLineWidth: (i, node) => 0.5,
        paddingLeft: (i, node) => 4,
        paddingRight: (i, node) => 4
    });

    toggleModal = () => {
        this.setState(prevState => ({ showModal: !prevState.showModal }));
    }

    render() {
        const { 
            data,
            selectedOption,
            selectedBenCat,
            currentPage,
            itemsPerPage,
            loading,
            bencatOptions,
            displayedData,
            selectedCustomCategories,
            showModal
        } = this.state;

        const {
            benefitYearOptions
        } = this.props;

        const indexOfLastRecord = currentPage * itemsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
        const currentRecords = displayedData.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(displayedData.length / itemsPerPage);

        let contents = null;
        if (loading) {
            contents = (<CustomLoading />);
        } else {
            contents = (
                <div className="HSA-Benefit-Utilization-Table report-table-container" style={{overflowX:"auto"}}>
                    <div className="report-toolbar"
                        style={{display: "flex", alignItems: "center", flexDirection: "row", flexWrap: "wrap"}}
                    >
                        <DateDropdownWithCalendarPicker
                            options={[{id:null, dateRange:"Custom", status: "", startDate: "", endDate: ""}, ...benefitYearOptions]}
                            selectedOption={selectedOption}
                            setSelectedOption={this.setSelectedOption}
                        />
                        <div className="benefit-year-status">
                            <span className="benefit-year-status-text">{selectedOption.status}</span>
                        </div>
                        {bencatOptions.length !== 0 ?
                        (<CustomDropdown
                            options={bencatOptions.map(x => x.bencatName)} 
                            selectedOption={selectedBenCat.bencatName} 
                            setSelectedOption={this.setSelectedBenCat}
                        />) : null}
                        {displayedData.length !== 0?
                        <div className="export-button-container">
                            <Button variant="primary" className="export-to-pdf" onClick={this.toggleModal}>
                                <span className="pdf-text">Export to PDF</span>
                            </Button>
                        </div>:null}
                    </div>
                    {displayedData.length === 0 ? <EmptyStateTemplate />:
                    <>
                        <div style={{fontSize: 13, color: "red", padding:10, fontWeight: 'bold'}}>
                            This report contains sensitive information and is intended only for administrators with permission to access.
                            <br/> 
                            This safeguards individual privacy rights while providing essential usage insights. Disclosing, copying, or distributing this data may violate employee confidentiality.
                            <br/>
                            For more information, please refer to our privacy policy or contact support@nationalhealthclaim.com.
                        </div>
                        <Table hover className="report-table">
                            <thead>
                                <tr>
                                    <th className="table-text-content">Last Name</th>
                                    <th className="table-text-content" style={{ borderRight: '1px solid #dee2e6' }}>First Name</th>
                                    {selectedCustomCategories.map((cat, index) => (
                                        <React.Fragment key={index}>
                                            <th>{cat.name} Limit</th>
                                            <th>{cat.name} Usage</th>
                                            <th style={{ borderRight: '1px solid #dee2e6' }}>{cat.name} Balance</th>
                                        </React.Fragment>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {currentRecords.map((item, index) => (
                                    <tr key={index} className="table-body-itme">
                                        <td className="table-text-content">{item.lastName}</td>
                                        <td className="table-text-content" style={{ borderRight: '1px solid #dee2e6' }}>{item.firstName}</td>
                                        {item.subCategories.map((cat, index) => (
                                            <React.Fragment key={index}>
                                                <td>{cat.limit}{!cat.isEligible && " (Ineligible)"}</td>
                                                <td>{cat.used}{!cat.isEligible && " (Ineligible)"}</td>
                                                <td style={{ borderRight: '1px solid #dee2e6' }}>{cat.balance}{!cat.isEligible && " (Ineligible)"}</td>
                                            </React.Fragment>
                                        ))}
                                    </tr>
                                    )
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={2} className="table-footer-item total-amount" style={{ borderRight: '1px solid #dee2e6' }}>Total</td>
                                    {selectedCustomCategories.map((t, index) => (
                                        <React.Fragment key={index}>
                                            <td className="table-footer-item">{t.totalLimit}</td>
                                            <td className="table-footer-item">{t.totalUsed}</td>
                                            <td className="table-footer-item" style={{borderRight: '1px solid #dee2e6'}}>{t.totalBalance}</td>
                                        </React.Fragment>
                                    ))}
                                </tr>
                            </tfoot>
                        </Table>
                        <div className="pagination-container">
                            <div className="pagination-info">
                                <span>Showing {indexOfFirstRecord + 1} - {indexOfFirstRecord+currentRecords.length} entries out of {data.length} entries</span>
                            </div>
                            <CustomPagination 
                                nPages={nPages} 
                                currentPage={currentPage} 
                                setCurrentPage={this.setCurrentPage} 
                            />
                        </div>
                    </>}
                </div>
            );
        }
        
        return (
        <>
            {contents}
            <ExportModal 
                show={showModal} 
                onHide={this.toggleModal} 
                onExportPDF={this.exportToPdf} 
            />
        </>);
    }
}

const ExportModal = ({ show, onHide, onExportPDF }) => (
    <Modal show={show} centered style={{top:200}}>
        <Modal.Header>
            <Modal.Title>Acknowledgment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={{fontSize: 13, fontWeight: 'bold', color: "red", padding:10}}>
                This report contains sensitive information and is intended only for administrators with permission to access.
                <br/> 
                This safeguards individual privacy rights while providing essential usage insights. Disclosing, copying, or distributing this data may violate employee confidentiality.
                <br/>
                For more information, please refer to our privacy policy or contact support@nationalhealthclaim.com.
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>Cancel</Button>
            <Button variant="primary" onClick={onExportPDF}>Confirm</Button>
        </Modal.Footer>
    </Modal>
);