import { RecordMeta } from "./RecordMeta";

export interface FieldError{
    type:FieldErrorType;
    message:string;
}

export type IErrorMessages = string[];

    

export interface IFieldErrorCollection {
    [name:string]:FieldError
}

export type FieldErrorType = 'VALIDATION' | 'NOTFOUND' | 'ERROR' | 'FATAL' | 'INFORMATION' | 'WARNING' | 'NO-DIALOG';

export class RecordErrorSet {
    public messages:IErrorMessages;
    public fields:IFieldErrorCollection;
    public title:string;
    public code:string = "VALIDATION_ERROR";
    public handled:boolean = true;
    public hasErrors:boolean;

    constructor(title:string){
        this.title = title;
        this.messages = [];
        this.fields = {};
    }


    static get(meta:RecordMeta):RecordErrorSet {
        if (meta) return meta.error;
    }

    static getFieldError(meta:RecordMeta,field:string):FieldError {
        let error = this.get(meta);
        if (!error || !error.fields) return null;
        return error.fields[field];
    }

    message(message:string):RecordErrorSet {
        this.messages.push(message);
        this.hasErrors = true;
        return this;
    }

    clear(field:string){
        this.fields[field] = null;
    }

    field(name:string,message:string,type:FieldErrorType):RecordErrorSet {
        if (!message){
            this.fields[name] = null;
            return;
        }
        this.fields[name] = {
            message,
            type
        };
        return this;
    }

}
