import React from "react";
import axios from "axios";
import toastr from "toastr";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

const emptyDependentData = {
  id: "",
  employee_Id: "",
  firstName: "",
  lastName: "",
  maidenName: "",
  nickname: "",
  dateOfBirth: "",
  relation: "Spouse",
};

const emptyErrors = {
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  relation: "",
}

export default class AddNewDependentModal extends React.Component<any> {
  state = {
    open: false,
    notes: null,
    newDependent: {
      id: "",
      employee_Id: "",
      firstName: "",
      lastName: "",
      maidenName: "",
      nickname: "",
      dateOfBirth: "",
      relation: "Spouse",
    },
    errors: {
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      relation: "",
    },
    buttonDisabled: true,
  };

  constructor(props) {
    super(props);
    this.handleDependentChange = this.handleDependentChange.bind(this);
  }

  componentDidMount() {}

  handleClose = (e) => {
    e.preventDefault();
    this.setState({ open: true });
  };

  handleDependentChange = (event) => {
    let empObj = this.state.newDependent;
    if (event.target.value == "") {
      empObj[event.target.name] = null;
    } else {
      empObj[event.target.name] = event.target.value;
    }
    let errors = this.state.errors;
    let buttonDisabled = this.state.buttonDisabled;
    let newDependent = this.state.newDependent;
    switch (event.target.name) {
      case "firstName":
        errors.firstName =
          event.target.value.length === 0 ? "First Name is required" : "";
        break;
      case "lastName":
        errors.lastName =
          event.target.value.length === 0 ? "Last Name is required" : "";
        break;
      case "dateOfBirth":
        errors.dateOfBirth =
          event.target.value.length === 0 ? "Date of Birth is required" : "";
        break;
      case "relation":
        errors.relation =
          event.target.value.length === 0 ? "Relation is required" : "";
        break;
      default:
        break;
    }
    if (
      newDependent.firstName &&
      newDependent.firstName.length > 0 &&
      newDependent.lastName &&
      newDependent.lastName.length > 0 &&
      newDependent.dateOfBirth
    ) {
      buttonDisabled = false;
    } else {
      buttonDisabled = true;
    }
    this.setState({ newDependent: empObj, errors, buttonDisabled });
  };

  handleDependentSave = async (e) => {
    e.preventDefault();
    var dob = moment(this.state.newDependent.dateOfBirth)
      .utc()
      .format("YYYY-MM-DD");
    let dependentModel = {
      EmployeeId: this.props.employeeId,
      FirstName: this.state.newDependent.firstName,
      LastName: this.state.newDependent.lastName,
      MaidenName: this.state.newDependent.maidenName,
      Nickname: this.state.newDependent.nickname,
      Relation: this.state.newDependent.relation,
      DateOfBirth: dob,
    };

    axios
      .put("/api/OpsEmployee/adddependent", dependentModel)
      .then((response) => {
        this.setState({
          allowEditAddress: false,
          errors: emptyErrors,
          newDependent: emptyDependentData,
        }); 
        toastr.success("Dependent added", "Success");
        this.props.onHide();
      })
      .catch((error) => {
        if (error.response?.status == 401) {
          toastr.error("Failed to add dependent", "Error");
        }
        else if(error.response?.data =="Duplicate dependent"){
          toastr.error("This dependent entry already exists", "Error");
        }
        else {
          toastr.error("Failed to add dependent", "Error");
          this.setState({
            loading: false,
            errors: error.response?.data,
          });
        }
        this.props.onHide();
      })
      .then(() => {
        this.props.onHide();
      });
  };

  render() {
    return (
      <Modal
        className="react-modal"
        backdrop="static"
        keyboard={false}
        show={this.props.show}
      >
        <Modal.Header className="react-modal-title">
          <Modal.Title>Add dependent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-inline-flex p-2 mg-r-25">
            <div className="form-group">
              <label className="form-control-label tx-semibold">
                First Name
                <span className="tx-danger"> *</span>
              </label>
              <input
                required
                value={this.state.newDependent.firstName || ""}
                onChange={this.handleDependentChange}
                className="form-control"
                type="text"
                name="firstName"
              />
              {this.state.errors != null ? (
                <div className="text-danger errorDependentModal">
                  {this.state.errors["firstName"] ? (
                    <div>{this.state.errors["firstName"]}</div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
          <div className="d-inline-flex p-2 mg-r-25">
            <div className="form-group">
              <label className="form-control-label tx-semibold">
                Last Name
                <span className="tx-danger"> *</span>
              </label>
              <input
                required
                value={this.state.newDependent.lastName || ""}
                onChange={this.handleDependentChange}
                className="form-control"
                type="text"
                name="lastName"
              />
              {this.state.errors != null ? (
                <div className="text-danger errorDependentModal">
                  {this.state.errors["lastName"] ? (
                    <div>{this.state.errors["lastName"]}</div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
          <div className="d-inline-flex p-2 mg-r-25">
            <div className="form-group">
              <label className="form-control-label tx-semibold">
                Maiden Name
              </label>
              <input
                value={this.state.newDependent.maidenName}
                onChange={this.handleDependentChange}
                className="form-control"
                type="text"
                name="maidenName"
              />
            </div>
          </div>
          <div className="d-inline-flex p-2 mg-r-25">
            <div className="form-group">
              <label className="form-control-label tx-semibold">Nickname</label>
              <input
                value={this.state.newDependent.nickname}
                onChange={this.handleDependentChange}
                className="form-control"
                type="text"
                name="nickname"
              />
            </div>
          </div>
          <div className="d-inline-flex p-2 mg-r-25">
            <div className="form-group">
              <label className="form-control-label tx-semibold">
                Relation <span className="tx-danger"> *</span>
              </label>
              <select
                required
                className="form-control"
                name="relation"
                value={this.state.newDependent.relation || ""}
                onChange={this.handleDependentChange}
              >
                <option value="SPOUSE">Spouse</option>
                <option value="CHILD">Child</option>
              </select>
              {this.state.errors != null ? (
                <div className="text-danger errorDependentModal">
                  {this.state.errors["relation"] ? (
                    <div>{this.state.errors["relation"]}</div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
          <div className="d-inline-flex p-2 mg-r-25">
            <div className="form-group">
              <label className="form-control-label tx-semibold">
                Date Of Birth
                <span className="tx-danger"> *</span>
              </label>
              <input
                required
                value={this.state.newDependent.dateOfBirth}
                onChange={this.handleDependentChange}
                className="form-control"
                type="date"
                name="dateOfBirth"
              />
              {this.state.errors != null ? (
                <div className="text-danger errorDependentModal">
                  {this.state.errors["dateOfBirth"] ? (
                    <div>{this.state.errors["dateOfBirth"]}</div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-primary pd-y-5"
            onClick={(event) => this.handleDependentSave(event)}
            disabled={this.state.buttonDisabled}
          >
            Save
          </button>
          <button className="btn btn-light pd-y-5" onClick={this.props.onHide}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
