import React from "react";
import {
  KTMScreen,
  ActionEvent,
  RenderEvent,
  renderScreen,
  RadioButtons,
} from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import {
  Layout,
  Dialog,
  Button,
  Display,
  Card,
  Column,
  Panel,
  Line,
} from "ktm-ui";
import AdvisorLookup from "enroll/lookups/Advisor";
import AccountHolderLookup from "enroll/lookups/Policy_holder";
import toastr from "toastr";
import CustomToolTip from "../../components/CustomToolTip";
import { CustomDropdown } from "components/CustomDropdown";

interface IdsPolicy {
  PolicyHolderName: string;
  PolicyHolder_Id: string;
  PolicyName: string;
  DistroAccount: string;
  PrimaryAdvisor_Id: string;
  Mode: string;
  Policy_Id: string;
  PolicyNo: string;
  Representative: string;
}

interface IdsBenefitYear {
  Id: string;
  StartDate: string;
  EndDate: string;
  RunoffMonths: number;
}

const representatives = ["-- Select --", "NONE", "SALESON", "SALESAB", "JG"];

export default KTMScreen(
  { name: "enroll.policy.new" },
  class extends React.Component<{
    new_policy_id: string;
    policyNumbers?: string[];
  }> {
    data: {
      dsPolicy: IdsPolicy;
      dsBenefitYear: IdsBenefitYear;
    };

    state = {
      policyNumber: this.props.new_policy_id,
      selectedRep: representatives[0]
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsPolicy";
      await KTMApi.start(screen, {
        "@new_policy_id": this.props.new_policy_id,
      });
      screen.title = "New Enrollment";
      screen.setLookup("PrimaryAdvisor_Id", {
        template: this.advisor_lookup,
        autoLaunch: true,
      });
      screen.setLookup("PolicyHolder_Id", {
        template: this.account_holder_lookup,
        autoLaunch: true,
      });
      screen.setOptions("Mode", [
        { value: "NEW", label: "New policy holder" },
        { value: "ADD", label: "Add a policy to existing policy holder" },
      ]);
      screen.setOptions("@dsBenefitYear.RunoffMonths", [
        { value: "1", label: "1 month" },
        { value: "2", label: "2 months" },
        { value: "3", label: "3 months" },
      ]);
      screen.setOnValueChanged("Mode", this.mode_onchange);
      screen.setOnValueChanged("PrimaryAdvisor_Id", this.advisor_onchange);
    }

    advisor_lookup({ getValue, eventValue }: RenderEvent) {
      return <AdvisorLookup eventValue={eventValue} />;
    }

    account_holder_lookup({ getValue, eventValue }: RenderEvent) {
      return <AccountHolderLookup eventValue={eventValue} />;
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout dialogSize="full">
          {draw(this.header)}
          {draw(this.main_tab)}
          {draw(this.footer)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return <Dialog.Header label="New Enrollment" />;
    }

    footer({ field, draw, action }: RenderEvent) {
      var isDisabled =
        this.data.dsPolicy.PrimaryAdvisor_Id === undefined ||
        this.state.policyNumber === undefined ||
        this.data.dsBenefitYear.StartDate === undefined ||
        this.data.dsBenefitYear.EndDate === undefined ||
        this.data.dsBenefitYear.RunoffMonths === undefined ||
        this.data.dsPolicy.PrimaryAdvisor_Id === null ||
        this.state.policyNumber === null ||
        this.state.policyNumber === "" ||
        this.data.dsBenefitYear.StartDate === null ||
        this.data.dsBenefitYear.EndDate === null ||
        this.data.dsBenefitYear.RunoffMonths === null
          ? true
          : false;

      return (
        <Dialog.Footer>
          <Button
            action={action(this.submit)}
            label="Create enrollment"
            size="large"
            style="primary"
            disable={isDisabled}
          />
        </Dialog.Footer>
      );
    }

    new_account_holder({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <Display field={field("PolicyHolderName")} />
          <div className="RT-Line__content RT-Line__content--space-evenly">
            <div className="RT-Line__item">
              <div className="RT-TextBox">
                <label>Policy Number</label>
                <div className="RT-TextBox__container">
                  <input
                    onChange={(e) => {
                      this.setState({
                        policyNumber: e.target.value,
                      });
                    }}
                    className="RT-TextBox__input"
                    type="number"
                    value={this.state.policyNumber}
                  />
                </div>
              </div>
            </div>
            <div className="RT-Line__item">
              <div className="employee-edit-bencat">
                <div className="RT-TextBox edit-employee-ben-cat">
                  <label className="RT-Label">
                    Representative
                  </label>
                  <CustomDropdown
                      options={representatives}
                      selectedOption={this.state.selectedRep}
                      setSelectedOption={this.setSelectedOption}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            The next available policy number is:{" "}
            <b>{this.props.new_policy_id}</b>
          </div>
        </Card>
      );
    }

    setSelectedOption = async (selectedOption: any) => {
      var findOption = representatives.find((option) => option == selectedOption);
      this.setState({ 
        selectedRep: findOption
      });
      this.data.dsPolicy.Representative = findOption;
    };

    existing_account_holder({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <Line>
            <Display field={field("PolicyHolder_Id")} />
            <Display field={field("PolicyName")} />
          </Line>
        </Card>
      );
    }

    main_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>
            {draw(this.enrollment_type)}
            {draw(this.new_account_holder)}
            {draw(this.existing_account_holder)}
            {draw(this.detail_panel)}
          </Column>
        </Panel>
      );
    }

    details({ field, draw, action }: RenderEvent) {
      return (
        <Card label="Primary Advisor">
          <Line>
            <Display field={field("PrimaryAdvisor_Id")} />
            <Display field={field("DistroAccount")} />
          </Line>
          <div>
            Click on the magnifying glass, then enter the advisor name and
            click "Search"
          </div>
        </Card>
      );
    }

    enrollment_type({ field, draw, action }: RenderEvent) {
      return (
        <RadioButtons
          field={field("@dsPolicy.Mode")}
          hideLabel
          direction="horizontal"
        />
      );
    }

    benefit_year({ field, draw, action }: RenderEvent) {
      return (
        <Card label="Benefit year">
          <Line>
            <Display
              field={field("@dsBenefitYear.StartDate")}
              toolTip={
                <CustomToolTip
                  header="Start Date"
                  bodyContent="To backdate correctly, you must backdate both this benefit year and the Product Effective Date under the “Products” tab. A policy can only be backdated up to a maximum of 2 years prior to the Benefit End Date."
                />
              }
            />
            <Display field={field("@dsBenefitYear.EndDate")} />
            <Display
              field={field("@dsBenefitYear.RunoffMonths")}
              toolTip={
                <CustomToolTip
                  header="RunOff Months"
                  bodyContent="Instead of days, employees have run-off month(s) to get their previous year receipts submitted."
                />
              }
            />
          </Line>
          <div>
            If backdating an enrollment, follow the (i) instructions carefully
          </div>
        </Card>
      );
    }

    detail_panel({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>{draw(this.details)}</Column>
          <Column>{draw(this.benefit_year)}</Column>
        </Panel>
      );
    }

    async submit({ screen }: ActionEvent) {

      if(this.data.dsPolicy.Representative == undefined || 
        this.data.dsPolicy.Representative == "-- Select --" ||
        this.data.dsPolicy.Representative == "NONE" )
      {
        this.data.dsPolicy.Representative = null;
      }

      if (
        this.props.policyNumbers &&
        this.props.policyNumbers.includes(this.state.policyNumber)
      ) {
        this.data.dsPolicy.PolicyNo = this.props.new_policy_id;
        toastr.error("Policy number already exists");
      } else {
        this.data.dsPolicy.PolicyNo = this.state.policyNumber;

        await KTMApi.action(screen, "submit", {
          "@dsPolicy.Mode": this.data.dsPolicy.Mode,
          "@dsPolicy.PolicyHolder_Id": this.data.dsPolicy.PolicyHolder_Id,
          "@dsPolicy.PolicyNo": this.data.dsPolicy.PolicyNo,
          "@dsPolicy.PolicyName": this.data.dsPolicy.PolicyName,
          "@dsPolicy.PolicyHolderName": this.data.dsPolicy.PolicyHolderName,
          "@dsPolicy.PrimaryAdvisor_Id": this.data.dsPolicy.PrimaryAdvisor_Id,
          "@dsPolicy.Representative": this.data.dsPolicy.Representative,
          "@dsBenefitYear.StartDate": this.data.dsBenefitYear.StartDate,
          "@dsBenefitYear.EndDate": this.data.dsBenefitYear.EndDate,
          "@dsBenefitYear.RunoffMonths": this.data.dsBenefitYear.RunoffMonths,
        });
        screen.close({ continue: true });
      }
    }

    async mode_onchange({ screen, eventValue }: ActionEvent) {
      if (eventValue == "ADD") {
        screen.setValue("@dsPolicy.PolicyHolderName", null);
        screen.hide(this.new_account_holder);
        screen.unhide(this.existing_account_holder);
      } else {
        screen.setValue("@dsPolicy.PolicyHolder_Id", null);
        screen.setValue("@dsPolicy.PolicyName", null);
        screen.hide(this.existing_account_holder);
        screen.unhide(this.new_account_holder);
      }
    }

    async advisor_onchange({ screen, eventValue }: ActionEvent) {
      await KTMApi.onValueChanged(screen, "@dsPolicy.PrimaryAdvisor_Id", {
        "@event-value": eventValue,
      });
    }
  }
);
